import { Wrapper } from '@pages/Settings/Settings.styled';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Account from '@pages/Account/Account';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '@constants/router';
import { useSelector } from 'react-redux';
import { isFeatureEnabled } from '@selectors/configSelectors';
import { getHasAdminRights } from '@selectors/loginSelectors';
import Preferences from '@pages/Preferences';
import { SETTING_TABS } from '@constants/settingTabs';
import SettingsTabs from '@components/SettingsTabs';

const Settings = () => {
  const intl = useIntl();
  const adminRights = useSelector(getHasAdminRights());
  const location = useLocation();
  const isFullScreenOperatorEnabled = useSelector(
    isFeatureEnabled('fullScreenOperator'),
  );
  const isAccountPage = location.pathname === ROUTES.SETTINGS_ACCOUNT;

  const title = useMemo(() => {
    return intl.formatMessage({
      id: 'navigation.link.settings',
      defaultMessage: 'Settings',
    });
  }, [intl]);

  return (
    <Wrapper data-testid="settings-page">
      <PageHeader
        dataTestId={`account-page__header`}
        size={PAGE_HEADER_VARIANT_LARGE}
        title={title}
      />

      {isFullScreenOperatorEnabled ? (
        <>
          <SettingsTabs
            activeTab={
              isAccountPage
                ? SETTING_TABS.ACCOUNT.title
                : SETTING_TABS.PREFERENCES.title
            }
            adminRights={adminRights}
          />

          {isAccountPage ? <Account /> : <Preferences />}
        </>
      ) : (
        <>
          <SettingsTabs
            activeTab={SETTING_TABS.ACCOUNT.title}
            adminRights={adminRights}
          />

          <Account />
        </>
      )}
    </Wrapper>
  );
};

Settings.propTypes = {};

export default Settings;

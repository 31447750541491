import { useCallback, useMemo, useState } from 'react';
import { clone, uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperatorMutations from '@hooks/operators/useOperatorMutations';
import { getDraggingOperatorId } from '@selectors/conceptSelectors';
import {
  endOperatorSelectorDrag,
  startOperatorSelectorDrag,
} from '@actions/conceptActions';
import { EventAction, EventCategory, sendEvent } from '@utils/GaTracker';
import { WORKFLOW_OPERATORS_DROPPABLE_ID } from '@containers/WorkflowOperators';
import useOperator from '@hooks/operators/useOperator';

export default function useOperatorDrag() {
  const dispatch = useDispatch();
  const draggingOperatorId = useSelector(getDraggingOperatorId);
  const {
    getSelectedWorkflow,
    getSelectedWorkflowOperators,
    getIsWorkflowEditable,
    setSelectedWorkflowOperators,
  } = useWorkflow();
  const { addOperatorMutation, reorderOperatorMutation } =
    useOperatorMutations();

  const { getOperatorGraphDependencies } = useOperator();

  const workflow = getSelectedWorkflow();
  const workflowOperators = getSelectedWorkflowOperators();
  const workflowDisabled = !getIsWorkflowEditable(workflow);

  const [operatorDependencyLimit, setOperatorDependencyLimit] = useState({
    minOrder: Number.MAX_SAFE_INTEGER,
    maxOrder: 0,
  });

  const closestDragItems = useMemo(
    () =>
      workflowOperators.reduce((acc, operator) => {
        const previousItem =
          operator?.order === operatorDependencyLimit?.minOrder;
        const nextItem = operator?.order === operatorDependencyLimit?.maxOrder;

        if (previousItem) acc.previousItem = operator;
        if (nextItem) acc.nextItem = operator;

        return acc;
      }, {}),
    [workflowOperators, operatorDependencyLimit],
  );

  const onDragStart = useCallback(
    (result) => {
      const fromOperatorListSelector =
        result.source.droppableId === WORKFLOW_OPERATORS_DROPPABLE_ID;

      const operator = workflowOperators[result.source.index];

      if (!fromOperatorListSelector) {
        dispatch(startOperatorSelectorDrag(result?.draggableId));

        return;
      }

      if (!operator) return;

      const operatorDependencyGraph = getOperatorGraphDependencies();

      const previousDependedOperators = operatorDependencyGraph.getSuccessors(
        operator.id,
      );
      const nextDependedOperators = operatorDependencyGraph.getPredecessors(
        operator.id,
      );

      let minOrder = Number.MAX_SAFE_INTEGER;
      let maxOrder = 0;

      previousDependedOperators.forEach((dependedOperator) => {
        if (maxOrder < dependedOperator.getContent().order) {
          maxOrder = dependedOperator.getContent().order;
        }
      });

      nextDependedOperators.forEach((dependedOperator) => {
        if (minOrder > dependedOperator.getContent().order) {
          minOrder = dependedOperator.getContent().order;
        }
      });

      setOperatorDependencyLimit({ minOrder, maxOrder });
    },
    [
      dispatch,
      workflowOperators,
      getOperatorGraphDependencies,
      setOperatorDependencyLimit,
    ],
  );

  const onDragEnd = useCallback(
    ({ source, destination }) => {
      const fromOperatorListSelector =
        source.droppableId === WORKFLOW_OPERATORS_DROPPABLE_ID;
      const droppedOnTheSamePosition =
        destination?.droppableId === source?.droppableId &&
        destination?.index === source?.index;

      if (!fromOperatorListSelector) {
        const draggableOperatorName = draggingOperatorId?.match(
          /draggableId:{([^}]*)}/,
        )?.[1];
        const draggableOperatorTemplateId =
          draggingOperatorId?.match(/templateId:{([^}]*)}/)?.[1];

        if (!destination) {
          dispatch(endOperatorSelectorDrag());

          return;
        }

        sendEvent(
          EventAction.operatorAdded,
          EventCategory.engagement,
          null,
          null,
          draggableOperatorName,
        );

        const order = destination?.index;

        const addedOperator = {
          id: uniqueId('operator-'),
          conceptId: workflow?.id,
          order,
          name: draggableOperatorName,
          tag: draggableOperatorName,
          adding: true,
          values: [],
        };

        const addedOperatorOrder = order + 1;

        const nextOperators = workflowOperators
          ?.toSpliced(order, 0, addedOperator)
          ?.map((operator, index) => ({
            ...operator,
            order: index + 1,
          }));

        setSelectedWorkflowOperators(nextOperators);

        addOperatorMutation.mutate({
          workflowId: workflow?.id,
          order: addedOperatorOrder,
          operatorName: draggableOperatorName,
          templateId: draggableOperatorTemplateId,
        });

        dispatch(endOperatorSelectorDrag());

        return;
      }

      setOperatorDependencyLimit({
        minOrder: Number.MAX_SAFE_INTEGER,
        maxOrder: 0,
      });

      if (!destination || droppedOnTheSamePosition) return;

      const originalOrder = source?.index;
      const newOrder = destination?.index;

      const clonedOperators = clone(workflowOperators);
      const [movedOperator] = clonedOperators.splice(originalOrder, 1);
      const nextOperators = clonedOperators
        ?.toSpliced(newOrder, 0, movedOperator)
        ?.map((operator, index) => ({
          ...operator,
          order: index + 1,
          reordering: true,
        }));

      setSelectedWorkflowOperators(nextOperators);

      reorderOperatorMutation.mutate({
        workflowId: workflow?.id,
        operatorId: movedOperator?.id,
        order: newOrder + 1,
        originalOperatorsList: workflowOperators,
      });
    },
    [
      dispatch,
      workflowOperators,
      setOperatorDependencyLimit,
      draggingOperatorId,
      workflow?.id,
      setSelectedWorkflowOperators,
      reorderOperatorMutation,
      addOperatorMutation,
    ],
  );

  return {
    daragEnabled: !workflowDisabled,
    closestDragItems,
    onDragStart,
    onDragEnd,
  };
}

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useScreenSizeObserver from '@hooks/useScreenSizeObserver';
import {
  getIsLoggedIn,
  getCurrentUser,
  getUserPreferences,
  getUserSessionPreferences,
  getIsTokenAuthentificated,
} from '@selectors/loginSelectors';
import AuthenticatedRoutes from '@routes/AuthenticatedRoutes';
import UnauthenticatedRoutes from '@routes/UnauthenticatedRoutes';
import GaTracker from '@utils/GaTracker';
import { addToLocalStorage } from '@utils/localStorage';
import { addToSessionStorage } from '@utils/sessionStorage';
import TokenAuthenticator from '@containers/TokenAuthenticator';
import {
  CURRENT_USER_KEY_LOCAL_STORAGE,
  USER_PREFERENCES_KEY,
  USER_SESSION_PREFERENCES_KEY,
} from '@constants/utilityConstants';
import { GlobalStyles, Wrapper, SVGSprite } from './Main.styled';

const Main = () => {
  const user = useSelector(getCurrentUser());
  const userPreferences = useSelector(getUserPreferences);
  const userSessionPreferences = useSelector(getUserSessionPreferences);
  const tokenAuthentificated = useSelector(getIsTokenAuthentificated);
  const isLoggedIn = useSelector(getIsLoggedIn());
  const checkForTokenValidity = isLoggedIn && !tokenAuthentificated;

  if (PROD_OR_TEST_ENV) {
    GaTracker();
  }

  useScreenSizeObserver();

  useEffect(() => {
    if (isLoggedIn) {
      addToLocalStorage(`${USER_PREFERENCES_KEY}.${user.id}`, userPreferences);
      addToSessionStorage(
        `${USER_SESSION_PREFERENCES_KEY}.${user.id}`,
        userSessionPreferences,
      );
    }
  }, [isLoggedIn, userPreferences, userSessionPreferences, user.id]);

  useEffect(() => {
    if (isLoggedIn) {
      addToLocalStorage(CURRENT_USER_KEY_LOCAL_STORAGE, user);
    }
  }, [isLoggedIn, user]);

  let ContentComponent = UnauthenticatedRoutes;

  if (checkForTokenValidity) {
    ContentComponent = TokenAuthenticator;
  } else if (isLoggedIn) {
    ContentComponent = AuthenticatedRoutes;
  }

  return (
    <>
      <GlobalStyles />

      <Wrapper>
        <ContentComponent />
      </Wrapper>

      <SVGSprite uniquifyIDs={false} />
    </>
  );
};

export default Main;

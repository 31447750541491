import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { operatorsQueryKeys } from '@hooks/operators/useOperatorQueries';
import {
  clone,
  get,
  isEmpty,
  isObject,
  isString,
  isUndefined,
  map,
  omit,
  pick,
} from 'lodash';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';
import { compare } from 'compare-versions';
import { workflowQueryKeys } from '@hooks/workflows/useWorkflowQueries';
import useWorkflowMutations from '@hooks/workflows/useWorkflowMutations';
import useFile from '@hooks/files/useFile';
import { printerQueryKeys } from '@hooks/printers/usePrinterQueries';
import { getCurrentUser, getUserPreference } from '@selectors/loginSelectors';
import { getMinimumBackwardsCompatibleVersion } from '@selectors/configSelectors';
import { getIsFetchesInProgress } from '@selectors/generalSelectors';
import {
  selectHiddenInputNames,
  selectIsAwaitingComputation,
} from '@reducers/workflowSlice';
import { exitToolpathSimulation } from '@actions/conceptActions';
import { resetComputationProgressHandler } from '@actions/computationProgressActions';
import useMaterial from '@hooks/materials/useMaterial';
import useAwaitingComputationMessage from './useAwaitingComputationMessage';
import { OperatorInputTypes } from '@app/constants/operatorInputTypes';

export default function useWorkflow() {
  const dispatch = useDispatch();
  const { itemId: selectedWorkflowId } = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(getCurrentUser());
  const minimumBackwardsCompatibleVersion = useSelector(
    getMinimumBackwardsCompatibleVersion,
  );
  const workflowHiddenInputNames = useSelector(selectHiddenInputNames);
  const areFetchesInProgress = useSelector(getIsFetchesInProgress());
  const isWorkflowAwaitingComputation = useSelector(
    selectIsAwaitingComputation,
  );
  const isComputeAutomatically = useSelector(
    getUserPreference('isComputeAutomatically'),
  );
  const { enqueueAwaitingComputationMessage } = useAwaitingComputationMessage();

  const {
    computeWorkflowMutation,
    cancelComputeWorkflowMutation,
    updateWorkflowMutation,
    updateWorkflowValuesMutation,
  } = useWorkflowMutations();

  const { getSelectedMaterial } = useMaterial();

  const { invalidateProjectFielsQuery } = useFile();

  const getWorkflow = useCallback(
    (workflowId) =>
      queryClient.getQueryData(workflowQueryKeys.workflow(workflowId)),
    [queryClient],
  );

  const getSelectedWorkflow = useCallback(
    () => getWorkflow(selectedWorkflowId),
    [selectedWorkflowId, getWorkflow],
  );

  const getWorkflowFromProvidedArg = useCallback(
    (workflow) => {
      const isWorkflowId = isString(workflow);

      if (isWorkflowId) {
        return getWorkflow(workflow);
      }

      return workflow || getSelectedWorkflow();
    },
    [getWorkflow, getSelectedWorkflow],
  );

  const getWorkflowMaterial = useCallback(
    (workflow) => {
      const materialId = workflow?.materialId;
      return getSelectedMaterial(materialId);
    },
    [getSelectedMaterial],
  );

  const getSelectedWorkflowProjectId = useCallback(
    () => getSelectedWorkflow()?.workspaceId,
    [getSelectedWorkflow],
  );

  const getSelectedWorkflowOperators = useCallback(
    () =>
      getSelectedWorkflow()?.operators?.sort?.((a, b) => a.order - b.order) ||
      [],
    [getSelectedWorkflow],
  );

  const getWorkflowOperators = useCallback(
    (workflowOrId) => {
      const workflow =
        getWorkflowFromProvidedArg(workflowOrId) || getSelectedWorkflow();

      return workflow?.operators?.sort?.((a, b) => a.order - b.order) || [];
    },
    [getWorkflowFromProvidedArg, getSelectedWorkflow],
  );

  const getIsWorkflowComputing = useCallback(
    (workflowOrId) => {
      const workflow =
        getWorkflowFromProvidedArg(workflowOrId) || getSelectedWorkflow();

      return workflow?.computing;
    },
    [getWorkflowFromProvidedArg, getSelectedWorkflow],
  );

  const getIsWorkflowAwaitingComputation = useCallback(
    () => isWorkflowAwaitingComputation && getIsWorkflowComputing(),
    [isWorkflowAwaitingComputation, getIsWorkflowComputing],
  );

  const getIsWorkflowDeprecated = useCallback(() => {
    const workflow = getSelectedWorkflow();
    const deprecatedOperators = workflow?.operators?.filter(
      (operator) => operator.deprecated && operator.upgradable,
    );
    const upgradeAvailable = !!deprecatedOperators?.length;

    return upgradeAvailable;
  }, [getSelectedWorkflow]);

  const getProjectWorkflows = useCallback(
    (projectId) =>
      queryClient.getQueryData(workflowQueryKeys.projectWorkflows(projectId)),
    [queryClient],
  );

  const getSortedProjectWorkflows = useCallback(
    (projectId) => {
      const workflows = getProjectWorkflows(projectId);
      const sortedWorkflows = workflows?.sort((a, b) => {
        const aValue = get(a, 'createdAt');
        const bValue = get(b, 'createdAt');

        if (isEmpty(aValue) || isEmpty(bValue)) return 0;

        return moment(bValue).diff(moment(aValue));
      });

      return sortedWorkflows;
    },
    [getProjectWorkflows],
  );

  const getWorkflowPrinter = useCallback(
    (workflowOrId) => {
      const workflow = getWorkflowFromProvidedArg(workflowOrId);

      if (!workflow) {
        return null;
      }

      const printers = queryClient.getQueryData(printerQueryKeys.printers);

      if (!printers) {
        return null;
      }

      let printer =
        printers.find((printer) => printer.id === workflow.printerId) ||
        workflow?.sharedPrinterInfo;

      if (!printer) {
        const projectWorkflows = getProjectWorkflows(workflow?.workspaceId);
        const projectWorkflow = projectWorkflows?.find(
          ({ id }) => id === workflow?.id,
        );
        printer = projectWorkflow?.sharedPrinterInfo;
      }

      return printer;
    },
    [queryClient, getWorkflowFromProvidedArg, getProjectWorkflows],
  );

  const getIsWorkflowPublic = useCallback(
    (workflowOrId) => {
      const workflow = getWorkflowFromProvidedArg(workflowOrId);
      const worflowIsShared = workflow?.public;

      return worflowIsShared;
    },
    [getWorkflowFromProvidedArg],
  );

  const getIsWorkflowDisabled = useCallback(
    (workflowOrId) => {
      const workflow = getWorkflowFromProvidedArg(workflowOrId);

      return getIsWorkflowComputing(workflow) || areFetchesInProgress;
    },
    [getIsWorkflowComputing, areFetchesInProgress, getWorkflowFromProvidedArg],
  );

  const getIsWorkflowEditable = useCallback(
    (workflowOrId) => {
      const workflow = getWorkflowFromProvidedArg(workflowOrId);
      const isWorkflowPublic = getIsWorkflowPublic(workflow);
      const isWorkflowDisabled = getIsWorkflowDisabled(workflow);

      if (isWorkflowDisabled) return false;

      if (isWorkflowPublic) {
        const isUserInOrganization =
          (workflow?.organization || workflow?.organizationId) ===
          user?.organizationId;

        return isUserInOrganization;
      }

      return true;
    },
    [
      user,
      getIsWorkflowPublic,
      getIsWorkflowDisabled,
      getWorkflowFromProvidedArg,
    ],
  );

  const getIsAutoCompute = useCallback(
    () => isComputeAutomatically,
    [isComputeAutomatically],
  );

  const getIsWorkflowEmpty = useCallback(
    () => isEmpty(getSelectedWorkflow()?.operators),
    [getSelectedWorkflow],
  );

  const getIsWorkflowComputed = useCallback(
    () => getSelectedWorkflow()?.operators?.every(({ computed }) => computed),
    [getSelectedWorkflow],
  );

  // TODO: Duplciate this getter from useOperator hook
  // since it's not possible to import due to circular dependency
  const getNormalizedDefaultOperators = useCallback(
    () =>
      queryClient.getQueryData(
        operatorsQueryKeys.defaultOperators(selectedWorkflowId),
      )?.normalizedDefaultOperators,
    [queryClient, selectedWorkflowId],
  );

  const getIsWorkflowBackwardsCompatible = useCallback(
    (workflowOrId) => {
      const workflow =
        getWorkflowFromProvidedArg(workflowOrId) || getSelectedWorkflow();

      return compare(
        workflow?.version ?? '0.0.1',
        minimumBackwardsCompatibleVersion ?? '999.999.999',
        '>=',
      );
    },
    [
      getWorkflowFromProvidedArg,
      getSelectedWorkflow,
      minimumBackwardsCompatibleVersion,
    ],
  );

  const processOperatorValues = useCallback(
    (
      operatorName,
      operatorValue,
      operatorHiddenInputNames,
      normalizedDefaultOperators,
    ) => {
      const isInputNotInDefaultOperators =
        !normalizedDefaultOperators?.[operatorName]?.settings?.[
          operatorValue.name
        ];
      const isListType =
        operatorValue.type ===
        OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES;
      const isHiddenInput =
        (!isListType && isInputNotInDefaultOperators) ||
        operatorHiddenInputNames?.includes(operatorValue.name);
      const cleanValue = omit(operatorValue, [
        'inputTemplateId',
        'locked',
        'exposed',
        'triggerComputation',
      ]);
      return { isHiddenInput, cleanValue };
    },
    [],
  );

  const generateWorkflowToUpdate = useCallback(
    (formValues = {}, injectProps = {}) => {
      const workflow = getSelectedWorkflow();
      const normalizedDefaultOperators = getNormalizedDefaultOperators();
      const hiddenInputs = [];

      const updateOperatorValues = (operator) => {
        const operatorFormValues = formValues?.[operator.id] || {};
        const operatorHiddenInputNames =
          workflowHiddenInputNames?.[operator.id];

        const updatedValues = operator.values.map((value) => {
          const { isHiddenInput, cleanValue } = processOperatorValues(
            operator.name,
            value,
            operatorHiddenInputNames,
            normalizedDefaultOperators,
          );
          if (isHiddenInput) {
            hiddenInputs.push(cleanValue);
          }

          let nextValue = value.value;

          if (
            value.id in operatorFormValues &&
            !isUndefined(operatorFormValues[value.id])
          ) {
            nextValue = operatorFormValues[value.id];
          }

          return {
            ...cleanValue,
            value: nextValue,
          };
        });

        return {
          ...operator,
          values: updatedValues,
        };
      };

      const updatedOperators = map(workflow.operators, (operator) =>
        updateOperatorValues(
          omit(operator, [
            'operatorDescriptor',
            'nameKey',
            'translatedName',
            'deprecated',
            'upgradable',
            'computed',
            'reordering',
            'iconUrl',
          ]),
        ),
      );

      const workflowData = pick(
        {
          ...workflow,
          operators: updatedOperators,
          hiddenInputs,
          ...injectProps,
        },
        [
          'hiddenInputs',
          'materialId',
          'nozzleId',
          'operators',
          'printerId',
          'workspaceId',
        ],
      );

      return workflowData;
    },
    [
      workflowHiddenInputNames,
      getSelectedWorkflow,
      getNormalizedDefaultOperators,
      processOperatorValues,
    ],
  );

  const getUpdatedOperatorValues = (operator, operatorValues) => {
    if (!operator || !isObject(operatorValues)) return [];
    const updatedOperatorValues = [];

    Object.entries(operatorValues).forEach(
      ([operatorSettingId, operatorSettingValue]) => {
        const value = operator?.values.find(
          (val) => val.id === operatorSettingId,
        );
        if (!value) return;

        if (operatorSettingValue !== value.value) {
          updatedOperatorValues.push({
            value: operatorSettingValue,
            id: value.id,
            isInput: value?.isinput,
            operatorId: value.operatorId,
          });
        }
      },
    );
    return updatedOperatorValues;
  };

  const generateOperatorValuesToUpdate = useCallback(
    (formValues = {}) => {
      const workflow = getSelectedWorkflow();
      const updatedOperatorValues = [];
      const hiddenInputs = [];
      const normalizedDefaultOperators = getNormalizedDefaultOperators();

      const processHiddenInputs = (operator) => {
        const operatorId = operator.id;
        const operatorHiddenInputNames = workflowHiddenInputNames?.[operatorId];

        operator.values.forEach((value) => {
          const { isHiddenInput, cleanValue } = processOperatorValues(
            operator.name,
            value,
            operatorHiddenInputNames,
            normalizedDefaultOperators,
          );

          if (isHiddenInput) {
            hiddenInputs.push(cleanValue);
          }
        });
      };

      Object.entries(formValues).forEach(([operatorId, operatorValues]) => {
        const operator = workflow?.operators.find((op) => op.id === operatorId);
        const singleOperatorOperatorUpdatedValues = getUpdatedOperatorValues(
          operator,
          operatorValues,
        );
        updatedOperatorValues.push(...singleOperatorOperatorUpdatedValues);
      });

      workflow.operators.forEach((operator) => {
        processHiddenInputs(operator);
      });

      return { updatedOperatorValues, hiddenInputs };
    },
    [
      getSelectedWorkflow,
      processOperatorValues,
      getNormalizedDefaultOperators,
      workflowHiddenInputNames,
    ],
  );

  const setSelectedWorkflowOperators = useCallback(
    (operators = []) => {
      const selectedWorkflow = getSelectedWorkflow();

      if (!selectedWorkflow) {
        return;
      }

      queryClient.setQueryData(workflowQueryKeys.workflow(selectedWorkflowId), {
        ...selectedWorkflow,
        operators,
      });
    },
    [queryClient, selectedWorkflowId, getSelectedWorkflow],
  );

  const addSelectedWorkflowOperator = useCallback(
    (operator, order) => {
      const selectedWorkflow = getSelectedWorkflow();

      if (!selectedWorkflow || !operator) {
        return;
      }

      let nextOperators = clone(selectedWorkflow.operators);

      if (isUndefined(order)) {
        nextOperators.push(operator);
      } else {
        nextOperators = selectedWorkflow.operators.toSpliced(
          order,
          0,
          operator,
        );
      }

      queryClient.setQueryData(workflowQueryKeys.workflow(selectedWorkflowId), {
        ...selectedWorkflow,
        operators: nextOperators,
      });
    },
    [queryClient, selectedWorkflowId, getSelectedWorkflow],
  );

  const setOperatorComputedFlag = useCallback(
    (operatorId, computed) => {
      const selectedWorkflow = getSelectedWorkflow();

      const nextOperators = selectedWorkflow.operators.map((operator) => {
        if (operator.id === operatorId) {
          return {
            ...operator,
            computed,
          };
        }

        return operator;
      });

      queryClient.setQueryData(workflowQueryKeys.workflow(selectedWorkflowId), {
        ...selectedWorkflow,
        operators: nextOperators,
      });
    },
    [queryClient, selectedWorkflowId, getSelectedWorkflow],
  );

  const setWorkflowComputingFlag = useCallback(
    (workflowId, computing) => {
      const workflow = getWorkflow(workflowId);

      queryClient.setQueryData(workflowQueryKeys.workflow(workflowId), {
        ...workflow,
        computing,
      });
    },
    [queryClient, getWorkflow],
  );

  const invalidateWorkflowQuery = useCallback(
    (workflowId) =>
      queryClient.invalidateQueries({
        queryKey: workflowQueryKeys.workflow(workflowId),
        refetchType: 'all',
      }),
    [queryClient],
  );

  const refetchWorkflow = useCallback(() => {
    return queryClient.refetchQueries({
      queryKey: workflowQueryKeys.workflow(getSelectedWorkflow()?.id),
    });
  }, [queryClient, getSelectedWorkflow]);

  const updateWorkflow = useCallback(
    async (formValues, injectProps, startComputation) => {
      const workflow = getSelectedWorkflow();
      const workflowToUpdate = generateWorkflowToUpdate(
        formValues,
        injectProps,
      );

      const mutationData = {
        workflowId: workflow.id,
        workflow: workflowToUpdate,
      };

      if (startComputation) {
        mutationData.computing = startComputation;
      }

      const updatedWorkflow = await updateWorkflowMutation.mutateAsync(
        mutationData,
      );

      return {
        workflow: updatedWorkflow,
        hiddenInputs: workflowToUpdate.hiddenInputs,
      };
    },
    [getSelectedWorkflow, generateWorkflowToUpdate, updateWorkflowMutation],
  );

  const updateWorkflowValues = useCallback(
    async (formValues, startComputation) => {
      const { updatedOperatorValues, hiddenInputs } =
        generateOperatorValuesToUpdate(formValues);
      const workflow = getSelectedWorkflow();

      let updatedWorkflow = workflow;

      // check if there are actually updates
      if (updatedOperatorValues && updatedOperatorValues.length) {
        const mutationData = {
          workflowId: workflow.id,
          updatedValues: {
            operatorValues: updatedOperatorValues,
            hiddenInputs: hiddenInputs.map((input) => input.id),
          },
        };

        if (startComputation) {
          mutationData.computing = startComputation;
        }
        updatedWorkflow = await updateWorkflowValuesMutation.mutateAsync(
          mutationData,
        );
      }

      return {
        workflow: updatedWorkflow,
        hiddenInputs,
      };
    },
    [
      getSelectedWorkflow,
      updateWorkflowValuesMutation,
      generateOperatorValuesToUpdate,
    ],
  );

  const computeWorkflow = useCallback(
    async (hiddenInputs = [], operatorId = null, computeAll = false) => {
      dispatch(exitToolpathSimulation());

      const response = await computeWorkflowMutation.mutateAsync({
        workflowId: selectedWorkflowId,
        operatorId,
        hiddenInputs,
        computeAll,
      });
      enqueueAwaitingComputationMessage();
      return response;
    },
    [
      dispatch,
      selectedWorkflowId,
      computeWorkflowMutation,
      enqueueAwaitingComputationMessage,
    ],
  );

  const updateAndComputeWorkflow = useCallback(
    async (formValues, injectProps, operatorId) => {
      try {
        const { hiddenInputs } = await updateWorkflow(
          formValues,
          injectProps,
          true,
        );

        await computeWorkflow(
          hiddenInputs,
          operatorId,
          injectProps?.computeAll,
        );
      } catch (_) {
        setWorkflowComputingFlag(selectedWorkflowId, false);
      }
    },
    [
      computeWorkflow,
      setWorkflowComputingFlag,
      updateWorkflow,
      selectedWorkflowId,
    ],
  );

  const updateValuesAndComputeWorkflow = useCallback(
    async (formValues, operatorId) => {
      try {
        const { hiddenInputs } = await updateWorkflowValues(formValues, true);

        await computeWorkflow(hiddenInputs, operatorId);
      } catch (_) {
        setWorkflowComputingFlag(selectedWorkflowId, false);
      }
    },
    [
      computeWorkflow,
      setWorkflowComputingFlag,
      updateWorkflowValues,
      selectedWorkflowId,
    ],
  );

  const cancelWorkflowComputation = useCallback(async () => {
    const workflow = getSelectedWorkflow();
    const workflowId = workflow?.id;
    const projectId = workflow?.workspaceId;

    try {
      await cancelComputeWorkflowMutation.mutateAsync({ workflowId });
      dispatch(resetComputationProgressHandler());
      await invalidateWorkflowQuery(workflowId);
      await invalidateProjectFielsQuery(projectId);
    } catch (_) {
      // Do nothing
    }
  }, [
    dispatch,
    getSelectedWorkflow,
    cancelComputeWorkflowMutation,
    invalidateWorkflowQuery,
    invalidateProjectFielsQuery,
  ]);

  const isWorkflowSetupSnapshotBeforeLastUpdate = useCallback(
    (workflowSetupSnapshotTime, printerLastUpdatedAt, materialUpdatedAt) => {
      const workflowSetupSnapshotDate = new Date(workflowSetupSnapshotTime);
      return (
        workflowSetupSnapshotDate < new Date(printerLastUpdatedAt) ||
        workflowSetupSnapshotDate < new Date(materialUpdatedAt)
      );
    },
    [],
  );

  const checkPrinterUpdateAvailable = useCallback(
    (workflow, isWorkflowDisabled, workflowPrinter, workflowMaterial) => {
      if (!workflow) return false;
      const printerSnapshotTime = workflow?.printerSnapshotCreationTime;
      const printerLastUpdatedAt = workflowPrinter?.lastUpdatedAt;
      const materialUpdatedAt = workflowMaterial?.updatedAt;
      if (!printerSnapshotTime || !printerLastUpdatedAt || !materialUpdatedAt) {
        return false;
      }

      return (
        !isWorkflowDisabled &&
        isWorkflowSetupSnapshotBeforeLastUpdate(
          printerSnapshotTime,
          printerLastUpdatedAt,
          materialUpdatedAt,
        )
      );
    },
    [isWorkflowSetupSnapshotBeforeLastUpdate],
  );

  const getIsPrinterUpdateAvailable = useCallback(
    (workflow) => {
      if (!workflow) return false;
      const workflowPrinter = getWorkflowPrinter(workflow.id);
      const workflowDisabled = getIsWorkflowDisabled(workflow);
      const workflowMaterial = getWorkflowMaterial(workflow);

      return checkPrinterUpdateAvailable(
        workflow,
        workflowDisabled,
        workflowPrinter,
        workflowMaterial,
      );
    },
    [
      getWorkflowPrinter,
      getIsWorkflowDisabled,
      checkPrinterUpdateAvailable,
      getWorkflowMaterial,
    ],
  );

  return {
    addSelectedWorkflowOperator,
    cancelWorkflowComputation,
    computeWorkflow,
    generateWorkflowToUpdate,
    getIsAutoCompute,
    getIsPrinterUpdateAvailable,
    getIsWorkflowBackwardsCompatible,
    getIsWorkflowComputed,
    getIsWorkflowComputing,
    getIsWorkflowDeprecated,
    getIsWorkflowDisabled,
    getIsWorkflowEditable,
    getIsWorkflowEmpty,
    getIsWorkflowPublic,
    getProjectWorkflows,
    getSelectedWorkflow,
    getSelectedWorkflowOperators,
    getSelectedWorkflowProjectId,
    getSortedProjectWorkflows,
    getWorkflow,
    getWorkflowOperators,
    getWorkflowPrinter,
    invalidateWorkflowQuery,
    refetchWorkflow,
    setOperatorComputedFlag,
    setSelectedWorkflowOperators,
    setWorkflowComputingFlag,
    updateAndComputeWorkflow,
    updateWorkflow,
    updateWorkflowValues,
    updateValuesAndComputeWorkflow,
    getIsWorkflowAwaitingComputation,
  };
}

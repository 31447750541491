import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@components/1-atoms/IconButton';
import Button, { BUTTON_VARIANT_TEXT } from '@components/1-atoms/Button';
import {
  Wrapper,
  Header,
  HeaderContent,
  Icon,
  Label,
  Body,
  BodyContent,
} from './SettingsCategory.styled';
import HorizontalDivider from '@app/components/1-atoms/HorizontalDivider';
import { preventDefault, stopPropagation } from '@app/utils/interactionEvents';

const SettingsCategory = ({
  children,
  className,
  dataTestId = 'settings-category',
  endingButtonDisabled,
  endingButtonIconName,
  endingButtonTitle,
  expand,
  expanTransitionTime = 300,
  leadingIconName,
  onEndingButtonClick,
  onEndingIconButtonClick,
  onHeaderClick,
  subcategory,
  title,
  withDividerBottom,
  withDividerTop,
}) => {
  const bodyRef = useRef(null);
  const bodyContentRef = useRef(null);
  const expandable = onHeaderClick || onEndingIconButtonClick;
  const previousExpandRef = useRef(expand);

  const showEndingButton = !onEndingIconButtonClick && endingButtonTitle;

  const bodyContentResizeObserver = useMemo(
    () =>
      new ResizeObserver(() => {
        const bodyElement = bodyRef.current;

        if (!bodyElement) return;

        bodyElement.style.maxHeight = `${bodyContentRef.current?.scrollHeight}px`;
      }),
    [],
  );

  useEffect(() => {
    previousExpandRef.current = expand;
  }, [expand]);

  useEffect(() => {
    const bodyContentElement = bodyContentRef.current;
    const bodyElement = bodyRef.current;

    if (expandable) {
      if (!previousExpandRef.current && expand) {
        bodyContentResizeObserver.observe(bodyContentElement);
      } else if (previousExpandRef.current && !expand) {
        bodyElement.style.maxHeight = '';
        bodyContentResizeObserver.unobserve(bodyContentElement);
      }

      return () => {
        bodyContentResizeObserver.unobserve(bodyContentElement);
      };
    }
  }, [expandable, expand, bodyContentResizeObserver, onHeaderClick]);

  return (
    <Wrapper
      className={className}
      data-testid={dataTestId}
      subcategory={subcategory}
    >
      {withDividerTop && <HorizontalDivider middleInset />}

      <Header subcategory={subcategory} onClick={onHeaderClick}>
        <HeaderContent>
          {leadingIconName && <Icon name={leadingIconName} />}

          <Label dataTestId={`${dataTestId}__title`} subcategory={subcategory}>
            {title}
          </Label>
        </HeaderContent>

        {(onEndingIconButtonClick || onHeaderClick) && (
          <IconButton
            dataTestId={`${dataTestId}__ending-icon-button`}
            iconName={expand ? 'expand_less_0' : 'expand_more_0'}
            onClick={stopPropagation(
              preventDefault(onEndingIconButtonClick || onHeaderClick),
            )}
          />
        )}

        {showEndingButton && (
          <Button
            dataTestId={`${dataTestId}__ending-button`}
            disabled={endingButtonDisabled}
            stretch={false}
            variant={BUTTON_VARIANT_TEXT}
            iconName={endingButtonIconName}
            onClick={stopPropagation(preventDefault(onEndingButtonClick))}
          >
            {endingButtonTitle}
          </Button>
        )}
      </Header>

      <Body
        ref={bodyRef}
        expand={expand}
        expanTransitionTime={expanTransitionTime}
        expandable={expandable}
        maxHeight={bodyContentRef.current?.scrollHeight}
      >
        <BodyContent ref={bodyContentRef}>{children}</BodyContent>
      </Body>

      {withDividerBottom && <HorizontalDivider middleInset />}
    </Wrapper>
  );
};

SettingsCategory.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  endingButtonDisabled: PropTypes.bool,
  endingButtonIconName: PropTypes.string,
  endingButtonTitle: PropTypes.string,
  expand: PropTypes.bool,
  expanTransitionTime: PropTypes.number,
  leadingIconName: PropTypes.string,
  onEndingButtonClick: PropTypes.func,
  onEndingIconButtonClick: PropTypes.func,
  onHeaderClick: PropTypes.func,
  subcategory: PropTypes.bool,
  title: PropTypes.string.isRequired,
  withDividerBottom: PropTypes.bool,
  withDividerTop: PropTypes.bool,
};

export default SettingsCategory;

import * as actionTypes from '@constants/actionTypes';

const initialState = {
  computing: false,
  handler: {},
  computingItemId: '',
  completed: false,
};

const computationProgress = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.START_COMPUTATION: {
      return {
        ...state,
        computing: true,
      };
    }

    case actionTypes.SET_COMPUTATION_PROGRESS_HANDLER: {
      return {
        ...state,
        handler: payload?.handler || {},
        computingItemId: payload?.handler?.id || '',
      };
    }

    case actionTypes.RESET_COMPUTATION_PROGRESS_HANDLER: {
      return initialState;
    }

    case actionTypes.SET_COMPUTING_ITEM_ID: {
      return {
        ...state,
        computingItemId: payload.itemId,
      };
    }

    case actionTypes.CANCEL_COMPUTATION:
    case actionTypes.COMPUTATION_FAILED: {
      return initialState;
    }

    case actionTypes.COMPUTATION_COMPLETED: {
      return {
        ...initialState,
        completed: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default computationProgress;

import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';
import React from 'react';
import HorizontalDivider from '../../1-atoms/HorizontalDivider/HorizontalDivider';

const PostProcessorRetraction = ({ isSubmitting }) => {
  const { values, setFieldValue } = useFormikContext();
  const intl = useIntl();

  return (
    <>
      <FormikField
        component={PostProcessorSetting}
        dataTestId={`printer-custom-post-processor__setting-isRetractionEnabled`}
        label={intl.formatMessage({
          id: 'printers.postprocessor.isRetractionEnabled.label',
          defaultMessage: 'Enable Retraction',
        })}
        renderAsCheckbox
        disabled={isSubmitting}
        name={'isRetractionEnabled'}
        checked={Boolean(values['isRetractionEnabled'])}
        onChange={() => {
          setFieldValue('isRetractionEnabled', !values['isRetractionEnabled']);
        }}
      />

      {values['isRetractionEnabled'] && (
        <FormikField
          component={PostProcessorSetting}
          dataTestId={`printer-custom-post-processor__setting-useRetractionCustom`}
          label={intl.formatMessage({
            id: 'printers.postprocessor.useRetractionCustom.label',
            defaultMessage: 'Use Custom Instructions',
          })}
          renderAsCheckbox
          disabled={isSubmitting}
          name={'useRetractionCustom'}
          checked={Boolean(values['useRetractionCustom'])}
          onChange={() => {
            setFieldValue(
              'useRetractionCustom',
              !values['useRetractionCustom'],
            );
          }}
        />
      )}

      {values['isRetractionEnabled'] && values['useRetractionCustom'] && (
        <>
          <FormikField
            label={intl.formatMessage({
              id: 'printers.postprocessor.customRetractionInstructions.label',
            })}
            component={PostProcessorSetting}
            dataTestId={`printer-custom-post-processor__setting-customRetractionInstructions`}
            name={'customRetractionInstructions'}
            disabled={isSubmitting}
            renderAsTextArea
            descriptionLabel={
              values['customRetractionInstructions']
                ? intl.formatMessage({
                    id: 'printers.postprocessor.customRetractionInstructions.description',
                  })
                : ''
            }
          />
          <FormikField
            label={intl.formatMessage({
              id: 'printers.postprocessor.customProtractionInstructions.label',
            })}
            component={PostProcessorSetting}
            dataTestId={`printer-custom-post-processor__setting-customProtractionInstructions`}
            name={'customProtractionInstructions'}
            disabled={isSubmitting}
            renderAsTextArea
            descriptionLabel={
              values['customProtractionInstructions']
                ? intl.formatMessage({
                    id: 'printers.postprocessor.customProtractionInstructions.description',
                  })
                : ''
            }
          />
        </>
      )}
      <HorizontalDivider />
    </>
  );
};

PostProcessorRetraction.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorRetraction;

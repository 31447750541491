import React from 'react';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import PromptDialog from '@app/containers/Dialogs/PromptDialog';
import CreateProjectDialog from '@containers/Dialogs/CreateProjectDialog';
import EditProjectDialog from '@containers/Dialogs/EditProjectDialog';
import CreateWorkflowDialog from '@containers/Dialogs/CreateWorkflowDialog';
import EditWorkflowDialog from '@containers/Dialogs/EditWorkflowDialog';
import DuplicateWorkflowDialog from '@containers/Dialogs/DuplicateWorkflowDialog';
import EditFileDialog from '@containers/Dialogs/EditFileDialog';
import WorkflowDetailsDialog from '@containers/Dialogs/WorkflowDetailsDialog';
import EditOperatorDialog from '@containers/Dialogs/EditOperatorDialog';
import PublishFileDialog from '@containers/Dialogs/PublishFileDialog';
import CreateMaterialDialog from '@containers/Dialogs/CreateMaterialDialog';
import CloneMaterialDialog from '@containers/Dialogs/CloneMaterialDialog';
import ResetPasswordDialog from '@containers/Dialogs/ResetPasswordDialog';
import AddMFADialog from '@containers/Dialogs/AddMFADialog';
import CreateUserDialog from '@containers/Dialogs/CreateUserDialog';
import EditUserDialog from '@containers/Dialogs/EditUserDialog';
import UserInfoDialog from '@containers/Dialogs/UserInfoDialog';
import CreatePrinterDialog from '@containers/Dialogs/CreatePrinterDialog';
import DeletePrinterDialog from '@containers/Dialogs/DeletePrinterDialog';
import ManageVisibilityDialog from '@containers/Dialogs/ManageVisibilityDialog';
import DeleteNozzleDialog from '@containers/Dialogs/DeleteNozzleDialog';
import TalkToAiDialog from '@containers/Dialogs/TalkToAiDialog';
import YoutubeVideoDialog from '@containers/Dialogs/YoutubeVideoDialog';

export default function Dialogs() {
  const { isDialogShown } = useDialog();

  //materials
  const showCreateMaterialDialog = isDialogShown(
    ModalDataTypes.CREATE_MATERIAL,
  );
  const showCloneMaterialDialog = isDialogShown(ModalDataTypes.CLONE_MATERIAL);

  if (showCreateMaterialDialog) {
    return <CreateMaterialDialog />;
  }

  if (showCloneMaterialDialog) {
    return <CloneMaterialDialog />;
  }

  //account
  const showResetPasswordDialog = isDialogShown(ModalDataTypes.RESET_PASSWORD);
  const showAddMFADialog = isDialogShown(ModalDataTypes.CONNECT_MFA);

  if (showResetPasswordDialog) {
    return <ResetPasswordDialog />;
  }

  if (showAddMFADialog) {
    return <AddMFADialog />;
  }

  //users
  const showCreateUserDialog = isDialogShown(ModalDataTypes.CREATE_USER);
  const showEditUserDialog = isDialogShown(ModalDataTypes.EDIT_USER);
  const showUserInfoDialog = isDialogShown(ModalDataTypes.USER_INFO);

  if (showCreateUserDialog) {
    return <CreateUserDialog />;
  }

  if (showEditUserDialog) {
    return <EditUserDialog />;
  }

  if (showUserInfoDialog) {
    return <UserInfoDialog />;
  }

  //printers
  const showCreatePrinterDialog = isDialogShown(ModalDataTypes.CREATE_PRINTER);
  const showDeletePrinterDialog = isDialogShown(ModalDataTypes.DELETE_PRINTER);
  const showDeleteNozzleDialog = isDialogShown(ModalDataTypes.DELETE_NOZZLE);
  const showManageVisibilityDialog = isDialogShown(
    ModalDataTypes.MANAGE_VISIBILITY,
  );

  if (showCreatePrinterDialog) {
    return <CreatePrinterDialog />;
  }

  if (showDeletePrinterDialog) {
    return <DeletePrinterDialog />;
  }

  if (showManageVisibilityDialog) {
    return <ManageVisibilityDialog />;
  }

  if (showDeleteNozzleDialog) {
    return <DeleteNozzleDialog />;
  }

  if (isDialogShown(ModalDataTypes.PROMPT)) {
    return <PromptDialog />;
  }

  if (isDialogShown(ModalDataTypes.CREATE_PROJECT)) {
    return <CreateProjectDialog />;
  }

  if (isDialogShown(ModalDataTypes.EDIT_PROJECT)) {
    return <EditProjectDialog />;
  }

  if (isDialogShown(ModalDataTypes.CREATE_WORKFLOW)) {
    return <CreateWorkflowDialog />;
  }

  if (isDialogShown(ModalDataTypes.EDIT_WORKFLOW)) {
    return <EditWorkflowDialog />;
  }

  if (isDialogShown(ModalDataTypes.DUPLICATE_WORKFLOW)) {
    return <DuplicateWorkflowDialog />;
  }

  if (isDialogShown(ModalDataTypes.EDIT_FILE)) {
    return <EditFileDialog />;
  }

  if (isDialogShown(ModalDataTypes.WORKFLOW_DETAILS)) {
    return <WorkflowDetailsDialog />;
  }

  if (isDialogShown(ModalDataTypes.EDIT_OPERATOR)) {
    return <EditOperatorDialog />;
  }

  if (isDialogShown(ModalDataTypes.PUBLISH_FILE)) {
    return <PublishFileDialog />;
  }

  if (isDialogShown(ModalDataTypes.TALK_TO_AI)) {
    return <TalkToAiDialog />;
  }

  if (isDialogShown(ModalDataTypes.YOUTUBE_VIDEO)) {
    return <YoutubeVideoDialog />;
  }

  return null;
}

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Provider, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { SnackbarProvider } from '@contexts/SnackbarContext';
import { TooltipProvider } from '@contexts/TooltipContext';
import { DropDownMenuProvider } from '@contexts/DropDownMenuContext';
import { DialogProvider } from '@contexts/DialogContext';
import { ModalProvider } from '@contexts/ModalContext';
import queryClient from '@api/queryClient';
import configureStore from '@store/configureStore';
import sagaMiddleware from '@store/middlewares/sagaMiddleware';
import rootSaga from '@sagas';
import English from '@app/languages/en-GB.json';
import { getScreenSize } from '@app/selectors/applicationSelectors';
import ApiClientManager from '@containers/ApiClientManager';
import ErrorDialogWrapper from '@components/ErrorDialogWrapper';
import { themeTypes } from '@constants/accountSettings';
import { darkTheme, lightTheme } from '@stylesheets/theme';
import useApplicationTheme from '@hooks/useApplicationTheme';

const store = configureStore();

sagaMiddleware.run(rootSaga);

const ThemeProvider = ({ children }) => {
  const screenSize = useSelector(getScreenSize);
  const applicationTheme = useApplicationTheme();

  const theme = useMemo(() => {
    const selectedTheme =
      applicationTheme === themeTypes.LIGHT.value ? lightTheme : darkTheme;
    return {
      ...selectedTheme,
      screenSize,
    };
  }, [applicationTheme, screenSize]);

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.any,
};

export default function Providers({ children }) {
  return (
    <Provider store={store}>
      <ApiClientManager />

      <IntlProvider
        locale={navigator.language}
        defaultLocale="en-gb"
        messages={English}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <SnackbarProvider>
              <DropDownMenuProvider>
                <TooltipProvider>
                  <DialogProvider>
                    <ModalProvider>
                      <ErrorDialogWrapper>{children}</ErrorDialogWrapper>
                    </ModalProvider>
                  </DialogProvider>
                </TooltipProvider>
              </DropDownMenuProvider>
            </SnackbarProvider>
          </ThemeProvider>

          {DEVELOPMENT_ENV && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </IntlProvider>
    </Provider>
  );
}

Providers.propTypes = {
  children: PropTypes.any,
};

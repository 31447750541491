import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';
import React from 'react';

const PostProcessorABB = ({ isSubmitting }) => {
  const { values, setFieldValue } = useFormikContext();
  const intl = useIntl();
  return (
    <>
      <FormikField
        component={PostProcessorSetting}
        dataTestId={`printer-custom-post-processor__setting-abbIsAnalogSignalEnabled`}
        label={intl.formatMessage({
          id: 'printers.postprocessor.abb.analog.label',
          defaultMessage: 'Use MoveLAO',
        })}
        renderAsCheckbox
        noPaddingBottom
        name={'abbIsAnalogSignalEnabled'}
        checked={Boolean(values['abbIsAnalogSignalEnabled'])}
        onChange={() => {
          setFieldValue(
            'abbIsAnalogSignalEnabled',
            !values['abbIsAnalogSignalEnabled'],
          );
        }}
        disabled={isSubmitting}
      />
      <FormikField
        component={PostProcessorSetting}
        dataTestId={`printer-custom-post-processor__setting-abbAnalogSignalName`}
        name={'abbAnalogSignalName'}
        type={'text'}
        label={intl.formatMessage({
          id: 'printers.postprocessor.abb.analogName.label',
        })}
        disabled={isSubmitting}
        renderAsInput
      />
    </>
  );
};

PostProcessorABB.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorABB;

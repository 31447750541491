import React, { useMemo, useCallback, useEffect } from 'react';
import { Formik, Field as FormikField } from 'formik';
import * as Yup from 'yup';
import { generatePath, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import useWorkflowMutations from '@hooks/workflows/useWorkflowMutations';
import useProjects from '@hooks/projects/useProjectQueries';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { Directories } from '@app/constants/directories';
import { ROUTES } from '@constants/router';
import Dialog from '@components/Workspaces/Dialog';
import DropDownField from '@components/1-atoms/DropDownField';
import Field from '@components/1-atoms/Field';
import { omit } from 'lodash';

const MODAL_ID = ModalDataTypes.DUPLICATE_WORKFLOW;

const DuplicateWorkflowDialog = () => {
  const intl = useIntl();
  const history = useHistory();
  const { getDialogData, hideDialog } = useDialog();
  const { projectsQuery } = useProjects();
  const { createWorkflowMutation } = useWorkflowMutations();

  const { refetch: projectsQueryRefetch } = projectsQuery;

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);
  const workflowToDuplicate = dialogData?.workflow;

  const workflowProject = useMemo(
    () =>
      projectsQuery?.data?.find(
        (project) => project.id === workflowToDuplicate?.workspaceId,
      ),
    [projectsQuery?.data, workflowToDuplicate?.workspaceId],
  );

  const formInitialValues = useMemo(
    () => ({
      name: `${workflowToDuplicate?.name}_copy`,
      project: {
        label: workflowProject?.name,
        value: workflowToDuplicate?.workspaceId,
      },
    }),
    [
      workflowProject?.name,
      workflowToDuplicate?.name,
      workflowToDuplicate?.workspaceId,
    ],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .min(
            5,
            intl.formatMessage({
              id: 'duplicateworkflow.dialog.workflowname.validation.min',
              defaultMessage: 'Name must be at least 5 characters long',
            }),
          )
          .max(
            256,
            intl.formatMessage({
              id: 'duplicateworkflow.dialog.workflowname.validation.max',
              defaultMessage: 'Name must be no longer than 256 characters',
            }),
          )
          .required(
            intl.formatMessage({
              id: 'duplicateworkflow.dialog.workflowname.validation.required',
              defaultMessage: 'Name cannot be empty',
            }),
          ),
        project: Yup.object()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'duplicateworkflow.dialog.project.project.validation.required',
              defaultMessage: 'Please select an option',
            }),
          ),
      }),
    [intl],
  );

  const getProjectDropDownMenuItems = useCallback(
    (values) => {
      const sortedProjects = projectsQuery?.data?.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      const projectOptions = sortedProjects?.map((project) => ({
        label: project.name,
        selected: values?.project?.value === project.id,
        formFieldValue: {
          label: project.name,
          value: project.id,
        },
      }));

      return projectOptions;
    },
    [projectsQuery?.data],
  );

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onFormSubmit = useCallback(
    (values) => {
      const workspaceId = values?.project?.value;

      const operators = workflowToDuplicate?.operators.map((operator) => {
        // extract fields to be removed and rest of the object
        const nextOperator = omit(operator, [
          'values',
          'deprecated',
          'upgradable',
          'operatorDescriptor',
          'nameKey',
          'translatedName',
          'computed',
          'iconUrl',
        ]);

        const updatedValues = operator?.values?.map((value) => {
          // extract "inputTemplateId" field and rest of the object
          const nextValues = omit(value, [
            'inputTemplateId',
            'locked',
            'exposed',
            'triggerComputation',
          ]);

          return nextValues; // return object without "inputTemplateId" field
        });

        return { ...nextOperator, values: updatedValues }; // return updated object with "values" field containing updated values
      });

      const submit = async () => {
        const workflow = await createWorkflowMutation.mutateAsync({
          name: values?.name,
          workspaceId,
          printerId: workflowToDuplicate?.printerId,
          operators: operators,
          nozzleId: workflowToDuplicate?.nozzleId,
          materialId: workflowToDuplicate?.materialId,
        });

        const navigationPath = generatePath(ROUTES.WORKFLOW, {
          workspaceId: workspaceId,
          directory: Directories.workflows,
          itemId: workflow?.id,
        });

        history.push(navigationPath);
      };

      return submit();
    },
    [
      history,
      workflowToDuplicate?.printerId,
      workflowToDuplicate?.operators,
      workflowToDuplicate?.nozzleId,
      workflowToDuplicate?.materialId,
      createWorkflowMutation,
    ],
  );

  useEffect(() => {
    projectsQueryRefetch();
  }, [projectsQueryRefetch]);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
      enableReinitialize
    >
      {({ values, isSubmitting, handleSubmit }) => (
        <Dialog
          dataTestId="duplicate-workflow-dialog"
          dialogId={MODAL_ID}
          title={intl.formatMessage({
            id: 'duplicateworkflow.dialog.title',
            defaultMessage: 'Duplicate Workflow',
          })}
          confirmButtonText={intl.formatMessage({
            id: 'general.duplicate',
            defaultMessage: 'Duplicate',
          })}
          onSubmit={handleSubmit}
          onCancel={handleClose}
          loading={isSubmitting}
        >
          <FormikField
            autoFocus
            component={Field}
            dataTestId="duplicate-workflow-dialog__name-field"
            disabled={isSubmitting}
            name="name"
            label={intl.formatMessage({
              id: 'duplicateworkflow.dialog.form.field.name.label',
              defaultMessage: 'Name',
            })}
          />

          <FormikField
            autoFocus
            component={DropDownField}
            dataTestId="duplicate-workflow-dialog__name-field"
            disabled={projectsQuery?.isLoading || isSubmitting}
            name="project"
            label={intl.formatMessage({
              id: 'duplicateworkflow.dialog.form.field.project.label',
              defaultMessage: 'Select Project',
            })}
            dropDownMenuItems={getProjectDropDownMenuItems(values)}
          />
        </Dialog>
      )}
    </Formik>
  );
};

export default DuplicateWorkflowDialog;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { Formik, Field as FormikField } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import useSteps from '@hooks/useSteps';
import useDialog from '@hooks/useDialog';
import usePrintersQueries from '@hooks/printers/usePrinterQueries';
import usePrinter from '@hooks/printers/usePrinter';
import useWorkflowQueries from '@hooks/workflows/useWorkflowQueries';
import useWorkflowMutations from '@hooks/workflows/useWorkflowMutations';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { ROUTES } from '@constants/router';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import Field from '@components/1-atoms/Field';
import DropDownField from '@components/1-atoms/DropDownField';
import DialogPortal from '@components/2-molecules/DialogPortal';
import { Fields } from './CreateWorkflowDialog.styled';
import { printerConstants } from '@constants/printers/printerConstants';
import { materialSortFunction } from '@utils/material';

const CREATE_WORKFLOW_WORKFLOW_NAME = 'workflowName';
const CREATE_WORKFLOW_NOZZLE = 'nozzle';

const CREATE_WORKFLOW_STEPS = [
  CREATE_WORKFLOW_WORKFLOW_NAME,
  CREATE_WORKFLOW_NOZZLE,
];

const MODAL_ID = ModalDataTypes.CREATE_WORKFLOW;

const CreateWorkflowDialog = () => {
  const intl = useIntl();
  const history = useHistory();
  const [nozzlePrinterId, setNozzlePrinterId] = useState('');
  const { hideDialog, getDialogData } = useDialog();
  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);
  const { projectWorkflowsQuery } = useWorkflowQueries({
    projectId: dialogData?.projectId,
  });
  const { createWorkflowMutation } = useWorkflowMutations();
  const { getPrinterMaterials, getPrinterNozzles, getExtruderDefinition } =
    usePrinter();
  const { printersQuery, allNozzlesQuery, materialsQuery } =
    usePrintersQueries();
  const materials = getPrinterMaterials(nozzlePrinterId);
  const nozzles = getPrinterNozzles(nozzlePrinterId);

  const { goNext, isStepActive, currentStepName, isLastStep } = useSteps({
    steps: CREATE_WORKFLOW_STEPS,
  });

  const formikRef = useRef();
  const isLoading =
    !printersQuery?.isFetched ||
    allNozzlesQuery.isFetching ||
    materialsQuery.isFetching;
  const refetchPrintersQuery = printersQuery.refetch;
  const refetchAllNozzlesQuery = allNozzlesQuery.refetch;
  const refetchMaterialsQuery = materialsQuery.refetch;

  const lastStepIsActive = useMemo(() => isLastStep(), [isLastStep]);

  const formInitialValues = useMemo(
    () => ({
      workflowName: 'Untitled workflow',
      printer: null,
      nozzle: null,
      material: null,
    }),
    [],
  );

  const isNozzleSelectionEnabled = useCallback(
    (printerId) => {
      const printer = printersQuery.data.find(
        (printer) => printer.id === printerId,
      );
      const extruderDefinition = getExtruderDefinition(printer);
      return printerConstants.METAL_WIRE !== extruderDefinition?.extrusionType;
    },
    [printersQuery, getExtruderDefinition],
  );

  const validationSchema = useMemo(() => {
    const validationFields = {};

    validationFields.workflowName = Yup.string()
      .required(
        intl.formatMessage({
          id: 'createworkflow.workflowname.validation.required',
          defaultMessage: 'Name cannot be empty',
        }),
      )
      .min(
        5,
        intl.formatMessage({
          id: 'createworkflow.workflowname.validation.min',
          defaultMessage: 'Name must be at least 5 characters long',
        }),
      )
      .max(
        256,
        intl.formatMessage({
          id: 'createworkflow.workflowname.validation.max',
          defaultMessage: 'Name must be no longer than 256 characters',
        }),
      )
      .test(
        'is-unique',
        intl.formatMessage({
          id: 'createworkflow.workflowname.validation.unique',
          defaultMessage: 'Name must be unique',
        }),
        (value) => {
          const projectWorkflows = projectWorkflowsQuery.data;
          const isNameUnique = !projectWorkflows?.some(
            (workflow) => workflow.name === value,
          );

          return isNameUnique;
        },
      );

    validationFields.printer = Yup.object()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'createworkflow.printer.validation.required',
          defaultMessage: 'Please select an option',
        }),
      );

    if (lastStepIsActive) {
      validationFields.nozzle = Yup.mixed().when('printer', {
        is: (printer) =>
          printer != null && isNozzleSelectionEnabled(printer.value),
        then: Yup.object()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'createworkflow.nozzle.validation.required',
              defaultMessage: 'Please select an option',
            }),
          ),
        otherwise: Yup.mixed().notRequired(),
      });
    }

    return Yup.object().shape(validationFields);
  }, [
    isNozzleSelectionEnabled,
    intl,
    lastStepIsActive,
    projectWorkflowsQuery.data,
  ]);

  const getPrinterDropDownMenuItems = useCallback(
    (values) => {
      return printersQuery.data
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((printer) => ({
          label: printer.name,
          selected: values?.printer?.value === printer.id,
          formFieldValue: {
            label: printer.name,
            value: printer.id,
          },
          onClick: () => {
            setNozzlePrinterId(printer.id);

            if (!allNozzlesQuery?.isFetched) {
              refetchAllNozzlesQuery();
            }

            if (!materialsQuery?.isFetched) {
              refetchMaterialsQuery();
            }

            if (!values?.printer) {
              goNext();
            }
          },
        }));
    },
    [
      printersQuery,
      setNozzlePrinterId,
      allNozzlesQuery?.isFetched,
      materialsQuery?.isFetched,
      refetchAllNozzlesQuery,
      refetchMaterialsQuery,
      goNext,
    ],
  );

  const getNozzleDropDownMenuItems = useCallback(
    (values) => {
      return nozzles?.map((nozzle) => ({
        label: nozzle.name,
        selected: values?.nozzle?.value === nozzle.id,
        formFieldValue: {
          label: nozzle.name,
          value: nozzle.id,
        },
      }));
    },
    [nozzles],
  );

  const getMaterialDropDownMenuItems = useCallback(
    (values) => {
      return materials?.sort(materialSortFunction).map((material) => ({
        label: material.materialName,
        selected: values?.material?.value === material.id,
        formFieldValue: {
          label: material.materialName,
          value: material.id,
        },
      }));
    },
    [materials],
  );

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onFormSubmit = useCallback(
    (values, { setSubmitting, setTouched }) => {
      if (lastStepIsActive) {
        return (async () => {
          const { workflowName, printer, nozzle, material } = values;
          const projectId = dialogData?.projectId;

          const workflow = await createWorkflowMutation.mutateAsync({
            name: workflowName,
            printerId: printer.value,
            operators: [],
            workspaceId: projectId,
            nozzleId: isNozzleSelectionEnabled(printer.value)
              ? nozzle.value
              : '',
            materialId: material.value,
          });

          const navigationPath = generatePath(ROUTES.WORKFLOW, {
            workspaceId: projectId,
            itemId: workflow?.id,
          });

          history.push(navigationPath);
        })();
      }

      setTouched({});
      setSubmitting(false);
      goNext();
    },
    [
      isNozzleSelectionEnabled,
      createWorkflowMutation,
      dialogData?.projectId,
      goNext,
      history,
      lastStepIsActive,
    ],
  );

  useEffect(() => {
    const skip = !nozzlePrinterId || isLoading;

    if (skip) return;

    const defaultNozzle = nozzles?.[0];
    const material = materials?.[0];

    if (defaultNozzle) {
      formikRef.current.setFieldValue('nozzle', {
        label: defaultNozzle.name,
        value: defaultNozzle.id,
      });
    } else {
      formikRef.current.setFieldValue('nozzle', null);
    }

    if (material) {
      formikRef.current.setFieldValue('material', {
        label: material.materialName,
        value: material.id,
      });
    } else {
      formikRef.current.setFieldValue('material', null);
    }
  }, [nozzlePrinterId, nozzles, materials, isLoading]);

  useEffect(() => {
    refetchPrintersQuery();
  }, [refetchPrintersQuery]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ values, handleSubmit, isSubmitting, errors, touched }) => (
        <DialogPortal
          renderAsForm
          dataTestId="create-project"
          dialogId={MODAL_ID}
          loading={isSubmitting || isLoading}
          onClose={handleClose}
          onSubmit={handleSubmit}
          primaryButtonLabel={
            lastStepIsActive
              ? intl.formatMessage({
                  id: 'general.create',
                  defaultMessage: 'Create',
                })
              : intl.formatMessage({
                  id: 'general.next',
                  defaultMessage: 'Next',
                })
          }
          secondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
        >
          {[CREATE_WORKFLOW_WORKFLOW_NAME, CREATE_WORKFLOW_NOZZLE].includes(
            currentStepName,
          ) && (
            <>
              <PageHeader
                variant={PAGE_HEADER_VARIANT_MEDIUM}
                title={intl.formatMessage({
                  id: 'createproject.workflow.header.title',
                  defaultMessage: 'Create Workflow',
                })}
                subtitle={intl.formatMessage({
                  id: 'createproject.workflow.header.subtitle',
                  defaultMessage:
                    'A workflow refers to a series of instructions or manufacturing steps',
                })}
              />

              <Fields>
                <FormikField
                  autoFocus={isStepActive(CREATE_WORKFLOW_WORKFLOW_NAME)}
                  component={Field}
                  dataTestId="create-project__workflow-name-field"
                  disabled={isSubmitting}
                  name="workflowName"
                  label={intl.formatMessage({
                    id: 'createworkflow.workflowname.label',
                    defaultMessage: 'Name',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'createworkflow.workflowname.placeholder',
                    defaultMessage: 'Untitled Workflow',
                  })}
                />

                <FormikField
                  component={DropDownField}
                  dataTestId="create-project__printer-dropdown-field"
                  disabled={
                    isSubmitting ||
                    isLoading ||
                    (!!errors?.workflowName && !!touched?.workflowName)
                  }
                  name="printer"
                  label={intl.formatMessage({
                    id: 'createworkflow.printer.label',
                    defaultMessage: 'Printer',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'createworkflow.printer.placeholder',
                    defaultMessage: 'Select Printer',
                  })}
                  dropDownMenuItems={getPrinterDropDownMenuItems(values)}
                  emptyDropDownStateIconName="precision_manufacturing_0"
                  emptyDropDownStateTitle={intl.formatMessage({
                    id: 'createworkflow.printer.empty_state.title',
                    defaultMessage: 'No printer yet',
                  })}
                  emptyDropDownStateDescription={intl.formatMessage({
                    id: 'createworkflow.printer.empty_state.description',
                    defaultMessage: 'To continue please add a printer',
                  })}
                  emptyDropDownStateLinkButtonLabel={intl.formatMessage({
                    id: 'createworkflow.printer.empty_state.link_button.label',
                    defaultMessage: 'Add Printer',
                  })}
                  emptyDropDownStateLinkButtonPath={ROUTES.PRINTERS}
                />
              </Fields>
            </>
          )}

          {isStepActive(CREATE_WORKFLOW_NOZZLE) && (
            <Fields>
              {isNozzleSelectionEnabled(values?.printer.value) && (
                <FormikField
                  component={DropDownField}
                  dataTestId="create-project__nozzle-dropdown-field"
                  disabled={isSubmitting || isLoading}
                  name="nozzle"
                  label={intl.formatMessage({
                    id: 'createworkflow.nozzle.label',
                    defaultMessage: 'Nozzle',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'createworkflow.nozzle.placeholder',
                    defaultMessage: 'Select Nozzle',
                  })}
                  dropDownMenuItems={getNozzleDropDownMenuItems(values)}
                  emptyDropDownStateIconName="precision_manufacturing_0"
                  emptyDropDownStateTitle={intl.formatMessage({
                    id: 'createworkflow.nozzle.empty_state.title',
                    defaultMessage: 'No nozzle yet',
                  })}
                  emptyDropDownStateDescription={intl.formatMessage({
                    id: 'createworkflow.nozzle.empty_state.description',
                    defaultMessage: 'To continue please add a nozzle',
                  })}
                  emptyDropDownStateLinkButtonLabel={intl.formatMessage({
                    id: 'createworkflow.nozzle.empty_state.link_button.label',
                    defaultMessage: 'Add Nozzle',
                  })}
                  emptyDropDownStateLinkButtonPath={generatePath(
                    ROUTES.PRINTER,
                    {
                      printerId: nozzlePrinterId,
                      directory: 'prints',
                    },
                  )}
                />
              )}

              <FormikField
                component={DropDownField}
                dataTestId="create-project__material-dropdown-field"
                disabled={isSubmitting || isLoading}
                name="material"
                label={intl.formatMessage({
                  id: 'createproject.project.material.label',
                  defaultMessage: 'Material',
                })}
                placeholder={intl.formatMessage({
                  id: 'createproject.project.material.placeholder',
                  defaultMessage: 'Select Material',
                })}
                dropDownMenuItems={getMaterialDropDownMenuItems(values)}
                emptyDropDownStateIconName="precision_manufacturing_0"
                emptyDropDownStateTitle={intl.formatMessage({
                  id: 'createproject.project.material.empty_state.title',
                  defaultMessage: 'No material yet',
                })}
                emptyDropDownStateDescription={intl.formatMessage({
                  id: 'createproject.project.material.empty_state.description',
                  defaultMessage: 'To continue please add a material',
                })}
                emptyDropDownStateLinkButtonLabel={intl.formatMessage({
                  id: 'createproject.project.material.empty_state.link_button.label',
                  defaultMessage: 'Add Material',
                })}
                emptyDropDownStateLinkButtonPath={generatePath(
                  ROUTES.MATERIALS,
                )}
              />
            </Fields>
          )}
        </DialogPortal>
      )}
    </Formik>
  );
};

CreateWorkflowDialog.propTypes = {};

export default CreateWorkflowDialog;

import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import useDialog from '@hooks/useDialog';
import useModal from '@hooks/useModal';
import useWorkflowList from '@hooks/workflows/useWorkflowList';
import useWorkflowQueries from '@hooks/workflows/useWorkflowQueries';
import { getCurrentUser } from '@selectors/loginSelectors';
import { ROUTES } from '@constants/router';
import { ModalDataTypes, MODAL_IDS } from '@constants/modalDataTypes';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import EmptyStateBox from '@components/2-molecules/EmptyStateBox';
import ListContainer from '@components/3-organisms/ListContainer';
import { ContentWrapper, Wrapper, BannerWrapper } from './Home.styled';
import { ICON_BUTTON_VARIANT_PRIMARY } from '@components/1-atoms/IconButton';
import WorkflowExamples from '@containers/WorkflowExamples';
import Banner from '@app/components/1-atoms/Banner';
import { getFromLocalStorage } from '@utils/localStorage';
import { HIDE_WELCOME_MODAL_KEY } from '@constants/utilityConstants';
import useLeanUpon from '@hooks/learnUpon/useLeanUpon';

const Home = () => {
  const intl = useIntl();
  const history = useHistory();
  const user = useSelector(getCurrentUser());

  const { showDialog } = useDialog();
  const { showModal } = useModal();
  const { recentWorkflowsQuery } = useWorkflowQueries();
  const { getWorkflowDropDownMenu } = useWorkflowList();
  const { refetch: refetchRecentWorkflowsQuery } = recentWorkflowsQuery;
  const { onLearnUponLinkClick } = useLeanUpon();

  const workflows = recentWorkflowsQuery.data;

  const recentWorkflowsListItems = useMemo(() => {
    if (!workflows) return [];

    const sortedWorkflows = workflows.sort((leftWorkflow, rightWorkflow) =>
      moment(rightWorkflow.lastModifiedAt).diff(
        moment(leftWorkflow.lastModifiedAt),
      ),
    );

    const recentWorkflows = sortedWorkflows.slice(0, 4);

    return recentWorkflows?.map((workflow) => {
      const isUserInOrganization =
        (workflow?.organization || workflow?.organizationId) ===
        user?.organizationId;
      const isPublic = workflow?.public || workflow?.publicAccess;
      let optionalListItemProps = {};

      if (isPublic) {
        optionalListItemProps = {
          label: intl.formatMessage(
            {
              id: 'homepage.recent_workflows.workflow_name_public',
              defaultMessage: '{workflowName} (Public)',
            },
            { workflowName: workflow?.name },
          ),
          leadingIconName: 'language_0',
        };
      }

      if (isUserInOrganization) {
        optionalListItemProps = {
          ...optionalListItemProps,
          endingIconButtonIconName: 'more_vert_0',
          endingIconButtonDropDownProps: {
            dropDownMenuItems: getWorkflowDropDownMenu(workflow),
          },
        };
      }

      return {
        id: workflow?.id,
        label: workflow?.name,
        description: intl.formatMessage(
          {
            id: 'homepage.recent_workflows.workflow_last_update',
            defaultMessage: 'Last update: {date}',
          },
          { date: moment(workflow?.lastModifiedAt).format('DD/MM/YYYY') },
        ),
        leadingIconName: 'network_node_0',
        onClick: () =>
          history.push(
            generatePath(ROUTES.WORKFLOW, {
              workspaceId: workflow?.workspaceId,
              itemId: workflow?.id,
            }),
          ),
        ...optionalListItemProps,
      };
    });
  }, [intl, history, workflows, user, getWorkflowDropDownMenu]);

  const showEmptyState =
    !recentWorkflowsQuery.isLoading &&
    recentWorkflowsQuery.isSuccess &&
    isEmpty(recentWorkflowsListItems);
  const showRecentWorkflows =
    recentWorkflowsQuery.isLoading || !isEmpty(recentWorkflowsListItems);

  useEffect(() => {
    refetchRecentWorkflowsQuery();
  }, [refetchRecentWorkflowsQuery]);

  useEffect(() => {
    if (!getFromLocalStorage(HIDE_WELCOME_MODAL_KEY)) {
      showModal(MODAL_IDS.WELCOME);
    }
  }, [showModal]);

  const getAddButtonDropDownMenuActions = useCallback(() => {
    return [
      {
        leadingIconName: 'category_0',
        label: intl.formatMessage({
          id: 'createproject.project.header.title',
          defaultMessage: 'Create new project',
        }),
        onClick: () => showDialog(ModalDataTypes.CREATE_PROJECT),
      },
      {
        leadingIconName: 'spool_0',
        label: intl.formatMessage({
          id: 'materials.create_material_dialog.header.title',
          defaultMessage: 'Add new material',
        }),
        onClick: () => showDialog(ModalDataTypes.CREATE_MATERIAL),
      },
      {
        leadingIconName: 'precision_manufacturing_0',
        label: intl.formatMessage({
          id: 'homepage.add_button.add.printer.label',
          defaultMessage: 'Add new printer',
        }),
        onClick: () => showDialog(ModalDataTypes.CREATE_PRINTER),
      },
    ];
  }, [showDialog, intl]);

  return (
    <Wrapper withPaddingBottom={showEmptyState}>
      <PageHeader
        size={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'homepage.title',
          defaultMessage: 'Home',
        })}
        endingIconButtonIconName={'add_0'}
        endingIconButtonDropDownProps={{
          dropDownMenuItems: getAddButtonDropDownMenuActions(),
        }}
        endingIconButtonVariant={ICON_BUTTON_VARIANT_PRIMARY}
      />

      <ContentWrapper>
        {showEmptyState && (
          <EmptyStateBox
            title={intl.formatMessage({
              id: 'homepage.empty_state.title',
              defaultMessage: 'Get started',
            })}
            description={intl.formatMessage({
              id: 'homepage.empty_state.description',
              defaultMessage:
                'Create a Project to to start managing manufacturing workflows',
            })}
            primaryButtonIconName="add_0"
            primaryButtonLabel={intl.formatMessage({
              id: 'homepage.empty_state.create_project',
              defaultMessage: 'Create project',
            })}
            onPrimaryButtonClick={() =>
              showDialog(ModalDataTypes.CREATE_PROJECT)
            }
          />
        )}

        {showRecentWorkflows && (
          <ListContainer
            loading={recentWorkflowsQuery.isLoading}
            headerTitle={intl.formatMessage({
              id: 'homepage.recent_workflows.title',
              defaultMessage: 'Recent Workflows',
            })}
            listItems={recentWorkflowsListItems}
            skeletonLength={4}
            scrollable={false}
          />
        )}

        <BannerWrapper>
          <Banner
            iconName="school_0"
            title={intl.formatMessage({
              id: 'homepage.banner.title',
              defaultMessage: 'Tutorials',
            })}
            description={intl.formatMessage({
              id: 'homepage.banner.description',
              defaultMessage:
                'Discover more about the Aibuild platform. Learn how to get started and manage your workflows',
            })}
            buttonLabel={intl.formatMessage({
              id: 'homepage.banner.button_title',
              defaultMessage: 'View all tutorials',
            })}
            onButtonClick={onLearnUponLinkClick}
            imageSrc="/img/banner-background.png"
          />
        </BannerWrapper>

        <WorkflowExamples />
      </ContentWrapper>
    </Wrapper>
  );
};

Home.propTypes = {};

export default Home;

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { CHIP_VARIANT_PRIMARY } from '@components/1-atoms/Chip';
import PageList from '@components/3-organisms/PageList';

const WorkflowsPageContent = ({
  handleTabClick,
  headerLeadingIconButtonIconName,
  headerTitle,
  loading,
  onHeaderLeadingIconButtonClick,
  project = {},
  tabs,
  workflowListItems = [],
}) => {
  const intl = useIntl();
  const { showDialog } = useDialog();
  const projectId = project?.id;

  const showCreateWorkflowDialog = useCallback(() => {
    showDialog(ModalDataTypes.CREATE_WORKFLOW, { projectId });
  }, [showDialog, projectId]);

  const actionChips = useMemo(() => {
    const chips = [];

    if (!project?.publicAccess) {
      chips.push({
        id: 'workflows-page-new-workflow-chip',
        variant: CHIP_VARIANT_PRIMARY,
        dataTestId: 'workflows-page__create-new-workflow-chip',
        label: intl.formatMessage({
          id: 'workflowspage.workflows_list.action_chips.new_workflow',
          defaultMessage: 'New Workflow',
        }),
        leadingIconName: 'add_0',
        onClick: showCreateWorkflowDialog,
      });
    }

    return chips;
  }, [project, showCreateWorkflowDialog, intl]);

  return (
    <PageList
      dataTestId="workflows-page-content"
      loading={loading}
      headerTitle={headerTitle}
      tabs={tabs}
      onTabClick={handleTabClick}
      listItems={workflowListItems}
      headerLeadingIconButtonIconName={headerLeadingIconButtonIconName}
      onHeaderLeadingIconButtonClick={onHeaderLeadingIconButtonClick}
      createItemMessageIconName="network_node_0"
      createItemMessageTitle={intl.formatMessage({
        id: 'workflowspage.workflows_list.create_workflow_title',
        defaultMessage: 'No workflow yet',
      })}
      createItemMessagDescription={intl.formatMessage({
        id: 'workflowspage.workflows_list.create_workflow_description',
        defaultMessage: 'Use workflows to create manufacturing strategies.',
      })}
      createItemMessageButtonIconName="add_0"
      createItemMessageButtonLabel={intl.formatMessage({
        id: 'workflowspage.workflows_list.create_workflow_button',
        defaultMessage: 'Create Workflow',
      })}
      createItemMessageOnButtonClick={showCreateWorkflowDialog}
      actionChips={actionChips}
      sortOptions={[
        {
          label: intl.formatMessage({
            id: 'general.date',
            defaultMessage: 'Date',
          }),
          value: 'createdAt',
        },
        {
          label: intl.formatMessage({
            id: 'general.name',
            defaultMessage: 'Name',
          }),
          value: 'label',
        },
      ]}
    />
  );
};

WorkflowsPageContent.propTypes = {
  handleTabClick: PropTypes.func,
  headerLeadingIconButtonIconName: PropTypes.string,
  loading: PropTypes.bool,
  onHeaderLeadingIconButtonClick: PropTypes.func,
  project: PropTypes.object,
  workflowListItems: PropTypes.array,
  headerTitle: PropTypes.string,
  tabs: PropTypes.array,
};

export default WorkflowsPageContent;

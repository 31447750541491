import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const authQueryKeys = {
  tokenValidity: ['showLoader', 'tokenValidity'],
};

export default function useAuthQueries({ token } = {}) {
  const checkTokenValidityQuery = useQuery({
    queryKey: authQueryKeys.tokenValidity,
    queryFn: async () => {
      try {
        await client.head(
          endpoints.passwordResetTokenValidity.replace(':token', token),
        );
        return { tokenInvalid: false };
      } catch (error) {
        if (error.response.status === 400 || error.response.status === 404) {
          return { tokenInvalid: true };
        }
        throw error;
      }
    },
    retry: () => {
      return false; //no retries
    },
    enabled: false,
  });

  return {
    checkTokenValidityQuery,
  };
}

import * as THREE from 'three';
import { Printer } from '../../Printer';
import { printerConstants as constants } from '../../../constants/printers/printerConstants';
import { EXTERNAL_JOINT_ANGLE } from '../../../constants/machineConstants';
import { ExternalAxis } from '../../ExternalAxis';
import { machineConstants } from '../../../constants/printers/machineConstants';
import Machine from '../Machine';

/**
 * Represents a gantry object, which is composed of a tree of THREE.js components that are
 * necessary for visualisation of the movement of the machine.
 */
class Gantry extends Machine {
  constructor(robotType, machineDefinitions, printerSettings, bed) {
    super(robotType, machineDefinitions, printerSettings, constants.Gantry);
    this.zMovement = this.machineDefaults[constants.zMovement];
    this.bed = bed;

    this.props[machineConstants.typeDiscriminator] =
      machineConstants.gantryDiscriminator;
    this.props[machineConstants.gantryType] = machineConstants.gantryType3Axis;
    this.setPropertiesHomePosition();
    this.setPropertiesBasisDirections(printerSettings);
    this.createJointGroups();
  }

  /**
   * Updates the 'home' properties of this object based on the values
   * provided in the 'printerSettings' object. If these values are not available,
   * default values are used.
   *
   * @param {Object} printerSettings - The settings for the printer, which contains
   *                                   a 'machine' property which should define a Gantry.
   */
  setPropertiesHomePosition() {
    this.props.axis1Home = this.machineDefaults.defaultHomePosition[0];
    this.props.axis2Home = this.machineDefaults.defaultHomePosition[1];
    this.props.axis3Home = this.machineDefaults.defaultHomePosition[2];
  }

  /**
   * Updates the 'basis direction' properties of this object based on the values
   * provided in the 'printerSettings' object. If these values are not available,
   * default values are used.
   *
   * @param {Object} printerSettings - The settings for the printer, which contains
   *                                   a 'machine' property which should define a Gantry.
   */
  setPropertiesBasisDirections(printerSettings) {
    this.props.basis1DirectionX = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.basis1DirectionX,
      1,
    );
    this.props.basis1DirectionY = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.basis1DirectionY,
      0,
    );
    this.props.basis1DirectionZ = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.basis1DirectionZ,
      0,
    );

    this.props.basis2DirectionX = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.basis2DirectionX,
      0,
    );
    this.props.basis2DirectionY = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.basis2DirectionY,
      1,
    );
    this.props.basis2DirectionZ = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.basis2DirectionZ,
      0,
    );

    this.props.basis3DirectionX = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.basis3DirectionX,
      0,
    );
    this.props.basis3DirectionY = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.basis3DirectionY,
      0,
    );
    this.props.basis3DirectionZ = Machine.getMachineSettingValueOrDefault(
      printerSettings,
      constants.basis3DirectionZ,
      1,
    );
  }

  /**
   * Creates the part groups for each axes and the flange to mount the extruder
   */
  createJointGroups() {
    this.components = this.machineDefaults[constants.components];
    if (!this.components) {
      //eslint-disable-next-line no-console
      console.error('This machine is missing axis configuration information');
      return;
    }

    for (const [key, value] of Object.entries(this.components)) {
      if (key != 'flange') this[key] = new THREE.Group();
      this[key].movement = value.movement;
      this[key].offset = value.offset;
      this.add(this[key]);
      this[key].position.set(
        this[key].offset.x,
        this[key].offset.y,
        this[key].offset.z,
      );
      if (this[key].offset.rotationParts) {
        this[key].offset.rotation = new THREE.Euler(
          this[key].offset.rotationParts.x,
          this[key].offset.rotationParts.y,
          this[key].offset.rotationParts.z,
          this[key].offset.rotationParts.type,
        );
        this[key].rotation.set(this[key].offset.rotation);
      }
      this.name = this[key];
    }
    this.add(this.flange);
  }

  /**
   * Converts an XYZ vector defined relative to the bed's coordinate system
   * to an XYZ vector defined relative to the gantry's coordinate system.
   *
   * Assumes the gantry coordinate system is identical to the global coordinate
   * system.
   * @param {THREE.Vector3} baseVector
   */
  convertBedToGantryCoordinateSystem(baseVector) {
    const matrix = new THREE.Matrix4().copy(this.bed.baseTransformationMatrix);
    return baseVector.applyMatrix4(matrix);
  }

  /**
   * Converts the tool calibration vector to use the basis vectors of the bed
   * coordinate system. The offset of the origin is removed because this is
   * irrelevant.
   * @param {*} toolCalibration
   * @returns
   */
  getToolCalibrationInBedBasis(toolCalibration) {
    const matrix = new THREE.Matrix4().copy(this.bed.baseTransformationMatrix);
    matrix.invert();
    matrix.setPosition(0, 0, 0);
    return toolCalibration.applyMatrix4(matrix);
  }

  /**
   * Moves the gantry axes based on the simulation data that has been provided.
   * The simulation data is always relative to the base, so vectors need to be
   * converted from the base coordinate system to the gantry coordinate system
   * so that the axes know which positions they need to move to.
   * @param {*} simulationData
   */
  simulate(simulationData) {
    const jointAngle1 =
      simulationData.previousStep['jointAngle1'] +
      (simulationData.currentStep['jointAngle1'] -
        simulationData.previousStep['jointAngle1']) *
        simulationData.stepRatio;
    const jointAngle2 =
      simulationData.previousStep['jointAngle2'] +
      (simulationData.currentStep['jointAngle2'] -
        simulationData.previousStep['jointAngle2']) *
        simulationData.stepRatio;
    const jointAngle3 =
      simulationData.previousStep['jointAngle3'] +
      (simulationData.currentStep['jointAngle3'] -
        simulationData.previousStep['jointAngle3']) *
        simulationData.stepRatio;
    const gantryVector = new THREE.Vector3(
      jointAngle1,
      jointAngle2,
      jointAngle3,
    );
    const joint1Name = EXTERNAL_JOINT_ANGLE + 1;
    const joint1Value =
      simulationData.previousStep[joint1Name] +
      (simulationData.currentStep[joint1Name] -
        simulationData.previousStep[joint1Name]) *
        simulationData.stepRatio;
    const joint2Name = EXTERNAL_JOINT_ANGLE + 2;
    const joint2Value =
      simulationData.previousStep[joint2Name] +
      (simulationData.currentStep[joint2Name] -
        simulationData.previousStep[joint2Name]) *
        simulationData.stepRatio;

    if (this.bed instanceof ExternalAxis) {
      gantryVector.applyMatrix4(
        this.bed.getRotatedBaseTransformationMatrix(joint1Value, joint2Value),
      );
    }
    if (this.robotType === 'Spark') {
      return this.moveSparkAxes(gantryVector);
    }
    this.moveAxes(gantryVector, simulationData.previousStep.toolId);
  }

  /**
   * Moves the gantry axes to their home positions, as defined in the robot
   * definitions page.
   */
  moveHomePosition() {
    const gantryVector = this.convertBedToGantryCoordinateSystem(
      new THREE.Vector3(
        this.props.axis1Home,
        this.props.axis2Home,
        this.props.axis3Home,
      ),
    );
    this.moveAxes(gantryVector, 0);
  }

  /**
   * Loads the gantry gltf model, then iterates through the children to create property
   * references based on the name of the child, e.g. if the child name is Axis1, and there is
   * no this.Axis1 property, then a new THREE.Group() is created and this.Axis1 is assigned to this
   * group. The child, and all children with a name beginning with Axis1 are added to this group.
   */
  initializeMachineGeometry() {
    return new Promise((resolve) => {
      const model = Printer.getModel(`/models/Gantry_${this.fileKey}.glb`);
      model.then((model) => {
        model.children.forEach((child) => {
          const axis = child.name.split(constants._)[0].toLowerCase();
          if (this[axis]) {
            this[axis].add(child.clone());
          } else {
            this.add(child.clone());
          }
        });
        resolve();
      });
    });
  }

  /**
   * Iterates through all components of the gantry printer and checks for which
   * axes they should be moved. e.g. if component.movement contains ['x', 'y'], it
   * should move to the target x, y position provided. It should not move in z.
   * @param {THREE.Vector3} target vector in gantry coordinate system
   */
  moveAxes(target, toolId) {
    for (const componentName of Object.keys(this.components)) {
      const component = this[componentName];
      const { offset } = component;
      let targetX = target.x + offset.x;
      let targetY = target.y + offset.y;
      let targetZ = target.z + offset.z;
      if (this.tool) {
        const calibration = this.tool.calibration[toolId];
        if (!calibration) {
          //eslint-disable-next-line no-console
          console.error('No tool has been configured with the input id');
          return;
        }
        targetX -= calibration.toolX;
        targetY -= calibration.toolY;
        targetZ -= calibration.toolZ;
      }
      this.doMovement(component, targetX, targetY, targetZ);
    }
  }

  doMovement(component, targetX, targetY, targetZ) {
    if (component.movement.includes('x')) {
      component.position.set(
        targetX,
        component.position.y,
        component.position.z,
      );
    }
    if (component.movement.includes('y')) {
      component.position.set(
        component.position.x,
        targetY,
        component.position.z,
      );
    }
    if (component.movement.includes('z')) {
      component.position.set(
        component.position.x,
        component.position.y,
        targetZ,
      );
    }
    if (component.movement.includes('-x')) {
      component.position.set(
        -targetX,
        component.position.y,
        component.position.z,
      );
    }
    if (component.movement.includes('-y')) {
      component.position.set(
        component.position.x,
        -targetY,
        component.position.z,
      );
    }
    if (component.movement.includes('-z')) {
      component.position.set(
        component.position.x,
        component.position.y,
        -targetZ,
      );
    }
  }

  moveSparkAxes(target) {
    const targetX = target.x;
    const targetY = target.y;
    this.axis1.position.set(0, targetY * 0.707106781, targetY * 0.707106781);
    this.axis2.position.set(
      targetX,
      targetY * 0.707106781,
      targetY * 0.707106781,
    );
    this.flange.position.set(
      targetX,
      targetY * 0.707106781,
      targetY * 0.707106781,
    );
  }
}

export default Gantry;

import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';
import React from 'react';
import {
  ABB,
  KUKA,
} from '@constants/printers/postProcessorSettingsDefinitions';

const PostProcessorToolType = ({ isSubmitting, programType }) => {
  const { values, setFieldValue } = useFormikContext();
  const intl = useIntl();

  return (
    <>
      <FormikField
        component={PostProcessorSetting}
        dataTestId={`printer-custom-post-processor__setting-isToolChangingEnabled`}
        label={intl.formatMessage({
          id: 'printers.postprocessor.toolChange.label',
          defaultMessage: 'Enable Tool Changing',
        })}
        renderAsCheckbox
        disabled={isSubmitting}
        name={'isToolChangingEnabled'}
        checked={Boolean(values['isToolChangingEnabled'])}
        noPaddingTop={
          programType === ABB ||
          (programType === KUKA && !values['isToolExternalAxis'])
        }
        onChange={() => {
          setFieldValue(
            'isToolChangingEnabled',
            !values['isToolChangingEnabled'],
          );
        }}
        descriptionLabel={
          values['isToolChangingEnabled']
            ? intl.formatMessage({
                id: 'printers.postprocessor.toolChange.description.label',
              })
            : ''
        }
      />

      {values['isToolChangingEnabled'] && (
        <FormikField
          component={PostProcessorSetting}
          dataTestId={`printer-custom-post-processor__setting-extrusionRateChangeInstruction`}
          name={'toolChangeInstruction'}
          disabled={isSubmitting}
          renderAsTextArea
        />
      )}
    </>
  );
};

PostProcessorToolType.propTypes = {
  isSubmitting: PropTypes.bool,
  programType: PropTypes.string,
};

export default PostProcessorToolType;

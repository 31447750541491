import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Field as FormikField } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import useSteps from '@hooks/useSteps';
import useDialog from '@hooks/useDialog';
import useProjectQueries from '@hooks/projects/useProjectQueries';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import usePrinter from '@hooks/printers/usePrinter';
import useProjectMutations from '@hooks/projects/useProjectMutations';
import useWorkflowMutations from '@hooks/workflows/useWorkflowMutations';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { Directories } from '@constants/directories';
import { ROUTES } from '@constants/router';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import Field from '@components/1-atoms/Field';
import DropDownField from '@components/1-atoms/DropDownField';
import DialogPortal from '@components/2-molecules/DialogPortal';
import { Fields } from './CreateProjectDialog.styled';
import SettingActionRow from '@components/2-molecules/SettingActionRow';
import { printerConstants } from '@constants/printers/printerConstants';

const CREATE_PROJECT_PROJECT = 'project';
const CREATE_PROJECT_WORKFLOW = 'workflow';
const CREATE_PROJECT_NOZZLE = 'nozzle';
const CREATE_PROJECT_SELECT_PROJECT = 'selectProject';

const CREATE_PROJECT_STEPS = [
  CREATE_PROJECT_SELECT_PROJECT,
  CREATE_PROJECT_PROJECT,
  CREATE_PROJECT_WORKFLOW,
  CREATE_PROJECT_NOZZLE,
];

const MODAL_ID = ModalDataTypes.CREATE_PROJECT;

const CreateProjectDialog = () => {
  const intl = useIntl();
  const history = useHistory();
  const [nozzlePrinterId, setNozzlePrinterId] = useState('');
  const { hideDialog, getDialogData } = useDialog();
  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);
  const specialCaseSelectProjectFromList =
    dialogData?.fromWorkflowCreatedOutsideProjectList;
  const { projectsQuery } = useProjectQueries();
  const { refetch: projectsQueryRefetch } = projectsQuery;
  const { getPrinterMaterials, getPrinterNozzles, getExtruderDefinition } =
    usePrinter();
  const { printersQuery, allNozzlesQuery, materialsQuery } =
    usePrinterQueries();
  const { createProjectMutation } = useProjectMutations();
  const { createWorkflowMutation } = useWorkflowMutations();
  const materials = getPrinterMaterials(nozzlePrinterId);
  const nozzles = getPrinterNozzles(nozzlePrinterId);

  const formikRef = useRef();

  const isLoading =
    printersQuery?.isLoading ||
    allNozzlesQuery.isFetching ||
    materialsQuery.isFetching;
  const refetchAllNozzlesQuery = allNozzlesQuery.refetch;
  const refetchMaterialsQuery = materialsQuery.refetch;

  const { goTo, goPrev, goNext, isStepActive, currentStepName, isLastStep } =
    useSteps({
      steps: CREATE_PROJECT_STEPS,
      initialStepNumber: specialCaseSelectProjectFromList ? 0 : 1,
    });

  const lastStepIsActive = useMemo(() => isLastStep(), [isLastStep]);

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  useEffect(() => {
    if (specialCaseSelectProjectFromList) {
      projectsQueryRefetch();
    }
  }, [specialCaseSelectProjectFromList, projectsQueryRefetch]);

  const showBackButton = useMemo(
    () =>
      ![CREATE_PROJECT_SELECT_PROJECT, CREATE_PROJECT_PROJECT].includes(
        currentStepName,
      ) ||
      (specialCaseSelectProjectFromList &&
        currentStepName === CREATE_PROJECT_PROJECT),
    [currentStepName, specialCaseSelectProjectFromList],
  );

  const formInitialValues = useMemo(
    () => ({
      projectName: 'Untitled project',
      workflowName: 'Untitled workflow',
      printer: null,
      nozzle: null,
      material: null,
      project: null,
    }),
    [],
  );

  const isNozzleSelectionEnabled = useCallback(
    (printerId) => {
      const printer = printersQuery.data.find(
        (printer) => printer.id === printerId,
      );
      const extruderDefinition = getExtruderDefinition(printer);
      return printerConstants.METAL_WIRE !== extruderDefinition?.extrusionType;
    },
    [printersQuery, getExtruderDefinition],
  );

  const validationSchema = useMemo(() => {
    const validationFields = {};

    if (isStepActive(CREATE_PROJECT_SELECT_PROJECT)) {
      validationFields.project = Yup.object()
        .nullable()
        .required(
          intl.formatMessage({
            id: 'createproject.project.validation.required',
            defaultMessage: 'Please select an option',
          }),
        );
    }

    if (isStepActive(CREATE_PROJECT_PROJECT)) {
      validationFields.projectName = Yup.string()
        .required(
          intl.formatMessage({
            id: 'createproject.project.projectname.validation.required',
            defaultMessage: 'Name cannot be empty',
          }),
        )
        .min(
          5,
          intl.formatMessage({
            id: 'createproject.project.projectname.validation.min',
            defaultMessage: 'Name must be at least 5 characters long',
          }),
        )
        .max(
          256,
          intl.formatMessage({
            id: 'createproject.project.projectname.validation.max',
            defaultMessage: 'Name must be no longer than 256 characters',
          }),
        )
        .test(
          'is-unique',
          intl.formatMessage({
            id: 'createproject.project.projectname.validation.unique',
            defaultMessage: 'Name must be unique',
          }),
          (value) => {
            return !projectsQuery.data?.some(
              (project) => project.name === value,
            );
          },
        );
    }

    if (
      [CREATE_PROJECT_WORKFLOW, CREATE_PROJECT_NOZZLE].includes(currentStepName)
    ) {
      validationFields.workflowName = Yup.string()
        .required(
          intl.formatMessage({
            id: 'createproject.project.workflowname.validation.required',
            defaultMessage: 'Name cannot be empty',
          }),
        )
        .min(
          5,
          intl.formatMessage({
            id: 'createproject.project.workflowname.validation.min',
            defaultMessage: 'Name must be at least 5 characters long',
          }),
        )
        .max(
          256,
          intl.formatMessage({
            id: 'createproject.project.workflowname.validation.max',
            defaultMessage: 'Name must be no longer than 256 characters',
          }),
        );

      validationFields.printer = Yup.object()
        .nullable()
        .required(
          intl.formatMessage({
            id: 'createproject.project.printer.validation.required',
            defaultMessage: 'Please select an option',
          }),
        );
    }

    if (lastStepIsActive) {
      validationFields.nozzle = Yup.mixed().when('printer', {
        is: (printer) =>
          printer != null && isNozzleSelectionEnabled(printer.value),
        then: Yup.object()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'createproject.project.nozzle.validation.required',
              defaultMessage: 'Please select an option',
            }),
          ),
        otherwise: Yup.mixed().notRequired(),
      });

      validationFields.material = Yup.object()
        .nullable()
        .required(
          intl.formatMessage({
            id: 'createproject.project.material.validation.required',
            defaultMessage: 'Please select an option',
          }),
        );
    }

    return Yup.object().shape(validationFields);
  }, [
    isStepActive,
    intl,
    projectsQuery?.data,
    lastStepIsActive,
    currentStepName,
    isNozzleSelectionEnabled,
  ]);

  const handleGoBack = useCallback(() => {
    if (lastStepIsActive) {
      goTo(CREATE_PROJECT_PROJECT);

      return;
    }

    if (
      specialCaseSelectProjectFromList &&
      [CREATE_PROJECT_PROJECT, CREATE_PROJECT_WORKFLOW].includes(
        currentStepName,
      )
    ) {
      goTo(CREATE_PROJECT_SELECT_PROJECT);
      return;
    }

    goPrev();
  }, [
    currentStepName,
    specialCaseSelectProjectFromList,
    lastStepIsActive,
    goPrev,
    goTo,
  ]);

  const handleGoForward = useCallback(() => {
    if (nozzlePrinterId) {
      goTo(CREATE_PROJECT_NOZZLE);

      return;
    }

    goNext();
  }, [nozzlePrinterId, goNext, goTo]);

  const getPrinterDropDownMenuItems = useCallback(
    (values) => {
      return printersQuery.data
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((printer) => ({
          label: printer.name,
          selected: values?.printer?.value === printer.id,
          formFieldValue: {
            label: printer.name,
            value: printer.id,
          },
          onClick: () => {
            setNozzlePrinterId(printer.id);

            if (!allNozzlesQuery?.isFetched) {
              refetchAllNozzlesQuery();
            }

            if (!materialsQuery?.isFetched) {
              refetchMaterialsQuery();
            }

            if (!values?.printer) {
              goNext();
            }
          },
        }));
    },
    [
      printersQuery,
      setNozzlePrinterId,
      allNozzlesQuery?.isFetched,
      materialsQuery?.isFetched,
      refetchAllNozzlesQuery,
      refetchMaterialsQuery,
      goNext,
    ],
  );

  const getNozzleDropDownMenuItems = useCallback(
    (values) => {
      return nozzles?.map((nozzle) => ({
        label: nozzle.name,
        selected: values?.nozzle?.value === nozzle.id,
        formFieldValue: {
          label: nozzle.name,
          value: nozzle.id,
        },
      }));
    },
    [nozzles],
  );

  const getProjectsDropDownMenuItems = useCallback(
    (values) => {
      return projectsQuery.data
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((project) => ({
          label: project.name,
          selected: values?.project?.value === project.id,
          formFieldValue: {
            label: project.name,
            value: project.id,
          },
        }));
    },
    [projectsQuery],
  );

  const getMaterialDropDownMenuItems = useCallback(
    (values) => {
      return materials?.map((material) => ({
        label: material.materialName,
        selected: values?.material?.value === material.id,
        formFieldValue: {
          label: material.materialName,
          value: material.id,
        },
      }));
    },
    [materials],
  );

  const onFormSubmit = useCallback(
    (values, { setSubmitting, setTouched, setFieldValue }) => {
      if (lastStepIsActive) {
        return (async () => {
          const {
            projectName,
            project,
            workflowName,
            printer,
            nozzle,
            material,
          } = values;

          let selectedProject;
          if (project) {
            selectedProject = {
              name: project.name,
              id: project.value,
            };
          } else {
            selectedProject = await createProjectMutation.mutateAsync(
              projectName,
            );
            setFieldValue('project', {
              label: selectedProject.name,
              value: selectedProject.id,
            });
          }
          const workflow = await createWorkflowMutation.mutateAsync({
            name: workflowName,
            printerId: printer.value,
            operators: [],
            workspaceId: selectedProject.id,
            nozzleId: isNozzleSelectionEnabled(printer.value)
              ? nozzle.value
              : '',
            materialId: material.value,
          });

          const navigationPath = generatePath(ROUTES.WORKFLOW, {
            workspaceId: selectedProject?.id,
            directory: Directories.workflows,
            itemId: workflow?.id,
          });

          history.push(navigationPath);
        })();
      }

      setTouched({});

      if (isStepActive(CREATE_PROJECT_PROJECT)) {
        return (async () => {
          if (specialCaseSelectProjectFromList) {
            const { projectName } = values;

            const workspace = await createProjectMutation.mutateAsync(
              projectName,
            );
            goTo(CREATE_PROJECT_SELECT_PROJECT);
            setFieldValue('project', {
              label: workspace.name,
              value: workspace.id,
            });
          } else {
            await printersQuery.refetch();
            handleGoForward();
          }
        })();
      }

      if (isStepActive(CREATE_PROJECT_SELECT_PROJECT)) {
        return printersQuery.refetch().then(() => {
          goTo(CREATE_PROJECT_WORKFLOW);
        });
      }

      setSubmitting(false);
      handleGoForward();
    },
    [
      goTo,
      specialCaseSelectProjectFromList,
      isStepActive,
      lastStepIsActive,
      printersQuery,
      createProjectMutation,
      createWorkflowMutation,
      history,
      handleGoForward,
      isNozzleSelectionEnabled,
    ],
  );

  useEffect(() => {
    const skip = !nozzlePrinterId || isLoading;

    if (skip) return;

    const defaultNozzle = nozzles?.[0];
    const material = materials?.[0];

    if (defaultNozzle) {
      formikRef.current.setFieldValue('nozzle', {
        label: defaultNozzle.name,
        value: defaultNozzle.id,
      });
    } else {
      formikRef.current.setFieldValue('nozzle', null);
    }

    if (material) {
      formikRef.current.setFieldValue('material', {
        label: material.materialName,
        value: material.id,
      });
    } else {
      formikRef.current.setFieldValue('material', null);
    }
  }, [nozzlePrinterId, nozzles, materials, isLoading]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  const handleCreateNewProject = useCallback(() => {
    goTo(CREATE_PROJECT_PROJECT);
  }, [goTo]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <DialogPortal
          renderAsForm
          dataTestId="create-project"
          dialogId={MODAL_ID}
          loading={isSubmitting || isLoading}
          onBackIconButtonClick={showBackButton ? handleGoBack : undefined}
          onClose={handleClose}
          onSubmit={handleSubmit}
          primaryButtonLabel={
            lastStepIsActive ||
            (specialCaseSelectProjectFromList &&
              isStepActive(CREATE_PROJECT_PROJECT))
              ? intl.formatMessage({
                  id: 'general.create',
                  defaultMessage: 'Create',
                })
              : intl.formatMessage({
                  id: 'general.next',
                  defaultMessage: 'Next',
                })
          }
          secondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
        >
          {[CREATE_PROJECT_SELECT_PROJECT].includes(currentStepName) && (
            <>
              <PageHeader
                variant={PAGE_HEADER_VARIANT_MEDIUM}
                title={intl.formatMessage({
                  id: 'createproject.workflow.select.project.header.title',
                  defaultMessage: 'Select Project',
                })}
              />

              <Fields>
                <FormikField
                  component={DropDownField}
                  dataTestId="create-workflow__project-dropdown-field"
                  disabled={isSubmitting || isLoading}
                  name="project"
                  label={intl.formatMessage({
                    id: 'createproject.workflow.projects.label',
                    defaultMessage: 'Projects',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'createproject.workflow.projects.placeholder',
                    defaultMessage: 'Select Project',
                  })}
                  dropDownMenuItems={getProjectsDropDownMenuItems(values)}
                  emptyDropDownStateIconName="precision_manufacturing_0"
                  emptyDropDownStateTitle={intl.formatMessage({
                    id: 'createproject.workflow.projects.empty_state.title',
                    defaultMessage: 'No project yet',
                  })}
                  emptyDropDownStateDescription={intl.formatMessage({
                    id: 'createproject.workflow.projects.empty_state.description',
                    defaultMessage: 'To continue please add a printer',
                  })}
                  emptyDropDownStateLinkButtonLabel={intl.formatMessage({
                    id: 'createproject.workflow.projects.empty_state.link_button.label',
                    defaultMessage: 'Add Project',
                  })}
                  emptyDropDownStateLinkButtonPath={ROUTES.PROJECTS}
                />

                <SettingActionRow
                  dataTestId={`create_workflow__add-new-project-button`}
                  button={{
                    iconName: 'add_0',
                    onClick: handleCreateNewProject,
                    children: (
                      <FormattedMessage
                        id="createproject.workflow.projects.add.new.button.label"
                        defaultMessage="Create a new Project"
                      />
                    ),
                  }}
                ></SettingActionRow>
              </Fields>
            </>
          )}

          {isStepActive(CREATE_PROJECT_PROJECT) && (
            <>
              <PageHeader
                variant={PAGE_HEADER_VARIANT_MEDIUM}
                title={intl.formatMessage({
                  id: 'createproject.project.header.title',
                  defaultMessage: 'Create Project',
                })}
                subtitle={intl.formatMessage({
                  id: 'createproject.project.header.subtitle',
                  defaultMessage:
                    'Projects provide a way to manage and organise manufacturing workflows and files in one place.',
                })}
              />

              <Fields>
                <FormikField
                  component={Field}
                  dataTestId="create-project__project-name-field"
                  disabled={isSubmitting}
                  name="projectName"
                  label={intl.formatMessage({
                    id: 'createproject.project.projectname.label',
                    defaultMessage: 'Name',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'createproject.project.projectname.placeholder',
                    defaultMessage: 'Untitled project',
                  })}
                  autoFocus
                />
              </Fields>
            </>
          )}

          {[CREATE_PROJECT_WORKFLOW, CREATE_PROJECT_NOZZLE].includes(
            currentStepName,
          ) && (
            <>
              <PageHeader
                variant={PAGE_HEADER_VARIANT_MEDIUM}
                title={intl.formatMessage({
                  id: 'createproject.workflow.header.title',
                  defaultMessage: 'Create Workflow',
                })}
                subtitle={intl.formatMessage({
                  id: 'createproject.workflow.header.subtitle',
                  defaultMessage:
                    'A workflow refers to a series of instructions or manufacturing steps',
                })}
              />

              <Fields>
                <FormikField
                  autoFocus={isStepActive(CREATE_PROJECT_WORKFLOW)}
                  component={Field}
                  dataTestId="create-project__workflow-name-field"
                  disabled={isSubmitting}
                  name="workflowName"
                  label={intl.formatMessage({
                    id: 'createproject.project.workflowname.label',
                    defaultMessage: 'Name',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'createproject.project.workflowname.placeholder',
                    defaultMessage: 'Untitled Workflow',
                  })}
                />

                <FormikField
                  component={DropDownField}
                  dataTestId="create-project__printer-dropdown-field"
                  disabled={isSubmitting || isLoading}
                  name="printer"
                  label={intl.formatMessage({
                    id: 'createproject.project.printer.label',
                    defaultMessage: 'Printer',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'createproject.project.printer.placeholder',
                    defaultMessage: 'Select Printer',
                  })}
                  dropDownMenuItems={getPrinterDropDownMenuItems(values)}
                  emptyDropDownStateIconName="precision_manufacturing_0"
                  emptyDropDownStateTitle={intl.formatMessage({
                    id: 'createproject.project.printer.empty_state.title',
                    defaultMessage: 'No printer yet',
                  })}
                  emptyDropDownStateDescription={intl.formatMessage({
                    id: 'createproject.project.printer.empty_state.description',
                    defaultMessage: 'To continue please add a printer',
                  })}
                  emptyDropDownStateLinkButtonLabel={intl.formatMessage({
                    id: 'createproject.project.printer.empty_state.link_button.label',
                    defaultMessage: 'Add Printer',
                  })}
                  emptyDropDownStateLinkButtonPath={ROUTES.PRINTERS}
                />
              </Fields>
            </>
          )}

          {isStepActive(CREATE_PROJECT_NOZZLE) && (
            <Fields>
              {isNozzleSelectionEnabled(values?.printer.value) && (
                <FormikField
                  component={DropDownField}
                  dataTestId="create-project__nozzle-dropdown-field"
                  disabled={isSubmitting || isLoading}
                  name="nozzle"
                  label={intl.formatMessage({
                    id: 'createproject.project.nozzle.label',
                    defaultMessage: 'Nozzle',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'createproject.project.nozzle.placeholder',
                    defaultMessage: 'Select Nozzle',
                  })}
                  dropDownMenuItems={getNozzleDropDownMenuItems(values)}
                  emptyDropDownStateIconName="precision_manufacturing_0"
                  emptyDropDownStateTitle={intl.formatMessage({
                    id: 'createproject.project.nozzle.empty_state.title',
                    defaultMessage: 'No nozzle yet',
                  })}
                  emptyDropDownStateDescription={intl.formatMessage({
                    id: 'createproject.project.nozzle.empty_state.description',
                    defaultMessage: 'To continue please add a nozzle',
                  })}
                  emptyDropDownStateLinkButtonLabel={intl.formatMessage({
                    id: 'createproject.project.nozzle.empty_state.link_button.label',
                    defaultMessage: 'Add Nozzle',
                  })}
                  emptyDropDownStateLinkButtonPath={generatePath(
                    ROUTES.PRINTER,
                    {
                      printerId: nozzlePrinterId,
                      directory: 'prints',
                    },
                  )}
                />
              )}

              <FormikField
                component={DropDownField}
                dataTestId="create-project__material-dropdown-field"
                disabled={isSubmitting || isLoading}
                name="material"
                label={intl.formatMessage({
                  id: 'createproject.project.material.label',
                  defaultMessage: 'Material',
                })}
                placeholder={intl.formatMessage({
                  id: 'createproject.project.material.placeholder',
                  defaultMessage: 'Select Material',
                })}
                dropDownMenuItems={getMaterialDropDownMenuItems(values)}
                emptyDropDownStateIconName="precision_manufacturing_0"
                emptyDropDownStateTitle={intl.formatMessage({
                  id: 'createproject.project.material.empty_state.title',
                  defaultMessage: 'No material yet',
                })}
                emptyDropDownStateDescription={intl.formatMessage({
                  id: 'createproject.project.material.empty_state.description',
                  defaultMessage: 'To continue please add a material',
                })}
                emptyDropDownStateLinkButtonLabel={intl.formatMessage({
                  id: 'createproject.project.material.empty_state.link_button.label',
                  defaultMessage: 'Add Material',
                })}
                emptyDropDownStateLinkButtonPath={generatePath(
                  ROUTES.MATERIALS,
                )}
              />
            </Fields>
          )}
        </DialogPortal>
      )}
    </Formik>
  );
};

CreateProjectDialog.propTypes = {};

export default CreateProjectDialog;

import { useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import { postProcessorGCodeDefinitions } from '@constants/printers/postProcessorSettingsDefinitions';
import { Field as FormikField, useFormikContext } from 'formik';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';
import PropTypes from 'prop-types';

const PostProcessorGCode = ({ isSubmitting }) => {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext();

  const getFormattedOptions = useMemo(
    () => (definitions) =>
      Object.values(definitions).map((option) => ({
        label: option.label,
        formFieldValue: {
          label: option.label,
          value: option.value,
        },
      })),
    [],
  );

  const options = getFormattedOptions(postProcessorGCodeDefinitions);

  return (
    <>
      <FormikField
        component={PostProcessorSetting}
        label={intl.formatMessage({
          id: 'printers.postprocessor.gcode.label',
        })}
        dataTestId={`printer-custom-post-processor__setting-gCodeAction`}
        name={'gCodeAction'}
        dropDownMenuItems={options}
        disabled={isSubmitting}
      />
      <FormikField
        component={PostProcessorSetting}
        dataTestId={`printer-custom-post-processor__setting-useJointPositions`}
        label={intl.formatMessage({
          id: 'printers.postprocessor.gcode.joints.label',
          defaultMessage: 'Use Joint Positions',
        })}
        renderAsCheckbox
        noPaddingBottom
        name={'useJointPositions'}
        checked={Boolean(values['useJointPositions'])}
        onChange={() => {
          setFieldValue('useJointPositions', !values['useJointPositions']);
        }}
        disabled={isSubmitting}
      />
    </>
  );
};

PostProcessorGCode.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorGCode;

import React, { useCallback, useMemo } from 'react';
import { CHIP_VARIANT_PRIMARY } from '@app/components/1-atoms/Chip';
import SettingAction from '@app/components/2-molecules/SettingAction';
import { useFormContext, Controller } from 'react-hook-form';
import useOperator from '@app/hooks/operators/useOperator';
import PropTypes from 'prop-types';
import SettingActionWithIconButton from '../2-molecules/SettingActionWithIconButton';
import useFile from '@app/hooks/files/useFile';
import useWorkflow from '@app/hooks/workflows/useWorkflow';
import { useIntl } from 'react-intl';

const SHAPE_ID_DEFAULT_VALUE = '-1';
const CanvasSelectionChip = ({
  fieldInput,
  referencedModelFieldName,
  referencedShapeIdFieldName,
  formFieldName,
  renderDropDownField,
  disabled,
}) => {
  const { watch, setValue } = useFormContext();
  const intl = useIntl();
  const watchReferencedModelInput = watch(referencedModelFieldName);
  const watchReferencedShapeIdFieldName = watch(referencedShapeIdFieldName);
  const { getIsCanvasSelectionActiveForField, selectCanvasSelectionField } =
    useOperator();
  const canvasSelectionActive = getIsCanvasSelectionActiveForField(fieldInput);
  const { getSelectedWorkflow, getWorkflowOperators } = useWorkflow();
  const { getSelectedDesignIds } = useFile();

  const workflow = getSelectedWorkflow();
  const workflowOperators = getWorkflowOperators(workflow);
  const selectedDesigns = getSelectedDesignIds(workflowOperators);
  const disableSelectionMode = useMemo(
    () => selectedDesigns?.canvasSelectionDesigns?.length === 0,
    [selectedDesigns],
  );
  const canvasSelectionFieldFilled =
    watchReferencedModelInput &&
    watchReferencedShapeIdFieldName &&
    watchReferencedShapeIdFieldName != SHAPE_ID_DEFAULT_VALUE;

  const renderActiveChip = useCallback(() => {
    return (
      <SettingAction
        label={fieldInput?.name}
        chip={{
          label: intl.formatMessage({
            id: 'canvasselection.field.select',
            defaultMessage: 'Select',
          }),
          leadingIconName: 'arrow_selector_tool_0',
          variant: CHIP_VARIANT_PRIMARY,
          disabled,
        }}
      />
    );
  }, [fieldInput?.name, disabled, intl]);

  const resetSelection = useCallback(() => {
    setValue(formFieldName, '');
    setValue(referencedModelFieldName, '');
    setValue(referencedShapeIdFieldName, '');
  }, [
    setValue,
    referencedModelFieldName,
    referencedShapeIdFieldName,
    formFieldName,
  ]);

  const showSelectedFace = useCallback(() => {
    selectCanvasSelectionField(fieldInput.id);
  }, [selectCanvasSelectionField, fieldInput?.id]);

  const renderSelectionFilledChip = useCallback(() => {
    return (
      <SettingActionWithIconButton
        label={fieldInput?.name}
        chip={{
          label: intl.formatMessage({
            id: 'canvasselection.field.1_selection',
            defaultMessage: '1 Selection',
          }),
          leadingIconName: 'arrow_selector_tool_0',
          variant: CHIP_VARIANT_PRIMARY,
          onClick: showSelectedFace,
          disabled,
        }}
        iconButton={{
          iconName: 'close_0',
          onClick: resetSelection,
          disabled,
        }}
      />
    );
  }, [fieldInput?.name, showSelectedFace, resetSelection, disabled, intl]);

  return (
    <Controller
      name={formFieldName}
      render={() => {
        if (canvasSelectionActive) {
          return renderActiveChip();
        }
        if (canvasSelectionFieldFilled) {
          return renderSelectionFilledChip();
        }
        const dropdownItems = disableSelectionMode
          ? []
          : [
              {
                label: intl.formatMessage({
                  id: 'canvasselection.field.select',
                  defaultMessage: 'Select',
                }),
                leadingIconName: 'arrow_selector_tool_0',
                onClick: () => {
                  selectCanvasSelectionField(fieldInput?.id);
                },
              },
            ];
        return renderDropDownField(fieldInput, dropdownItems);
      }}
    />
  );
};

CanvasSelectionChip.propTypes = {
  fieldInput: PropTypes.object,
  referencedModelFieldName: PropTypes.string,
  referencedShapeIdFieldName: PropTypes.string,
  formFieldName: PropTypes.string,
  renderDropDownField: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CanvasSelectionChip;

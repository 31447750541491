import SettingBar from '@components/2-molecules/SettingBar';
import React, { useCallback, useMemo } from 'react';
import {
  hidePostProcessorConfig,
  updatePostProcessorConfig,
} from '@actions/printerActions';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  ABB,
  CEAD_PROGRAMS,
  GCODE,
  KUKA,
  postProcessorActionTypes,
  postProcessorBooleanSetting,
  postProcessorCalculationDefinitions,
  postProcessorConfigType,
  postProcessorControlDefinitions,
  postProcessorGCodeDefinitions,
  postProcessorInstructionType,
  postProcessorSettingsDefinitions,
  SUPPORTS_ADDITIONAL_CUSTOMISATIONS,
} from '@constants/printers/postProcessorSettingsDefinitions';
import { useParams } from 'react-router-dom';
import {
  getPostProcessorConfigs,
  getPrinter,
} from '@selectors/printerSelectors';
import * as Yup from 'yup';
import PostProcessorInput from '@components/Printers/PostProcessorConfig/PostProcessorInput';
import PostProcessorExtrusion from '@components/Printers/PostProcessorConfig/PostProcessorExtrusion/PostProcessorExtrusion';
import PostProcessorGCode from '@components/Printers/PostProcessorConfig/PostProcessorGCode/PostProcessorGCode';
import { isFetchInProgess } from '@selectors/fetchSelector';
import useDialog from '@hooks/useDialog';
import PostProcessorToolType from '@components/Printers/PostProcessorConfig/PostProcessorToolType/PostProcessorToolType';
import PostProcessorKUKA from '@components/Printers/PostProcessorConfig/PostProcessorKUKA/PostProcessorKUKA';
import PostProcessorABB from '@components/Printers/PostProcessorConfig/PostProcessorABB/PostProcessorABB';
import PostProcessorCEAD from '@components/Printers/PostProcessorConfig/PostProcessorCEAD/PostProcessorCEAD';
import { Formik, Field as FormikField } from 'formik';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';
import usePrinterQueries from '@app/hooks/printers/usePrinterQueries';
import PostProcessorRetraction from './PostProcessorRetraction';
import HorizontalDivider from '../../1-atoms/HorizontalDivider/HorizontalDivider';

// Define Yup validation schema
const validationSchema = Yup.object().shape({
  startInstruction: Yup.string().max(5000),
  endInstruction: Yup.string().max(5000),
  lineTypeInstruction: Yup.string().max(5000),
  layerInstruction: Yup.string().max(5000),
  toggleOnInstruction: Yup.string().max(5000),
  toggleOffInstruction: Yup.string().max(5000),
  extrusionRateChangeInstruction: Yup.string().max(5000),
  depositionMultiplier: Yup.number().typeError(
    'Deposition Multiplier must be a number.',
  ),
  MaximumDepositionValue: Yup.number().typeError(
    'Maximum deposition value must be a number.',
  ),
  toolChangeInstruction: Yup.string().max(5000),
  abbAnalogSignalName: Yup.string().max(50),
  toolExternalAxisId: Yup.number()
    .integer('Tool External Axis ID must be an integer.') // Validate it's an integer
    .typeError('Tool External Axis ID must be a number.'), // Message if the type is not a number
});

const PostProcessorConfig = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { showDialog } = useDialog();
  const dbPostProcessorConfigs = useSelector(getPostProcessorConfigs());
  const { printersQuery } = usePrinterQueries();
  const params = useParams();
  const { printerId } = params;
  const printer = useSelector(getPrinter(printerId));
  const isPostProcessorUpdating = useSelector(
    isFetchInProgess('updatePostProcessorConfig'),
  );

  const onSuccess = useCallback(() => {
    printersQuery.refetch();
  }, [printersQuery]);

  const handleClose = useCallback(
    (resetForm) => () => {
      resetForm();
      dispatch(hidePostProcessorConfig());
    },
    [dispatch],
  );

  const mapDbConfigToFormInitialValues = (dbPostProcessorConfigs) => {
    const initialValues = {
      startAction: postProcessorActionTypes.APPEND,
      endAction: postProcessorActionTypes.APPEND,
      lineTypeAction: postProcessorActionTypes.APPEND,
      layerAction: postProcessorActionTypes.APPEND,
      extrusionAction: postProcessorSettingsDefinitions.DEFAULT,
      controlAction: postProcessorControlDefinitions.CONSTANT,
      calculationAction: postProcessorCalculationDefinitions.DEFAULT,
      gCodeAction: postProcessorGCodeDefinitions.NONE,
      depositionMultiplier: 1,
      maximumDepositionValue: '',
      extrusionRateChangeInstruction: '',
      toggleOnInstruction: '',
      toggleOffInstruction: '',
      startInstruction: '',
      endInstruction: '',
      lineTypeInstruction: '',
      layerInstruction: '',
      isToolChangingEnabled: '',
      toolChangeInstruction: '',
      isRetractionEnabled: false,
      useRetractionCustom: false,
      customRetractionInstructions: '',
      customProtractionInstructions: '',
      abbIsAnalogSignalEnabled: '',
      abbAnalogSignalName: '',
      isToolExternalAxis: '',
      toolExternalAxisId: '',
      ceadEnablePurge: true,
      ceadEnableTemperatures: true,
      useJointPositions: false,
    };

    dbPostProcessorConfigs.forEach((config) => {
      switch (config.configType) {
        case postProcessorConfigType.Header.id:
          initialValues.startAction = postProcessorActionTypes[config.action];
          initialValues.startInstruction = config.instruction;
          break;
        case postProcessorConfigType.Footer.id:
          initialValues.endAction = postProcessorActionTypes[config.action];
          initialValues.endInstruction = config.instruction;
          break;
        case postProcessorConfigType.LineType.id:
          initialValues.lineTypeAction =
            postProcessorActionTypes[config.action];
          initialValues.lineTypeInstruction = config.instruction;
          break;
        case postProcessorConfigType.Layer.id:
          initialValues.layerAction = postProcessorActionTypes[config.action];
          initialValues.layerInstruction = config.instruction;
          break;
        case postProcessorConfigType.Extrusion.id:
          initialValues.extrusionAction =
            postProcessorSettingsDefinitions[config.instruction];
          break;
        case postProcessorConfigType.ExtrusionControl.id:
          initialValues.controlAction =
            postProcessorControlDefinitions[config.instruction];
          break;
        case postProcessorConfigType.ExtrusionCalculation.id:
          initialValues.calculationAction =
            postProcessorCalculationDefinitions[config.instruction];
          break;
        case postProcessorConfigType.DepositionMultiplier.id:
          initialValues.depositionMultiplier = config.instruction;
          break;
        case postProcessorConfigType.MaximumDepositionValue.id:
          initialValues.maximumDepositionValue = config.instruction;
          break;
        case postProcessorConfigType.ToggleOn.id:
          initialValues.toggleOnInstruction = config.instruction;
          break;
        case postProcessorConfigType.ToggleOff.id:
          initialValues.toggleOffInstruction = config.instruction;
          break;
        case postProcessorConfigType.ExtrusionRateChange.id:
          initialValues.extrusionRateChangeInstruction = config.instruction;
          break;
        case postProcessorConfigType.GCodeRotationFormat.id:
          initialValues.gCodeAction =
            postProcessorGCodeDefinitions[config.instruction];
          break;
        case postProcessorConfigType.IsToolChangingEnabled.id:
          initialValues.isToolChangingEnabled =
            config.instruction === postProcessorBooleanSetting.TRUE.value;
          break;
        case postProcessorConfigType.ToolChangeInstructions.id:
          initialValues.toolChangeInstruction = config.instruction;
          break;
        case postProcessorConfigType.IsRetractionEnabled.id:
          initialValues.isRetractionEnabled =
            config.instruction === postProcessorBooleanSetting.TRUE.value;
          break;
        case postProcessorConfigType.UseRetractionCustom.id:
          initialValues.useRetractionCustom =
            config.instruction === postProcessorBooleanSetting.TRUE.value;
          break;
        case postProcessorConfigType.CustomRetractionInstructions.id:
          initialValues.customRetractionInstructions = config.instruction;
          break;
        case postProcessorConfigType.CustomProtractionInstructions.id:
          initialValues.customProtractionInstructions = config.instruction;
          break;
        case postProcessorConfigType.ABBIsAnalogSignalEnabled.id:
          initialValues.abbIsAnalogSignalEnabled =
            config.instruction === postProcessorBooleanSetting.TRUE.value;
          break;
        case postProcessorConfigType.ABBAnalogSignalName.id:
          initialValues.abbAnalogSignalName = config.instruction;
          break;
        case postProcessorConfigType.IsToolExternalAxis.id:
          initialValues.isToolExternalAxis =
            config.instruction === postProcessorBooleanSetting.TRUE.value;
          break;
        case postProcessorConfigType.ToolExternalAxisId.id:
          initialValues.toolExternalAxisId = config.instruction;
          break;
        case postProcessorConfigType.CEADEnablePurge.id:
          initialValues.ceadEnablePurge =
            config.instruction === postProcessorBooleanSetting.TRUE.value;
          break;
        case postProcessorConfigType.CEADEnableTemperatures.id:
          initialValues.ceadEnableTemperatures =
            config.instruction === postProcessorBooleanSetting.TRUE.value;
          break;
        case postProcessorConfigType.UseJointPositions.id:
          initialValues.useJointPositions =
            config.instruction === postProcessorBooleanSetting.TRUE.value;
          break;
        default:
          break;
      }
    });

    return initialValues;
  };

  const onFormSubmit = useCallback(
    (
      {
        startInstruction,
        endInstruction,
        lineTypeInstruction,
        layerInstruction,
        startAction,
        endAction,
        lineTypeAction,
        layerAction,
        extrusionAction,
        controlAction,
        calculationAction,
        depositionMultiplier,
        maximumDepositionValue,
        gCodeAction,
        extrusionRateChangeInstruction,
        toggleOnInstruction,
        toggleOffInstruction,
        isToolChangingEnabled,
        toolChangeInstruction,
        isRetractionEnabled,
        useRetractionCustom,
        customRetractionInstructions,
        customProtractionInstructions,
        isToolExternalAxis,
        toolExternalAxisId,
        abbIsAnalogSignalEnabled,
        abbAnalogSignalName,
        ceadEnablePurge,
        ceadEnableTemperatures,
        useJointPositions,
      },
      { setSubmitting, setErrors },
    ) => {
      setErrors({});

      dispatch(
        updatePostProcessorConfig(
          showDialog,
          printerId,
          [
            {
              configType: postProcessorConfigType.Header.id,
              instructionType: postProcessorInstructionType.InstructionSet.id,
              action: startAction.value,
              instruction: startInstruction,
            },
            {
              configType: postProcessorConfigType.Footer.id,
              instructionType: postProcessorInstructionType.InstructionSet.id,
              action: endAction.value,
              instruction: endInstruction,
            },
            {
              configType: postProcessorConfigType.LineType.id,
              instructionType: postProcessorInstructionType.InstructionSet.id,
              action: lineTypeAction.value,
              instruction: lineTypeInstruction,
            },
            {
              configType: postProcessorConfigType.Layer.id,
              instructionType: postProcessorInstructionType.InstructionSet.id,
              action: layerAction.value,
              instruction: layerInstruction,
            },
            {
              configType: postProcessorConfigType.DepositionMultiplier.id,
              instructionType: postProcessorInstructionType.Setting.id,
              action: null,
              instruction: depositionMultiplier.toString(),
            },
            {
              configType: postProcessorConfigType.MaximumDepositionValue.id,
              instructionType: postProcessorInstructionType.Setting.id,
              action: null,
              instruction: maximumDepositionValue.toString(),
            },
            ...(SUPPORTS_ADDITIONAL_CUSTOMISATIONS.includes(
              printer?.programType,
            )
              ? [
                  {
                    configType: postProcessorConfigType.Extrusion.id,
                    instructionType: postProcessorInstructionType.Setting.id,
                    action: null,
                    instruction: extrusionAction.value,
                  },
                  {
                    configType:
                      postProcessorConfigType.IsToolChangingEnabled.id,
                    instructionType:
                      postProcessorInstructionType.SettingBoolean.id,
                    action: null,
                    instruction: isToolChangingEnabled.toString(),
                  },
                  {
                    configType:
                      postProcessorConfigType.ToolChangeInstructions.id,
                    instructionType:
                      postProcessorInstructionType.InstructionSet.id,
                    action: null,
                    instruction: toolChangeInstruction,
                  },
                ]
              : []),
            {
              configType: postProcessorConfigType.IsRetractionEnabled.id,
              instructionType: postProcessorInstructionType.SettingBoolean.id,
              action: null,
              instruction: isRetractionEnabled.toString(),
            },
            {
              configType: postProcessorConfigType.UseRetractionCustom.id,
              instructionType: postProcessorInstructionType.SettingBoolean.id,
              action: null,
              instruction: useRetractionCustom.toString(),
            },
            {
              configType:
                postProcessorConfigType.CustomRetractionInstructions.id,
              instructionType: postProcessorInstructionType.InstructionSet.id,
              action: null,
              instruction: customRetractionInstructions,
            },
            {
              configType:
                postProcessorConfigType.CustomProtractionInstructions.id,
              instructionType: postProcessorInstructionType.InstructionSet.id,
              action: null,
              instruction: customProtractionInstructions,
            },
            ...(extrusionAction &&
            extrusionAction.value ===
              postProcessorSettingsDefinitions.CUSTOM.value
              ? [
                  {
                    configType: postProcessorConfigType.ExtrusionControl.id,
                    instructionType: postProcessorInstructionType.Setting.id,
                    action: null,
                    instruction: controlAction.value,
                  },
                ]
              : []),
            ...(extrusionAction.value ===
              postProcessorSettingsDefinitions.CUSTOM.value &&
            controlAction.value !==
              postProcessorControlDefinitions.CONSTANT.value
              ? [
                  {
                    configType: postProcessorConfigType.ExtrusionCalculation.id,
                    instructionType: postProcessorInstructionType.Setting.id,
                    action: null,
                    instruction: calculationAction.value,
                  },
                ]
              : []),
            ...(extrusionAction.value ===
            postProcessorSettingsDefinitions.CUSTOM.value
              ? [
                  {
                    configType: postProcessorConfigType.ToggleOn.id,
                    instructionType:
                      postProcessorInstructionType.InstructionSet.id,
                    action: postProcessorActionTypes.APPEND.value,
                    instruction: toggleOnInstruction,
                  },
                ]
              : []),
            ...(extrusionAction.value ===
            postProcessorSettingsDefinitions.CUSTOM.value
              ? [
                  {
                    configType: postProcessorConfigType.ToggleOff.id,
                    instructionType:
                      postProcessorInstructionType.InstructionSet.id,
                    action: postProcessorActionTypes.APPEND.value,
                    instruction: toggleOffInstruction,
                  },
                ]
              : []),
            ...(extrusionAction.value ===
              postProcessorSettingsDefinitions.CUSTOM.value &&
            controlAction.value !==
              postProcessorControlDefinitions.CONSTANT.value
              ? [
                  {
                    configType: postProcessorConfigType.ExtrusionRateChange.id,
                    instructionType:
                      postProcessorInstructionType.InstructionSet.id,
                    action: postProcessorActionTypes.APPEND.value,
                    instruction: extrusionRateChangeInstruction,
                  },
                ]
              : []),
            ...(printer?.programType === ABB
              ? [
                  {
                    configType:
                      postProcessorConfigType.ABBIsAnalogSignalEnabled.id,
                    instructionType: postProcessorInstructionType.Setting.id,
                    action: null,
                    instruction: abbIsAnalogSignalEnabled.toString(),
                  },
                ]
              : []),
            ...(printer?.programType === ABB
              ? [
                  {
                    configType: postProcessorConfigType.ABBAnalogSignalName.id,
                    instructionType:
                      postProcessorInstructionType.InstructionSet.id,
                    action: null,
                    instruction: abbAnalogSignalName.toString(),
                  },
                ]
              : []),
            ...(CEAD_PROGRAMS.includes(printer?.programType)
              ? [
                  {
                    configType: postProcessorConfigType.CEADEnablePurge.id,
                    instructionType: postProcessorInstructionType.Setting.id,
                    action: null,
                    instruction: ceadEnablePurge.toString(),
                  },
                  {
                    configType:
                      postProcessorConfigType.CEADEnableTemperatures.id,
                    instructionType: postProcessorInstructionType.Setting.id,
                    action: null,
                    instruction: ceadEnableTemperatures.toString(),
                  },
                ]
              : []),
            ...(printer?.programType === KUKA
              ? [
                  {
                    configType: postProcessorConfigType.IsToolExternalAxis.id,
                    instructionType: postProcessorInstructionType.Setting.id,
                    action: null,
                    instruction: isToolExternalAxis.toString(),
                  },
                  {
                    configType: postProcessorConfigType.ToolExternalAxisId.id,
                    instructionType: postProcessorInstructionType.Setting.id,
                    action: null,
                    instruction: toolExternalAxisId.toString(),
                  },
                ]
              : []),
            ...(printer?.programType === GCODE
              ? [
                  {
                    configType: postProcessorConfigType.GCodeRotationFormat.id,
                    instructionType: postProcessorInstructionType.Setting.id,
                    action: null,
                    instruction: gCodeAction.value,
                  },
                  {
                    configType: postProcessorConfigType.UseJointPositions.id,
                    instructionType: postProcessorInstructionType.Setting.id,
                    action: null,
                    instruction: useJointPositions.toString(),
                  },
                ]
              : []),
          ],
          onSuccess,
        ),
      );
      setSubmitting(false);
    },
    [showDialog, dispatch, printerId, printer?.programType, onSuccess],
  );

  // Generate formInitialValues using the map function
  const formInitialValues = useMemo(() => {
    return mapDbConfigToFormInitialValues(dbPostProcessorConfigs);
  }, [dbPostProcessorConfigs]);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit, isSubmitting, dirty, resetForm }) => (
        <SettingBar
          headerTitle={intl.formatMessage({
            id: 'printers.postprocessor.title',
            defaultMessage: 'Custom Post Processor',
          })}
          footerPrimaryButtonLabel={intl.formatMessage({
            id: 'general.save',
            defaultMessage: 'Save',
          })}
          footerPrimaryButtonDisabled={!dirty}
          headerLeadingIconButtonIconName={'close_0'}
          footerSecondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
          onHeaderLeadingIconButtonClick={handleClose(resetForm)}
          onFooterSecondaryButtonClick={handleClose(resetForm)}
          onSubmit={handleSubmit}
          headerEndingIconButtonIconName={''}
          loading={isPostProcessorUpdating}
          withFooterDivider
          renderAsForm
        >
          <PostProcessorInput
            label="printers.postprocessor.start.label"
            descriptionLabel="printers.postprocessor.start.description.label"
            optionName="startAction"
            textName="startInstruction"
            isSubmitting={isSubmitting}
          />
          <PostProcessorInput
            label="printers.postprocessor.end.label"
            descriptionLabel="printers.postprocessor.end.description.label"
            optionName="endAction"
            textName="endInstruction"
            isSubmitting={isSubmitting}
          />
          <HorizontalDivider />
          <PostProcessorRetraction isSubmitting={isSubmitting} />
          <HorizontalDivider />
          <FormikField
            component={PostProcessorSetting}
            dataTestId={`printer-custom-post-processor__setting-depositionMultiplier`}
            name={'depositionMultiplier'}
            type={'number'}
            label={intl.formatMessage({
              id: 'printers.postprocessor.extrusion.multiplier.label',
            })}
            disabled={isSubmitting}
            renderAsInput
          />
          {(SUPPORTS_ADDITIONAL_CUSTOMISATIONS.includes(printer?.programType) ||
            CEAD_PROGRAMS.includes(printer?.programType)) && (
            <FormikField
              component={PostProcessorSetting}
              dataTestId={`printer-custom-post-processor__setting-maximumDepositionValue`}
              name={'maximumDepositionValue'}
              type={'number'}
              label={intl.formatMessage({
                id: 'printers.postprocessor.extrusion.maximum.label',
              })}
              disabled={isSubmitting}
              renderAsInput
            />
          )}
          {SUPPORTS_ADDITIONAL_CUSTOMISATIONS.includes(
            printer?.programType,
          ) && (
            <>
              <PostProcessorExtrusion isSubmitting={isSubmitting} />
              {printer?.programType === KUKA && (
                <PostProcessorKUKA isSubmitting={isSubmitting} />
              )}
              {printer?.programType === GCODE && (
                <PostProcessorGCode isSubmitting={isSubmitting} />
              )}
              {printer?.programType === ABB && (
                <PostProcessorABB isSubmitting={isSubmitting} />
              )}
              <PostProcessorToolType
                isSubmitting={isSubmitting}
                programType={printer?.programType}
              />
            </>
          )}
          {CEAD_PROGRAMS.includes(printer?.programType) && (
            <PostProcessorCEAD isSubmitting={isSubmitting} />
          )}
          <HorizontalDivider />
          <PostProcessorInput
            label="printers.postprocessor.lineType.label"
            descriptionLabel="printers.postprocessor.lineType.description.label"
            optionName="lineTypeAction"
            textName="lineTypeInstruction"
            isSubmitting={isSubmitting}
          />
          <PostProcessorInput
            label="printers.postprocessor.layer.label"
            descriptionLabel="printers.postprocessor.layer.description.label"
            optionName="layerAction"
            textName="layerInstruction"
            isSubmitting={isSubmitting}
          />
        </SettingBar>
      )}
    </Formik>
  );
};

export default PostProcessorConfig;

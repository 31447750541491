import React, { Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useOperator from '@hooks/operators/useOperator';
import {
  CHECKBOX_FIELD_TYPES,
  DROP_DOWN_DATA_FIELD_TYPES,
  DROP_DOWN_FIELD_TYPES,
  DROP_DOWN_FILE_FIELD_TYPES,
  DROP_DOWN_INPUT_FIELD_TYPES,
  INPUT_FIELD_TYPES,
} from '@constants/operatorFieldTypes';
import SettingDropDown from '@components/2-molecules/SettingDropDown';
import SettingTextField from '@components/2-molecules/SettingTextField';
import SettingCheckbox from '@components/2-molecules/SettingCheckbox';
import { Wrapper } from './WorkflowReadOnlyOperatorFields.styled';

const WorkflowReadOnlyOperatorFields = ({ operator = {} }) => {
  const intl = useIntl();

  const { getOperatorVisibleInputs, getOperatorFieldOptions } = useOperator();

  const operatorValues = useMemo(
    () => getOperatorVisibleInputs(operator),
    [getOperatorVisibleInputs, operator],
  );

  const comparisonAwareOperatorValues = useMemo(() => {
    return operatorValues.filter((fieldInput) => {
      // Check if the field input is modified or removed
      const isModifiedOrRemoved =
        fieldInput?.diffModified || fieldInput?.diffRemoved;

      // If operatorDiff is true, only include modified or removed items
      if (operator.diffOperator) {
        return isModifiedOrRemoved;
      }

      return true;
    });
  }, [operatorValues, operator.diffOperator]);

  const getDropDownPlaceholder = useCallback(
    (fieldInput = {}) => {
      const { type } = fieldInput;
      let placeholder = intl.formatMessage({
        id: 'editorpanel.selector.select_value',
        defaultMessage: 'Select value',
      });

      if (DROP_DOWN_INPUT_FIELD_TYPES.includes(type)) {
        placeholder = intl.formatMessage({
          id: 'editorpanel.selector.select_input',
          defaultMessage: 'Select input',
        });
      }

      if (DROP_DOWN_FILE_FIELD_TYPES.includes(type)) {
        placeholder = intl.formatMessage({
          id: 'editorpanel.selector.select_file',
          defaultMessage: 'Select file',
        });
      }

      if (DROP_DOWN_DATA_FIELD_TYPES.includes(type)) {
        placeholder = intl.formatMessage({
          id: 'editorpanel.selector.select_data',
          defaultMessage: 'Select data',
        });
      }

      return placeholder;
    },
    [intl],
  );

  const renderDropDownField = useCallback(
    (fieldInput = {}) => {
      const fieldName = fieldInput?.name || '';
      const options = getOperatorFieldOptions(operator, fieldInput);
      const selectedOption = options.find(
        (option) => option.value === fieldInput?.value,
      ) || { label: '', value: '' };

      return (
        <SettingDropDown
          label={fieldName}
          dropDownField={{
            disabled: true,
            value: selectedOption?.label,
            placeholder: getDropDownPlaceholder(fieldInput),
            diffRemoved: fieldInput?.diffRemoved,
            diffAdded: fieldInput?.diffAdded,
            diffModified: fieldInput?.diffModified,
            fullWidthDropDownMenu: false,
            transparentWhenDisabled: false,
          }}
        />
      );
    },
    [getDropDownPlaceholder, getOperatorFieldOptions, operator],
  );

  const renderInputField = useCallback(
    (fieldInput = {}) => {
      const fieldName = fieldInput?.name || '';
      const fieldInputType = fieldInput?.type || '';
      const intlId = `operatorinput.placeholder.${fieldInputType?.toLowerCase()}`;

      return (
        <SettingTextField
          label={fieldName}
          field1={{
            disabled: true,
            type: fieldInputType,
            placeholder: intl.formatMessage({
              id: intlId,
              defaultMessage: fieldInputType,
            }),
            value: fieldInput?.value,
            diffRemoved: fieldInput?.diffRemoved,
            diffAdded: fieldInput?.diffAdded,
            diffModified: fieldInput?.diffModified,
            transparentWhenDisabled: false,
          }}
        />
      );
    },
    [intl],
  );

  const renderCheckboxField = useCallback((fieldInput = {}) => {
    const fieldName = fieldInput?.name || '';
    const checked = fieldInput.value === 'true';

    return (
      <SettingCheckbox
        label={fieldName}
        checkbox={{
          disabled: true,
          checked: checked,
          diffRemoved: fieldInput?.diffRemoved,
          diffAdded: fieldInput?.diffAdded,
          diffModified: fieldInput?.diffModified,
          transparentWhenDisabled: false,
        }}
      />
    );
  }, []);

  return (
    <Wrapper>
      {comparisonAwareOperatorValues?.map((fieldInput) => (
        <Fragment key={fieldInput.id}>
          {DROP_DOWN_FIELD_TYPES.includes(fieldInput?.type) &&
            renderDropDownField(fieldInput)}

          {INPUT_FIELD_TYPES.includes(fieldInput?.type) &&
            renderInputField(fieldInput)}

          {CHECKBOX_FIELD_TYPES.includes(fieldInput?.type) &&
            renderCheckboxField(fieldInput)}
        </Fragment>
      ))}
    </Wrapper>
  );
};

WorkflowReadOnlyOperatorFields.propTypes = {
  operator: PropTypes.object,
};

export default WorkflowReadOnlyOperatorFields;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import useOperator from '@hooks/operators/useOperator';
import { Content, Icon, Text } from './WorkflowCanvasSelectionInputBar.styled';
import Paper from '@app/components/1-atoms/Paper';
import IconButton, {
  ICON_BUTTON_VARIANT_PLAIN,
} from '@components/1-atoms/IconButton';

const WorkflowCanvasSelectionInputBar = () => {
  const { deselectCanvasSelectionField } = useOperator();

  return (
    <Paper>
      <Content>
        <Icon name="info_0" />
        <Text>
          <FormattedMessage
            id="operator.canvasselection.helper"
            defaultMessage="Select a surface on the canvas"
          />
        </Text>
        <IconButton
          variant={ICON_BUTTON_VARIANT_PLAIN}
          iconName="close_0"
          onClick={deselectCanvasSelectionField}
        />
      </Content>
    </Paper>
  );
};

WorkflowCanvasSelectionInputBar.propTypes = {};

export default WorkflowCanvasSelectionInputBar;

import styled, { css } from 'styled-components';
import SpriteSVG from '@app/components/1-atoms/SpriteSVG';
import { flexCenterVertical } from '@stylesheets/helpers';

export const Content = styled.div`
  ${flexCenterVertical}
  padding: 0px 10px;

  ${({ theme: { colors } }) => css`
    color: ${colors.onSurface};
  `}
`;

export const Text = styled.p`
  padding: 0px 5px;
`;

export const Icon = styled(SpriteSVG)`
  flex-shrink: 0;

  ${({ theme: { colors } }) => css`
    fill: ${colors.onSurface};
  `}
`;

import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';
import React from 'react';

const PostProcessorCEAD = ({ isSubmitting }) => {
  const { values, setFieldValue } = useFormikContext();
  const intl = useIntl();
  return (
    <>
      <FormikField
        component={PostProcessorSetting}
        dataTestId={`printer-custom-post-processor__setting-ceadEnablePurge`}
        label={intl.formatMessage({
          id: 'printers.postprocessor.cead.purge.label',
          defaultMessage: 'Enable Purge Sequence',
        })}
        renderAsCheckbox
        noPaddingBottom
        name={'ceadEnablePurge'}
        checked={Boolean(values['ceadEnablePurge'])}
        onChange={() => {
          setFieldValue('ceadEnablePurge', !values['ceadEnablePurge']);
        }}
        disabled={isSubmitting}
      />
      <FormikField
        component={PostProcessorSetting}
        dataTestId={`printer-custom-post-processor__setting-ceadEnableTemperatures`}
        label={intl.formatMessage({
          id: 'printers.postprocessor.cead.temperature.label',
          defaultMessage: 'Enable Temperature Control Instructions',
        })}
        renderAsCheckbox
        noPaddingBottom
        name={'ceadEnableTemperatures'}
        checked={Boolean(values['ceadEnableTemperatures'])}
        onChange={() => {
          setFieldValue(
            'ceadEnableTemperatures',
            !values['ceadEnableTemperatures'],
          );
        }}
        disabled={isSubmitting}
      />
    </>
  );
};

PostProcessorCEAD.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorCEAD;

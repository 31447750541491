import ReconnectingWebSocket from 'reconnecting-websocket';
import { useSelector, useDispatch } from 'react-redux';
import usePrevious from '@hooks/usePrevious';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useWorkflowComputation from '@hooks/workflows/useWorkflowComputation';
import useFile from '@hooks/files/useFile';
import { getConfig } from '@actions/AuthenticatedFetch';
import { resetComputationProgressHandler } from '@actions/computationProgressActions';
import { getCurrentUser } from '@selectors/loginSelectors';
import {
  displayComputedOutput,
  fetchToolpathInstructionsIfSelected,
} from '@actions/conceptActions';
import {
  CLOSED_CONNECTION_TO_TOOLPATH_SOCKET,
  CONNECT_TO_TOOLPATH_SOCKET,
  EVENTS_WEBSOCKET_CREATED,
} from '@constants/actionTypes';
import { tokenAuthentication } from '@actions/loginActions';
import { WEBSOCKET_VALIDATE_TOKEN_RECONNECTION_LIMIT } from '@constants/websocket';
import { manageErrorDuringComputation } from '@actions/errorActions';
import { useCallback, useEffect, useMemo } from 'react';

const RECCONECT_DELAY_AFTER_COMPUTATION_ENDS = 3000;

const WS_MESSAGE_TYPE = {
  RUNNING_OPERATOR: 'RUNNING_OPERATOR',
  WORKFLOW_COMPUTATION_COMPLETED: 'WORKFLOW_COMPUTATION_COMPLETED',
  WORKFLOW_COMPUTATION_ERROR: 'WORKFLOW_COMPUTATION_ERROR',
};

export default function useWorkflowSocket() {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser());

  const { getSelectedWorkflow, invalidateWorkflowQuery } = useWorkflow();
  const { handleComputationMessage } = useWorkflowComputation();
  const { invalidateProjectFielsQuery } = useFile();

  const workflow = getSelectedWorkflow();
  const workflowId = workflow?.id;
  const projectId = workflow?.workspaceId;
  const isWorkflowComputing = workflow?.computing;
  const previousIsWorkflowComputing = usePrevious(isWorkflowComputing);

  const Config = getConfig();
  const baseUrl = Config.baseUrlws || 'ws://localhost/';

  const urlProvider = useCallback(async () => {
    const token = currentUser?.token;

    return baseUrl + 'ws/events' + '?token=' + encodeURIComponent(token);
  }, [baseUrl, currentUser?.token]);

  const wsOptions = useMemo(
    () => ({
      connectionTimeout: 2000,
      minReconnectionDelay: 500,
      maxReconnectionDelay: 3000,
    }),
    [],
  );

  const ws = useMemo(
    () => new ReconnectingWebSocket(urlProvider, [], wsOptions),
    [urlProvider, wsOptions],
  );

  const resetWorkflowOnFinish = useCallback(async () => {
    dispatch(resetComputationProgressHandler());
    await invalidateWorkflowQuery(workflowId);
    await invalidateProjectFielsQuery(projectId);
  }, [
    dispatch,
    invalidateWorkflowQuery,
    invalidateProjectFielsQuery,
    workflowId,
    projectId,
  ]);

  const manageWorkflowComputationSuccess = useCallback(async () => {
    await resetWorkflowOnFinish();
    dispatch(displayComputedOutput(getSelectedWorkflow()));
    dispatch(fetchToolpathInstructionsIfSelected());
  }, [dispatch, resetWorkflowOnFinish, getSelectedWorkflow]);

  const handleWorkflowReceivedMessage = useCallback(
    async (message) => {
      const event = JSON.parse(message.data);
      const eventData = event?.data || {};
      // TODO for all of these, instead of blindy assigning the data, we should
      // decompose it explicitly into properties as a means of documenting
      // here the expected data properties

      // IMPORTANT NOTE:
      // all of the actions dispatched below will be catched by
      // not only toolpathReducer, but analyticsReducer too

      const belongToSelectedWorkflow = eventData?.conceptId === workflowId;

      if (!belongToSelectedWorkflow) return;

      switch (event.type) {
        case WS_MESSAGE_TYPE.RUNNING_OPERATOR: {
          handleComputationMessage(event);

          break;
        }

        case WS_MESSAGE_TYPE.WORKFLOW_COMPUTATION_COMPLETED: {
          manageWorkflowComputationSuccess();
          break;
        }

        case WS_MESSAGE_TYPE.WORKFLOW_COMPUTATION_ERROR: {
          const { data } = event;
          const errorData = data?.errorData;
          const conceptId = data?.conceptId || data?.operator?.conceptId;
          const isErrorForSelectedWorkflow = conceptId === workflow?.id;

          if (isErrorForSelectedWorkflow) {
            dispatch(manageErrorDuringComputation(errorData));
            await resetWorkflowOnFinish();
          }

          break;
        }
      }
    },
    [
      dispatch,
      handleComputationMessage,
      manageWorkflowComputationSuccess,
      resetWorkflowOnFinish,
      workflow,
      workflowId,
    ],
  );

  useEffect(() => {
    ws.addEventListener('open', () => {
      if (DEVELOPMENT_ENV) {
        // eslint-disable-next-line no-console
        console.log('WebSocket open');
      }

      dispatch({
        type: CONNECT_TO_TOOLPATH_SOCKET,
      });
    });

    dispatch({
      type: EVENTS_WEBSOCKET_CREATED,
      payload: ws,
    });
  }, [dispatch, ws]);

  useEffect(() => {
    ws.addEventListener('close', (closeEvent) => {
      const retryCount = closeEvent?.target?._retryCount || 0;
      const shouldDispatchCloseAction = retryCount <= 1;
      const shouldValidateToken =
        retryCount === WEBSOCKET_VALIDATE_TOKEN_RECONNECTION_LIMIT;

      if (DEVELOPMENT_ENV) {
        // eslint-disable-next-line no-console
        console.log('WebSocket closed');
      }

      if (shouldDispatchCloseAction) {
        dispatch({
          type: CLOSED_CONNECTION_TO_TOOLPATH_SOCKET,
        });
      }

      if (shouldValidateToken) {
        dispatch(dispatch(tokenAuthentication()));
      }
    });
  }, [dispatch, ws]);

  // Workflow computation messages handler
  useEffect(() => {
    ws.removeEventListener('message', handleWorkflowReceivedMessage);
    ws.addEventListener('message', handleWorkflowReceivedMessage);

    return () => {
      ws.removeEventListener('message', handleWorkflowReceivedMessage);
    };
  }, [handleWorkflowReceivedMessage, ws]);

  useEffect(() => {
    // Neede to avoid receiving messages right after computation is stopped
    // and until the websocket messages stop coming
    if (previousIsWorkflowComputing && !isWorkflowComputing) {
      ws.close();

      setTimeout(() => {
        ws.reconnect();
      }, RECCONECT_DELAY_AFTER_COMPUTATION_ENDS);
    }
  }, [ws, isWorkflowComputing, previousIsWorkflowComputing]);

  useEffect(() => {
    return () => {
      if (ws.OPEN) ws.close();
    };
  }, [ws]);

  return ws;
}

import React, { useCallback, useMemo } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useWorkflowList from '@hooks/workflows/useWorkflowList';
import useProjectQueries from '@hooks/projects/useProjectQueries';
import useWorkflowQueries from '@hooks/workflows/useWorkflowQueries';
import { getCurrentUser } from '@selectors/loginSelectors';
import { ROUTES } from '@constants/router';
import { Wrapper } from './Workflows.styled';
import WorkflowsPageContent from '@app/components/WorkflowsPageContent';

const Workflows = () => {
  const intl = useIntl();
  const history = useHistory();
  const { workspaceId: projectId } = useParams();
  const user = useSelector(getCurrentUser());
  const { getWorkflowDropDownMenu } = useWorkflowList();
  const { projectQuery } = useProjectQueries({ projectId });
  const { projectWorkflowsQuery } = useWorkflowQueries({ projectId });

  const project = projectQuery.data;
  const workflows = projectWorkflowsQuery.data;

  const workflowListItems = useMemo(
    () =>
      workflows?.map((workflow) => {
        const isUserInOrganization =
          (workflow?.organization || workflow?.organizationId) ===
          user?.organizationId;
        const isPublic = workflow?.public || workflow?.publicAccess;
        let optionalListItemProps = {};

        if (isPublic) {
          optionalListItemProps = {
            label: intl.formatMessage(
              {
                id: 'workflowspage.workflows_list.workflow_name_public',
                defaultMessage: '{workflowName} (Public)',
              },
              { workflowName: workflow?.name },
            ),
            leadingIconName: 'language_0',
          };
        }

        if (isUserInOrganization) {
          optionalListItemProps = {
            ...optionalListItemProps,
            endingIconButtonIconName: 'more_vert_0',
            endingIconButtonDropDownProps: {
              dropDownMenuItems: getWorkflowDropDownMenu(workflow),
            },
          };
        }

        return {
          id: workflow?.id,
          label: workflow?.name,
          description: intl.formatMessage(
            {
              id: 'workflowspage.workflows_list.workflow_last_update',
              defaultMessage: 'Created: {date}',
            },
            { date: moment(workflow?.createdAt).format('DD/MM/YYYY') },
          ),
          leadingIconName: 'network_node_0',
          createdAt: workflow?.createdAt,
          onClick: () =>
            history.push(
              generatePath(ROUTES.WORKFLOW, {
                workspaceId: workflow?.workspaceId,
                itemId: workflow?.id,
              }),
            ),
          ...optionalListItemProps,
        };
      }),
    [intl, history, workflows, user, getWorkflowDropDownMenu],
  );

  const handleGoBack = useCallback(() => {
    history.push(ROUTES.PROJECTS);
  }, [history]);

  const handleTabClick = useCallback(
    (tab) => {
      const isFileTab = tab?.title === 'Files';

      if (!isFileTab) return;

      history.push(
        generatePath(ROUTES.FILES, {
          workspaceId: projectId,
        }),
      );
    },
    [history, projectId],
  );

  return (
    <Wrapper data-testid="workflows-page">
      <WorkflowsPageContent
        handleTabClick={handleTabClick}
        headerLeadingIconButtonIconName="arrow_back_0"
        headerTitle={project?.name || ''}
        loading={!projectWorkflowsQuery?.isFetched}
        onHeaderLeadingIconButtonClick={handleGoBack}
        project={project}
        workflowListItems={workflowListItems}
        tabs={[
          {
            id: 'workflows-tab-1',
            title: intl.formatMessage({
              id: 'workflowspage.workflows_list.tab_title.workflows',
              defaultMessage: 'Workflows',
            }),
          },
          {
            id: 'workflows-tab-2',
            title: intl.formatMessage({
              id: 'workflowspage.workflows_list.tab_title.files',
              defaultMessage: 'Files',
            }),
          },
        ]}
      />
    </Wrapper>
  );
};

Workflows.propTypes = {};

export default Workflows;

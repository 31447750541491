import React, { useCallback } from 'react';
import { useDispatch, batch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import { map } from 'lodash';
import { useFormContext } from 'react-hook-form';
import useDialog from '@hooks/useDialog';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useWorkflowMutations from '@hooks/workflows/useWorkflowMutations';
import useOperatorMutations from '@hooks/operators/useOperatorMutations';
import { MODAL_IDS, ModalDataTypes } from '@constants/modalDataTypes';
import { ROUTES } from '@constants/router';
import useVersion from '@hooks/version/useVersion';
import { compare } from 'compare-versions';
import {
  updateWorkspaceVisibility,
  updatePrintingBedVisibility,
  updateRobotVisibility,
  updateEnclosureVisibility,
} from '@actions/conceptActions';
import { getCurrentUser } from '@selectors/loginSelectors';
import useSnackbar from '@hooks/useSnackbar';
import useModal from '@hooks/useModal';
import useProject from '@hooks/projects/useProject';
import useLeanUpon from '@hooks/learnUpon/useLeanUpon';
import { releaseNoteLink } from '@constants/releaseNote';
import Link from '@components/1-atoms/Link';

export default function useWorkflowList() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { itemId: selectedWorkflowId } = useParams();
  const { getSelectedProject } = useProject();
  const { showSnackbar } = useSnackbar();
  const {
    getSortedProjectWorkflows,
    getSelectedWorkflow,
    getIsWorkflowPublic,
    getIsWorkflowComputing,
    getIsWorkflowDisabled,
    getIsPrinterUpdateAvailable,
    getIsWorkflowEditable,
    updateAndComputeWorkflow,
  } = useWorkflow();
  const {
    updateWorkflowMetadataMutation,
    deleteWorkflowMutation,
    updateWorkflowPrinterConfigMutation,
  } = useWorkflowMutations();
  const { upgradeOperatorsMutation } = useOperatorMutations();
  const { showDialog } = useDialog();
  const { showModal } = useModal();
  const { getComputationVersion } = useVersion();
  const version = getComputationVersion();
  const user = useSelector(getCurrentUser());
  const { onLearnUponLinkClick } = useLeanUpon();

  const form = useFormContext();

  const getWorkflowDropDownMenu = useCallback(
    (workflow = {}) => {
      let actions = [];
      const isWorkflowEditable = getIsWorkflowEditable(workflow);

      if (!isWorkflowEditable) return actions;

      const isPublic = workflow?.public || workflow?.publicAccess;
      const hasTemplateOperator = workflow?.operators?.some(
        ({ templateId }) => !!templateId,
      );
      const isWorkflowComputing = getIsWorkflowComputing(workflow);

      actions = [
        ...actions,
        {
          leadingIconName: 'info_0',
          label: intl.formatMessage({
            id: 'general.details',
            defaultMessage: 'Details',
          }),
          onClick: () => {
            showDialog(ModalDataTypes.WORKFLOW_DETAILS, {
              workflow,
            });
          },
        },
        {
          leadingIconName: 'edit_0',
          label: intl.formatMessage({
            id: 'general.rename',
            defaultMessage: 'Rename',
          }),
          onClick: () =>
            showDialog(ModalDataTypes.EDIT_WORKFLOW, {
              workflowId: workflow.id,
              workflowName: workflow.name,
              workflowIsPublic: isPublic,
              projectId: workflow.workspaceId,
            }),
        },
      ];

      if (!hasTemplateOperator) {
        actions = [
          ...actions,
          {
            leadingIconName: 'content_copy_0',
            label: intl.formatMessage({
              id: 'general.duplicate',
              defaultMessage: 'Duplicate',
            }),
            onClick: () =>
              showDialog(ModalDataTypes.DUPLICATE_WORKFLOW, {
                workflow,
              }),
          },
        ];
      }

      if (user?.organizationAllowPublicWorkflows) {
        actions = [
          ...actions,
          {
            leadingIconName: 'language_0',
            label: intl.formatMessage({
              id: 'general.public',
              defaultMessage: 'Public',
            }),
            endingButton: {
              type: 'switch',
              enabled: isPublic,
              onChange: () => {
                updateWorkflowMetadataMutation.mutate({
                  id: workflow.id,
                  name: workflow.name,
                  public: !isPublic,
                  projectId: workflow.workspaceId,
                });
              },
            },
          },
        ];
      }

      if (!isWorkflowComputing) {
        actions = [
          ...actions,
          {
            leadingIconName: 'delete_0',
            label: intl.formatMessage({
              id: 'general.delete',
              defaultMessage: 'Delete',
            }),
            color: 'error',
            onClick: () => {
              showDialog(ModalDataTypes.PROMPT, {
                dataTestId: 'delete-workflow-dialog',
                title: intl.formatMessage({
                  id: 'deleteworkflow.dialog.title',
                  defaultMessage: 'Delete Workflow',
                }),
                subtitle: intl.formatMessage(
                  {
                    id: 'deleteworkflow.dialog.subtitle',
                    defaultMessage:
                      '<b>{workflowName}</b> will be permanently deleted and all its configurations will be lost. <br></br><br></br> Are you sure you want to proceed?',
                  },
                  {
                    workflowName: workflow?.name,
                    b: (str) => <b>{str}</b>,
                    br: () => <br />,
                  },
                ),
                onPrimaryButtonClick: async () => {
                  try {
                    await deleteWorkflowMutation.mutateAsync({
                      id: workflow?.id,
                      projectId: workflow?.workspaceId,
                    });
                    showSnackbar({
                      text: intl.formatMessage({
                        id: 'deleteworkflow.snackbar.label',
                        defaultMessage: 'Workflow successfully deleted',
                      }),
                    });
                  } catch (_) {
                    //error
                  }

                  if (selectedWorkflowId === workflow?.id) {
                    const navigationPath = generatePath(ROUTES.WORKFLOWS, {
                      workspaceId: workflow?.workspaceId,
                    });

                    history.push(navigationPath);
                  }
                },
              });
            },
          },
        ];
      }

      return actions;
    },
    [
      showSnackbar,
      user,
      intl,
      history,
      showDialog,
      updateWorkflowMetadataMutation,
      deleteWorkflowMutation,
      selectedWorkflowId,
      getIsWorkflowComputing,
      getIsWorkflowEditable,
    ],
  );

  const getWorkflowsListDropDownMenu = useCallback(() => {
    const selectedWorkflow = getSelectedWorkflow();
    const selectedProjectId = selectedWorkflow?.workspaceId;
    const projectWorkflows = getSortedProjectWorkflows(selectedProjectId);

    let actions = [];

    const project = getSelectedProject();
    if (!project?.publicAccess) {
      actions.push({
        leadingIconName: 'add_0',
        label: intl.formatMessage({
          id: 'workflows.dropdownmenu.item.label',
          defaultMessage: 'New workflow',
        }),
        navigationItem: true,
        onClick: () => {
          showDialog(ModalDataTypes.CREATE_WORKFLOW, {
            projectId: selectedProjectId,
          });
        },
      });
    }

    const workflowItems = projectWorkflows?.map((workflow, i) => {
      const isWorkflowPublic = getIsWorkflowPublic(workflow);

      return {
        id: workflow.id,
        leadingIconName: isWorkflowPublic ? 'language_0' : 'network_node_0',
        label: isWorkflowPublic
          ? `${workflow.name} (${intl.formatMessage({
              id: 'general.public',
              defaultMessage: 'Public',
            })})`
          : workflow.name,
        selected: workflow.id === selectedWorkflow?.id,
        withDivider: i === 0,
        onClick: () => {
          const navigationPath = generatePath(ROUTES.WORKFLOW, {
            workspaceId: selectedProjectId,
            itemId: workflow?.id,
          });

          history.push(navigationPath);
        },
      };
    });

    actions = [...actions, ...workflowItems];

    return actions;
  }, [
    getSelectedProject,
    history,
    intl,
    getSelectedWorkflow,
    getSortedProjectWorkflows,
    getIsWorkflowPublic,
    showDialog,
  ]);

  const handleWorkflowUpgrade = useCallback(
    async (workflow, deprecatedOperators) => {
      await upgradeOperatorsMutation.mutateAsync({
        workflowId: workflow.id,
        operatorsId: map(deprecatedOperators, 'id'),
      });
    },
    [upgradeOperatorsMutation],
  );

  const handlePrinterConfigurationUpdate = useCallback(
    async (workflow, callApi = false) => {
      if (callApi) {
        await updateWorkflowPrinterConfigMutation.mutateAsync({
          workflowId: workflow.id,
        });
      }
      batch(() => {
        dispatch(updatePrintingBedVisibility(true));
        dispatch(updateEnclosureVisibility(true));
        dispatch(updateRobotVisibility(true));
        dispatch(updateWorkspaceVisibility(true));
      });
    },
    [updateWorkflowPrinterConfigMutation, dispatch],
  );

  const releaseNotesLink = useCallback(
    (label) => {
      return (
        <Link onClick={() => onLearnUponLinkClick(releaseNoteLink)} to="#">
          {label}
        </Link>
      );
    },
    [onLearnUponLinkClick],
  );

  const getSelectedWorkflowMoreDropDownMenu = useCallback(
    ({ onCollapseAll, onExpandAll } = {}) => {
      const workflow = getSelectedWorkflow();
      const workflowDisabled = getIsWorkflowDisabled(workflow);
      const generalWorkflowActions = getWorkflowDropDownMenu(workflow);
      const isWorkflowEditable = getIsWorkflowEditable(workflow);
      const printerUpdateAvailable = getIsPrinterUpdateAvailable(workflow);
      const deprecatedOperators = workflow?.operators?.filter(
        (operator) => operator.deprecated && operator.upgradable,
      );
      let actions = [];

      if (generalWorkflowActions?.[0]) {
        generalWorkflowActions[0].withDivider = true;
      }
      const upgradeAvailable =
        isWorkflowEditable &&
        !workflowDisabled &&
        compare(version || '0.0.0', workflow?.version ?? '0.0.0', '>');

      const workflowUpdateAvailable =
        upgradeAvailable || printerUpdateAvailable;

      if (workflowUpdateAvailable) {
        let subtitle;
        let title = intl.formatMessage(
          {
            id: 'workflow.upgrade.dialog.title',
            defaultMessage: 'Update available ({newVersion})',
          },
          {
            newVersion: version,
          },
        );
        let onPrimaryButtonClick = null;

        if (deprecatedOperators.length > 0 && !printerUpdateAvailable) {
          subtitle = intl.formatMessage(
            {
              id: 'workflow.upgrade.dialog.subtitle',
              defaultMessage:
                'There is a new Aibuild update available.<br></br>The new version includes changes to the following operators:<br></br>{operatorNames} <br></br><a>Read Release Notes</a><br></br> Updating may affect current workflow settings and outputs.<br></br>Do you want to proceed with the update?',
            },
            {
              operatorNames: deprecatedOperators?.map(({ tag, name }) => (
                <>
                  • {tag || name}
                  <br />
                </>
              )),
              b: (str) => <b>{str}</b>,
              br: () => <br />,
              a: (str) => releaseNotesLink(str),
            },
          );

          onPrimaryButtonClick = (workflowIsComputable) => async () => {
            try {
              await handleWorkflowUpgrade(workflow, deprecatedOperators);
              if (workflowIsComputable) {
                updateAndComputeWorkflow();
              } else {
                showSnackbar({
                  text: intl.formatMessage({
                    id: 'workflow.upgrade.snackbar.label',
                    defaultMessage:
                      'Workflow successfully updated to the latest version',
                  }),
                });
              }
            } catch (_) {
              //error
            }
          };
        } else if (
          deprecatedOperators.length == 0 &&
          printerUpdateAvailable &&
          !upgradeAvailable
        ) {
          title = intl.formatMessage({
            id: 'workflow.update.printer.dialog.title',
            defaultMessage: 'Update the workflow setup',
          });

          subtitle = intl.formatMessage(
            {
              id: 'workflow.upgrade.dialog.subtitle.only_printer',
              defaultMessage:
                'The configuration of the current workflow setup, such as the printer, nozzle, or material, has been updated.<br></br><br></br>Do you wish to apply these changes?',
            },
            {
              b: (str) => <b>{str}</b>,
              br: () => <br />,
            },
          );

          onPrimaryButtonClick = (workflowIsComputable) => async () => {
            try {
              await handlePrinterConfigurationUpdate(workflow, true);
              if (workflowIsComputable) {
                updateAndComputeWorkflow();
              } else {
                showSnackbar({
                  text: intl.formatMessage({
                    id: 'workflow.upgrade_printer.snackbar.label',
                    defaultMessage: 'Workflow setup successfully updated',
                  }),
                });
              }
            } catch (_) {
              //error
            }
          };
        } else {
          subtitle = intl.formatMessage(
            {
              id: 'workflow.upgrade.dialog.subtitle.only_version',
              defaultMessage:
                'There is a new Aibuild update available.<br></br><br></br><a>Read Release Notes</a><br></br><br></br>Updating may affect current workflow settings and outputs.<br></br>Do you want to proceed with the update?',
            },
            {
              br: () => <br />,
              a: (str) => releaseNotesLink(str),
            },
          );
          onPrimaryButtonClick = (workflowIsComputable) => async () => {
            try {
              await handleWorkflowUpgrade(workflow, deprecatedOperators);
              await handlePrinterConfigurationUpdate(workflow, false);

              if (workflowIsComputable) {
                updateAndComputeWorkflow();
              } else {
                showSnackbar({
                  text: intl.formatMessage({
                    id: 'workflow.upgrade.snackbar.label',
                    defaultMessage:
                      'Workflow successfully updated to the latest version',
                  }),
                });
              }
            } catch (_) {
              //error
            }
          };
        }

        actions = [
          {
            reviewUpdateAction: upgradeAvailable,
            reviewUpdatePrinterAction: printerUpdateAvailable,
            withBadge: true,
            leadingIconName: 'cloud_download',
            label: intl.formatMessage({
              id: 'workflow.dropdownmenu.actions.review_update',
              defaultMessage: 'Review update',
            }),
            onClick: ({ workflowIsComputable = true } = {}) => {
              showDialog(ModalDataTypes.PROMPT, {
                dataTestId: 'upgrade-workflow-dialog',
                title,
                subtitle,
                secondaryButtonLabel: intl.formatMessage({
                  id: 'workflow.upgrade.dialog.secondary_button_title',
                  defaultMessage: 'I’ll do it later',
                }),
                primaryButtonLabel: intl.formatMessage({
                  id: 'general.update',
                  defaultMessage: 'Update',
                }),
                onPrimaryButtonClick:
                  onPrimaryButtonClick(workflowIsComputable),
              });
            },
          },
        ];
      }

      actions = [
        ...actions,
        {
          withDivider: true,
          leadingIconName: 'account_tree_0',
          label: intl.formatMessage({
            id: 'visualTool.visualTool',
            defaultMessage: 'Visual tool',
          }),
          onClick: () => {
            showModal(MODAL_IDS.VISUAL_TOOL, {
              form,
              headerTitle: workflow?.name,
              headerZIndex: 1,
            });
          },
        },
        {
          withDivider: workflowUpdateAvailable,
          leadingIconName: 'collapse_all_0',
          label: intl.formatMessage({
            id: 'general.collapse_all',
            defaultMessage: 'Collapse all',
          }),
          onClick: onCollapseAll,
        },
        {
          leadingIconName: 'expand_all_0',
          label: intl.formatMessage({
            id: 'general.expand_all',
            defaultMessage: 'Expand all',
          }),
          onClick: onExpandAll,
        },
      ];

      return [...actions, ...generalWorkflowActions];
    },
    [
      releaseNotesLink,
      intl,
      getSelectedWorkflow,
      getWorkflowDropDownMenu,
      handlePrinterConfigurationUpdate,
      handleWorkflowUpgrade,
      showDialog,
      showModal,
      version,
      getIsWorkflowDisabled,
      getIsPrinterUpdateAvailable,
      updateAndComputeWorkflow,
      getIsWorkflowEditable,
      showSnackbar,
      form,
    ],
  );

  return {
    getWorkflowDropDownMenu,
    getWorkflowsListDropDownMenu,
    getSelectedWorkflowMoreDropDownMenu,
  };
}

import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  hiddenInputNames: [],
  isSubmitted: false,
  isAwaitingComputation: false,
  activeCanvasSelectionInput: null,
  operators: {
    fullScreenOperatorId: null,
    showFullScreenOperator: false,
  },
};

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setHiddenInputNames(state, action) {
      state.hiddenInputNames = action.payload;
    },
    setIsSubmitted(state, action) {
      state.isSubmitted = action.payload;
    },
    setIsAwaitingComputation(state, action) {
      state.isAwaitingComputation = action.payload;
    },
    setActiveCanvasSelectionInput(state, action) {
      state.activeCanvasSelectionInput = action.payload;
    },
    setFullScreenOperatorId(state, action) {
      state.operators.fullScreenOperatorId = action.payload;
    },
    setFullScreenOperatorVisibility(state, action) {
      state.operators.showFullScreenOperator = action.payload;
    },

    resetHiddenInputNames(state) {
      state.hiddenInputNames = [];
    },
    resetIsSubmitted(state) {
      state.isSubmitted = false;
    },
    resetIsAwaitingComputation(state) {
      state.isAwaitingComputation = false;
    },
    resetActiveCanvasSelectionInput(state) {
      state.activeCanvasSelectionInput = null;
    },
    resetFullScreenOperatorId(state) {
      state.operators.fullScreenOperatorId =
        initialState.operators.fullScreenOperatorId;
    },
    resetFullScreenOperatorVisibility(state) {
      state.operators.showFullScreenOperator =
        initialState.operators.showFullScreenOperator;
    },
  },
});

export const {
  setHiddenInputNames,
  resetHiddenInputNames,
  resetIsSubmitted,
  setIsSubmitted,
  setIsAwaitingComputation,
  setFullScreenOperatorVisibility,
  setFullScreenOperatorId,
  resetIsAwaitingComputation,
  setActiveCanvasSelectionInput,
  resetActiveCanvasSelectionInput,
  resetFullScreenOperatorId,
  resetFullScreenOperatorVisibility,
} = workflowSlice.actions;

export const selectHiddenInputNames = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.hiddenInputNames,
);

export const selectIsSubmitted = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.isSubmitted,
);

export const selectIsAwaitingComputation = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.isAwaitingComputation,
);

export const selectActiveCanvasSelectionInput = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.activeCanvasSelectionInput,
);

export const selectFullScreenOperatorId = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.operators.fullScreenOperatorId,
);

export const selectFullScreenOperatorVisibility = createSelector(
  (state) => state.workflow,
  (workflow) => workflow.operators.showFullScreenOperator,
);

export default workflowSlice.reducer;

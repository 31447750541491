import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { useRouteMatch, useHistory } from 'react-router-dom';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useSteps from '@hooks/useSteps';
import useQuery from '@hooks/useQuery';
import usePrevious from '@hooks/usePrevious';
import client from '@api/client';
import endpoints from '@api/endpoints';
import { isPasswordComplexEnough } from '@utils/validation';
import {
  getCurrentUser,
  getLoginTokenExpired,
  getLoginFailed,
} from '@selectors/loginSelectors';
import { getMFA } from '@selectors/settingsSelectors';
import {
  login,
  createResetPasswordToken,
  resetPassword,
  resetLoginFailure,
} from '@actions/loginActions';
import LoginLayout from '@components/1-atoms/Layouts/LoginLayout';
import Button, { BUTTON_VARIANT_TEXT } from '@components/1-atoms/Button';
import { Header, Logo, Form, Footer } from './Login.styled';
import LoginUserName from '@components/3-organisms/LoginUserName';
import Login2FACode, {
  MFA_CODE_LENGTH,
} from '@components/3-organisms/Login2FACode';
import Login2FAConnector from '@components/3-organisms/Login2FAConnector';
import LoginResetPasswordEmail from '@components/3-organisms/LoginResetPasswordEmail';
import LoginResetPassword from '@components/3-organisms/LoginResetPassword';
import { ROUTES } from '@constants/router';
import useAuthQueries from '@hooks/auth/useAuthQueries';
import LoginResetPasswordExpiredLink from '@components/3-organisms/LoginResetPasswordExpiredLink';
import { useQueryClient } from '@tanstack/react-query';

const MFA_CODE_FIELD_NAME = 'mfaCode';
const MFA_CONNECTOR_EXPIRATION_TIME = 570000;

const LOGIN_STEP_USERNAME = 'username';
const LOGIN_STEP_MFA_CODE = 'mfaCode';
const LOGIN_STEP_MFA_CONNECTOR = 'mfaConnector';
const LOGIN_STEP_MFA_CONNECTOR_VALIDATE_CODE = 'mfaConnectorValidateCode';
const LOGIN_STEP_RESET_PASSWORD_EMAIL = 'resetPasswordEmail';
const LOGIN_STEP_RESET_PASSWORD_EMAIL_SENT = 'resetPasswordEmailSent';
const LOGIN_STEP_RESET_PASSWORD_NEW_PASSWORD = 'resetPasswordNewPassword';
const LOGIN_STEP_RESET_PASSWORD_EXPIRED_LINK = 'resetPasswordExpiredLink';

const LOGIN_STEPS = [
  LOGIN_STEP_USERNAME,
  LOGIN_STEP_MFA_CODE,
  LOGIN_STEP_MFA_CONNECTOR,
  LOGIN_STEP_MFA_CONNECTOR_VALIDATE_CODE,
  LOGIN_STEP_RESET_PASSWORD_EMAIL,
  LOGIN_STEP_RESET_PASSWORD_EMAIL_SENT,
  LOGIN_STEP_RESET_PASSWORD_NEW_PASSWORD,
  LOGIN_STEP_RESET_PASSWORD_EXPIRED_LINK,
];

let mfaConnectorStepTimeout;

export default function Login() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const query = useQuery();
  const currentUser = useSelector(getCurrentUser());
  const loginTokenExpired = useSelector(getLoginTokenExpired());
  const loginFailed = useSelector(getLoginFailed);
  const mfa = useSelector(getMFA);
  const token = query?.get('token');
  const matchResetPasswordRoute = useRouteMatch(ROUTES.RESET_PASSWORD);
  const isResetPasswordPage = matchResetPasswordRoute?.isExact;

  const [passwordResetFailed, setPasswordResetFailed] = useState(false);
  const formRef = useRef(null);
  const canAnimateSteps = useRef();

  const { checkTokenValidityQuery } = useAuthQueries({ token });
  const { refetch: refetchTokenValidityQuery, data } = checkTokenValidityQuery;
  const queryClient = useQueryClient();

  const { goTo, isStepActive, currentStepName, getStepIndex } = useSteps({
    steps: LOGIN_STEPS,
    initialStepNumber: isResetPasswordPage
      ? LOGIN_STEPS.indexOf(LOGIN_STEP_RESET_PASSWORD_NEW_PASSWORD)
      : LOGIN_STEPS.indexOf(LOGIN_STEP_USERNAME),
  });
  const previousCurrentStepName = usePrevious(currentStepName);

  useEffect(() => {
    if (token) {
      refetchTokenValidityQuery();
    }
  }, [token, refetchTokenValidityQuery]);

  useEffect(() => {
    if (token && data && data.tokenInvalid) {
      goTo(LOGIN_STEP_RESET_PASSWORD_EXPIRED_LINK);
      queryClient.clear();
    }
  }, [queryClient, goTo, token, data]);

  const { contextSafe } = useGSAP(
    () => {
      const animateTransition =
        canAnimateSteps.current &&
        previousCurrentStepName !== LOGIN_STEP_RESET_PASSWORD_EMAIL_SENT;

      if (!animateTransition) return;

      const isMovingBackwards =
        getStepIndex(currentStepName) < getStepIndex(previousCurrentStepName);

      const startFrom = isMovingBackwards ? '-100%' : '100%';

      gsap.from('.login-step', 0.15, { x: startFrom, opacity: 0 });
    },
    {
      scope: formRef,
      dependencies: [
        currentStepName,
        getStepIndex,
        currentStepName,
        canAnimateSteps,
      ],
    },
  );

  const mfaCodeFieldErrorMessage = useMemo(
    () => (
      <>
        <FormattedMessage
          id="loginpage.2fa.invalid_2fa_code"
          defaultMessage="Invalid two-factor authentication code."
        />
        <br />
        <FormattedMessage
          id="loginpage.2fa.please_try_again"
          defaultMessage="Please try again."
        />
      </>
    ),
    [],
  );

  const validationSchema = useMemo(() => {
    if (isStepActive(LOGIN_STEP_RESET_PASSWORD_EMAIL)) {
      return Yup.object({
        email: Yup.string()
          .required(
            intl.formatMessage({
              id: 'loginpage.resetpassword.email.required',
              defaultMessage: 'Email cannot be empty',
            }),
          )
          .email(
            intl.formatMessage({
              id: 'loginpage.resetpassword.email.invalid',
              defaultMessage:
                'Your email address is not a valid. Please contact Aibuild to reset your password.',
            }),
          ),
      });
    }

    if (isStepActive(LOGIN_STEP_RESET_PASSWORD_NEW_PASSWORD)) {
      return Yup.object({
        newPassword: Yup.string()
          .required(
            intl.formatMessage({
              id: 'loginpage.resetpassword_newpassword.newpassword.required',
              defaultMessage: 'New password field cannot be empty',
            }),
          )
          .test('password-complex-enough', '', (p) =>
            isPasswordComplexEnough(p),
          ),
        confirmNewPassword: Yup.string()
          .required(
            intl.formatMessage({
              id: 'loginpage.resetpassword_newpassword.confirmnewpassword.required',
              defaultMessage: 'Confirm password field cannot be empty',
            }),
          )
          .oneOf(
            [Yup.ref('newPassword')],
            intl.formatMessage({
              id: 'loginpage.resetpassword_newpassword.confirmnewpassword.must_match',
              defaultMessage: 'Confirm password must match new password',
            }),
          ),
      });
    }

    return Yup.object({
      username: Yup.string().required(
        intl.formatMessage({
          id: 'loginpage.login_failed.username_cannot_be_empty',
          defaultMessage: 'Username cannot be empty',
        }),
      ),
      password: Yup.string().required(
        intl.formatMessage({
          id: 'loginpage.login_failed.password_cannot_be_empty',
          defaultMessage: 'Password cannot be empty',
        }),
      ),
      mfaCode:
        isStepActive(LOGIN_STEP_MFA_CODE) ||
        isStepActive(LOGIN_STEP_MFA_CONNECTOR_VALIDATE_CODE)
          ? Yup.string()
              .required(
                intl.formatMessage({
                  id: 'loginpage.2fa.code_cannot_be_empty',
                  defaultMessage:
                    'Two-factor authentication code cannot be empty',
                }),
              )
              .length(
                MFA_CODE_LENGTH,
                intl.formatMessage({
                  id: 'loginpage.2fa.minimum_6_characters',
                  defaultMessage:
                    'Minimum 6 characters required for two-factor authentication code',
                }),
              )
          : Yup.string(),
    });
  }, [intl, isStepActive]);

  const goToStep = contextSafe((step) => {
    const animateTransition = !isStepActive(
      LOGIN_STEP_RESET_PASSWORD_EMAIL_SENT,
    );

    if (mfaConnectorStepTimeout) {
      clearTimeout(mfaConnectorStepTimeout);
    }

    if (animateTransition) {
      const isMovingForward =
        getStepIndex(step) > getStepIndex(currentStepName);

      const startFrom = isMovingForward ? '-100%' : '100%';

      gsap.to('.login-step', 0.15, {
        x: startFrom,
        opacity: 0,
        onComplete: () => goTo(step),
      });

      return;
    }

    goTo(step);
  });

  const handleGoToStep = useCallback(
    (step) => () => goToStep(step),
    [goToStep],
  );

  const handleResetPasswordClick = useCallback(
    (resetForm) => () => {
      resetForm?.({});
      setPasswordResetFailed(false);
      goToStep(LOGIN_STEP_RESET_PASSWORD_EMAIL);
      dispatch(resetLoginFailure());
    },
    [dispatch, goToStep, setPasswordResetFailed],
  );

  const navigateToLoginUserPage = useCallback(() => {
    goToStep(LOGIN_STEP_USERNAME);
    history.push(ROUTES.LOGIN);
  }, [history, goToStep]);

  const validateMFACode = useCallback(
    async (mfaCode) => {
      const {
        data: { valid },
      } = await client.post(endpoints.validateMFACode, {
        userId: currentUser?.id,
        code: mfaCode,
      });

      return valid;
    },
    [currentUser?.id],
  );

  const onFormSubmit = useCallback(
    async (
      { username, password, mfaCode, email, newPassword, confirmNewPassword },
      { setFieldValue, setSubmitting, setErrors, setFieldError },
    ) => {
      setErrors({});

      if (isStepActive(LOGIN_STEP_RESET_PASSWORD_NEW_PASSWORD)) {
        dispatch(
          resetPassword(token, newPassword, confirmNewPassword, {
            finallyCallback: () => {
              setSubmitting(false);
            },
            successCallback: () => {
              navigateToLoginUserPage();
            },
          }),
        );

        return new Promise(() => {});
      }

      if (isStepActive(LOGIN_STEP_RESET_PASSWORD_EMAIL)) {
        setPasswordResetFailed(false);

        dispatch(
          createResetPasswordToken(email, {
            failureCallback: () => {
              setPasswordResetFailed(true);
            },
            successCallback: () => {
              goToStep(LOGIN_STEP_RESET_PASSWORD_EMAIL_SENT);
            },
            finallyCallback: () => {
              setSubmitting(false);
            },
          }),
        );

        return new Promise(() => {});
      }

      if (isStepActive(LOGIN_STEP_MFA_CONNECTOR_VALIDATE_CODE)) {
        const isMfaCodeValid = await validateMFACode(mfaCode);

        if (!isMfaCodeValid) {
          setFieldError(MFA_CODE_FIELD_NAME, mfaCodeFieldErrorMessage);
          setSubmitting(false);

          return;
        }
      }

      dispatch(
        login(username, password, mfaCode, {
          failureCallback: () => {
            setFieldValue(MFA_CODE_FIELD_NAME);
            setErrors({ username: 'error', password: 'error' });
            setSubmitting(false);
          },
          mfaCodeStepFailureCallback: () => {
            setFieldError(MFA_CODE_FIELD_NAME, mfaCodeFieldErrorMessage);
            setSubmitting(false);
          },
          switchToMFACodeStepCallback: () => {
            goToStep(LOGIN_STEP_MFA_CODE);
            setSubmitting(false);
          },
          switchToMFAConnectorStepCallback: () => {
            goToStep(LOGIN_STEP_MFA_CONNECTOR);
            setSubmitting(false);
          },
        }),
      );

      return new Promise(() => {});
    },
    [
      dispatch,
      token,
      validateMFACode,
      mfaCodeFieldErrorMessage,
      isStepActive,
      goToStep,
      navigateToLoginUserPage,
    ],
  );

  const formInitialValues = useMemo(
    () => ({
      username: currentUser?.username,
      password: '',
      mfaCode: '',
      email: '',
      newPassword: '',
      confirmNewPassword: '',
    }),
    [currentUser?.username],
  );

  useEffect(() => {
    const skip = !(
      isStepActive(LOGIN_STEP_MFA_CONNECTOR) ||
      isStepActive(LOGIN_STEP_MFA_CONNECTOR_VALIDATE_CODE)
    );

    if (skip) return;

    mfaConnectorStepTimeout = setTimeout(() => {
      goToStep(LOGIN_STEP_USERNAME);
    }, MFA_CONNECTOR_EXPIRATION_TIME);
  }, [dispatch, isStepActive, goToStep]);

  useEffect(() => {
    canAnimateSteps.current = true;
  }, []);

  return (
    <LoginLayout>
      <Header>
        <Logo />
      </Header>

      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          resetForm,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <Form ref={formRef} onSubmit={handleSubmit}>
              {isStepActive(LOGIN_STEP_USERNAME) && (
                <LoginUserName
                  className="login-step"
                  handleFieldBlur={handleBlur}
                  handleFieldChange={handleChange}
                  isSubmitting={isSubmitting}
                  loginFailed={loginFailed}
                  loginTokenExpired={loginTokenExpired}
                  password={values?.password}
                  passwordError={!!errors?.password}
                  passwordErrorMessage={errors?.password}
                  passwordTouched={touched?.password}
                  username={values?.username}
                  usernameError={!!errors?.username}
                  usernameErrorMessage={errors?.username}
                  usernameTouched={touched?.username}
                />
              )}

              {(isStepActive(LOGIN_STEP_MFA_CODE) ||
                isStepActive(LOGIN_STEP_MFA_CONNECTOR_VALIDATE_CODE)) && (
                <Login2FACode
                  className="login-step"
                  handleFieldBlur={handleBlur}
                  handleFieldChange={handleChange}
                  handleGoBack={handleGoToStep(LOGIN_STEP_USERNAME)}
                  isSubmitting={isSubmitting}
                  loginFailed={loginFailed}
                  mfaCode={values?.mfaCode}
                  mfaCodeError={!!errors?.mfaCode}
                  mfaCodeErrorMessage={errors?.mfaCode}
                  mfaCodeTouched={touched?.mfaCode}
                />
              )}

              {isStepActive(LOGIN_STEP_MFA_CONNECTOR) && (
                <Login2FAConnector
                  className="login-step"
                  handleGoBack={handleGoToStep(LOGIN_STEP_USERNAME)}
                  handleSubmit={handleGoToStep(
                    LOGIN_STEP_MFA_CONNECTOR_VALIDATE_CODE,
                  )}
                  isSubmitting={isSubmitting}
                  mfaSetupCode={mfa?.encryptedSecret}
                  qrCodeUrl={mfa?.qrCodeUrl}
                />
              )}

              {isStepActive(LOGIN_STEP_RESET_PASSWORD_EMAIL) && (
                <LoginResetPasswordEmail
                  className="login-step"
                  email={values?.email}
                  emailError={!!errors?.email}
                  emailErrorMessage={errors?.email}
                  emailSent={isStepActive(LOGIN_STEP_RESET_PASSWORD_EMAIL_SENT)}
                  emailTouched={touched?.email}
                  handleFieldBlur={handleBlur}
                  handleFieldChange={handleChange}
                  handleGoBack={handleGoToStep(LOGIN_STEP_USERNAME)}
                  isSubmitting={isSubmitting}
                  resetFailed={passwordResetFailed}
                />
              )}

              {isStepActive(LOGIN_STEP_RESET_PASSWORD_EMAIL_SENT) && (
                <LoginResetPasswordEmail
                  className="login-step"
                  email={values?.email}
                  emailError={!!errors?.email}
                  emailErrorMessage={errors?.email}
                  emailSent
                  emailTouched={touched?.email}
                  handleFieldBlur={handleBlur}
                  handleFieldChange={handleChange}
                  handleGoBack={handleGoToStep(LOGIN_STEP_USERNAME)}
                  isSubmitting={isSubmitting}
                  resetFailed={passwordResetFailed}
                />
              )}

              {isStepActive(LOGIN_STEP_RESET_PASSWORD_EXPIRED_LINK) && (
                <LoginResetPasswordExpiredLink
                  className="reset-password-step"
                  handleGoBack={handleGoToStep(LOGIN_STEP_USERNAME)}
                  handleGoToResetPassword={handleGoToStep(
                    LOGIN_STEP_RESET_PASSWORD_EMAIL,
                  )}
                />
              )}

              {isStepActive(LOGIN_STEP_RESET_PASSWORD_NEW_PASSWORD) && (
                <LoginResetPassword
                  className="login-step"
                  confirmNewPassword={values?.confirmNewPassword}
                  confirmNewPasswordError={!!errors?.confirmNewPassword}
                  confirmNewPasswordErrorMessage={errors?.confirmNewPassword}
                  confirmNewPasswordTouched={touched?.confirmNewPassword}
                  handleFieldBlur={handleBlur}
                  handleFieldChange={handleChange}
                  isSubmitting={isSubmitting}
                  newPassword={values?.newPassword}
                  newPasswordError={!!errors?.newPassword}
                  newPasswordErrorMessage={errors?.newPassword}
                  newPasswordTouched={touched?.newPassword}
                  resetPasswordButtonLabel={intl.formatMessage({
                    id: 'loginpage.resetpassword_newpassword.reset_password',
                    defaultMessage: 'Reset Password',
                  })}
                />
              )}
            </Form>

            {isStepActive(LOGIN_STEP_USERNAME) && (
              <Footer>
                <Button
                  disabled={isSubmitting}
                  variant={BUTTON_VARIANT_TEXT}
                  onClick={handleResetPasswordClick(resetForm)}
                >
                  <FormattedMessage
                    id="loginpage.login.forgotpassword.button"
                    defaultMessage="Forgot password?"
                  />
                </Button>
              </Footer>
            )}
          </>
        )}
      </Formik>
    </LoginLayout>
  );
}

import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { differenceBy, isEmpty, isEqual, isUndefined } from 'lodash';
import { compare } from 'compare-versions';
import { yupResolver } from '@hookform/resolvers/yup';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import usePrevious from '@hooks/usePrevious';
import { isValueInValidRange } from '@actions/conceptActions';
import { OperatorInputTypes } from '@constants/operatorInputTypes';
import {
  DROP_DOWN_DATA_FIELD_TYPES,
  DROP_DOWN_FILE_FIELD_TYPES,
  DROP_DOWN_INPUT_FIELD_TYPES,
} from '@constants/operatorFieldTypes';
import { getInvalidInputHint, isDecimal, isInteger } from '@utils/validation';
import { resetIsSubmitted, selectIsSubmitted } from '@reducers/workflowSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function useWorkflowForm() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { getSelectedWorkflow, getSelectedWorkflowOperators } = useWorkflow();
  const {
    getDefaultOperators,
    getOperatorVisibleInputs,
    getWorkflowHiddenInputNames,
    getOperatorFieldOptions,
    getOperatorInputs,
    calculateWorkflowHiddenInputs,
  } = useOperator();
  const isCustomSubmitted = useSelector(selectIsSubmitted);

  const defaultOperators = useMemo(
    () => getDefaultOperators(),
    [getDefaultOperators],
  );

  const workflow = getSelectedWorkflow();
  const workflowVersion = workflow.version;
  const previousWorkflowVersion = usePrevious(workflowVersion);
  const workflowOperators = getSelectedWorkflowOperators();
  const previousWorkflowOperator = usePrevious(workflowOperators);

  const workflowHiddenInputNames = useMemo(
    () => getWorkflowHiddenInputNames(),
    [getWorkflowHiddenInputNames],
  );

  const generateOperatorDefaultValues = useCallback(
    (operator = {}) => {
      const availableValues = getOperatorInputs(operator);

      return availableValues?.reduce(
        (acc, field) => ({
          ...acc,
          [field?.id]: field?.value,
        }),
        {},
      );
    },
    [getOperatorInputs],
  );

  const initialOperatorsValues = useMemo(
    () =>
      workflowOperators?.reduce(
        (acc, operator) => ({
          ...acc,
          [operator.id]: generateOperatorDefaultValues(operator),
        }),
        {},
      ),
    [workflowOperators, generateOperatorDefaultValues],
  );

  const generateOperatorValidationSchema = useCallback(
    (operator = {}) => {
      const availableValues = getOperatorVisibleInputs(operator);
      const operatorHiddenInputNames = workflowHiddenInputNames[operator?.id];

      const validation = availableValues?.reduce((acc, field) => {
        const isHiddenInput = operatorHiddenInputNames?.includes(field.name);

        if (isHiddenInput) {
          return acc;
        }

        const decimalType = field?.type === OperatorInputTypes.DECIMAL;
        const integerType = field?.type === OperatorInputTypes.INTEGER;
        const selectType = [
          OperatorInputTypes.SELECTION,
          OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES,
        ].includes(field?.type);

        const dependentInputType = [
          ...DROP_DOWN_INPUT_FIELD_TYPES,
          ...DROP_DOWN_FILE_FIELD_TYPES,
          ...DROP_DOWN_DATA_FIELD_TYPES,
        ].includes(field?.type);

        let nextValidation = { ...acc };

        const rangeValidationError = () =>
          getInvalidInputHint(defaultOperators, operator, field?.name);

        const rangeValidation = (value) =>
          isValueInValidRange(defaultOperators, operator, field, value);

        if (decimalType) {
          nextValidation = {
            ...nextValidation,
            [field?.id]: yup
              .string()
              .test(
                'is-decimal',
                intl.formatMessage({
                  id: 'operator.fields.validation.must_be_integer_or_decimal',
                  defaultMessage: 'Must be integer or decimal',
                }),
                isDecimal,
              )
              .test(
                'is-in-valid-range',
                rangeValidationError() ||
                  intl.formatMessage({
                    id: 'operator.fields.validation.invalid_range',
                    defaultMessage: 'Invalid range',
                  }),
                rangeValidation,
              )
              .required(
                intl.formatMessage({
                  id: 'operator.fields.validation.can_not_be_empty',
                  defaultMessage: 'Can not be empty',
                }),
              ),
          };
        }

        if (integerType) {
          nextValidation = {
            ...nextValidation,
            [field?.id]: yup
              .string()
              .test(
                'is-integer',
                intl.formatMessage({
                  id: 'operator.fields.validation.must_be_integer',
                  defaultMessage: 'Must be integer',
                }),
                isInteger,
              )
              .test(
                'is-in-valid-range',
                rangeValidationError() || 'Invalid range',
                rangeValidation,
              )
              .required(
                intl.formatMessage({
                  id: 'operator.fields.validation.can_not_be_empty',
                  defaultMessage: 'Can not be empty',
                }),
              ),
          };
        }

        if (dependentInputType) {
          nextValidation = {
            ...nextValidation,
            [field?.id]: yup.string().required(
              intl.formatMessage({
                id: 'operator.fields.validation.can_not_be_empty',
                defaultMessage: 'Can not be empty',
              }),
            ),
          };
        }

        if (selectType) {
          nextValidation = {
            ...nextValidation,
            [field?.id]: yup.string().required(
              intl.formatMessage({
                id: 'operator.fields.validation.can_not_be_empty',
                defaultMessage: 'Can not be empty',
              }),
            ),
          };
        }

        return nextValidation;
      }, {});

      return yup.object(validation);
    },
    [
      intl,
      getOperatorVisibleInputs,
      workflowHiddenInputNames,
      defaultOperators,
    ],
  );

  const validationSchema = useMemo(
    () =>
      yup
        .object(
          workflowOperators?.reduce(
            (acc, operator) => ({
              ...acc,
              [operator.id]: generateOperatorValidationSchema(operator),
            }),
            {},
          ),
        )
        .required(),
    [workflowOperators, generateOperatorValidationSchema],
  );

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialOperatorsValues,
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const {
    formState: { isSubmitSuccessful },
    getValues,
    setValue,
    reset,
    trigger,
  } = form;

  const getResetFieldNamesWithNoSelectedOption = useCallback(
    (operators = [], formOperatorsValues = {}) => {
      const selectInputTypes = [
        ...DROP_DOWN_INPUT_FIELD_TYPES,
        ...DROP_DOWN_FILE_FIELD_TYPES,
      ];

      const nextFormOperatorsValues = operators?.reduce((acc, operator) => {
        if (operator.reordering) return acc;

        const operatorFieldNamesToReset = (operator?.values || [])?.reduce(
          (acc, inputField) => {
            const isSelectType =
              inputField.isinput && selectInputTypes.includes(inputField?.type);
            const currentFieldValue =
              formOperatorsValues?.[operator?.id]?.[inputField?.id];

            if (!isSelectType || !currentFieldValue) return acc;

            const inputOptions = getOperatorFieldOptions(operator, inputField);
            const optionExists = inputOptions?.some(
              ({ value }) => value === currentFieldValue,
            );

            if (optionExists) return acc;

            const fieldName = `${operator?.id}.${inputField?.id}`;

            acc.push(fieldName);

            return acc;
          },
          [],
        );

        if (isEmpty(operatorFieldNamesToReset)) return acc;

        return [...acc, ...operatorFieldNamesToReset];
      }, []);

      return nextFormOperatorsValues;
    },
    [getOperatorFieldOptions],
  );

  const getFieldNamesToAutoFill = useCallback(
    (operators = []) => {
      const autoSelectInputTypes = [
        ...DROP_DOWN_INPUT_FIELD_TYPES,
        ...DROP_DOWN_FILE_FIELD_TYPES,
      ];

      const workflowHiddenInputs = calculateWorkflowHiddenInputs({
        normalizeOutput: true,
      });

      const fieldNames = operators?.reduce((acc, operator) => {
        const operatorVisibileInputs = operator?.values.filter(
          (inputField) => !workflowHiddenInputs?.[inputField.id],
        );

        operatorVisibileInputs?.forEach((inputField) => {
          const isFileType =
            inputField.isinput &&
            autoSelectInputTypes.includes(inputField?.type);

          if (isFileType) {
            const inputOptions = getOperatorFieldOptions(operator, inputField);
            const singleOptionAvailable = inputOptions?.length === 1;

            if (singleOptionAvailable) {
              const [option] = inputOptions;
              const fieldName = `${operator?.id}.${inputField?.id}`;

              acc[fieldName] = option?.value;
            }
          }
        });

        return acc;
      }, []);

      return fieldNames;
    },
    [getOperatorFieldOptions, calculateWorkflowHiddenInputs],
  );

  const getIsTemplateExploded = useCallback(() => {
    if (
      isUndefined(previousWorkflowOperator) ||
      isEqual(previousWorkflowOperator, workflowOperators)
    ) {
      return;
    }

    const filterTemplateOperators = ({ templateId }) => !!templateId;
    const previousTemplateOperators =
      previousWorkflowOperator?.filter?.(filterTemplateOperators) || [];
    const currentTemplateOperators =
      workflowOperators?.filter?.(filterTemplateOperators) || [];

    const templateExploaded =
      currentTemplateOperators.length < previousTemplateOperators.length &&
      workflowOperators?.length >= previousWorkflowOperator?.length;

    return templateExploaded;
  }, [previousWorkflowOperator, workflowOperators]);

  const observeOperatorsUpdates = useCallback(() => {
    const updateTypes = {};

    const operatorsHasBeenUpdated =
      !isUndefined(previousWorkflowOperator) &&
      !isEqual(workflowOperators, previousWorkflowOperator);

    const workflowHasBeenUpgraded =
      !isUndefined(previousWorkflowVersion) &&
      compare(workflowVersion, previousWorkflowVersion, '>');

    if (workflowHasBeenUpgraded) {
      return {
        ...updateTypes,
        upgraded: true,
      };
    }

    if (!operatorsHasBeenUpdated) {
      return updateTypes;
    }

    const addedOperators = differenceBy(
      workflowOperators,
      previousWorkflowOperator,
      'id',
    );
    const added = !isEmpty(addedOperators);

    const removedOperators = differenceBy(
      previousWorkflowOperator,
      workflowOperators,
      'id',
    );
    const removed = !isEmpty(removedOperators);

    const reordered =
      !added &&
      !removed &&
      workflowOperators.some(
        ({ id }, index) => previousWorkflowOperator[index]?.id !== id,
      );

    return {
      ...updateTypes,
      reordered,
      added,
      removed,
      exploded: getIsTemplateExploded(),
      addedOperators,
      removedOperators,
    };
  }, [
    workflowOperators,
    previousWorkflowOperator,
    getIsTemplateExploded,
    workflowVersion,
    previousWorkflowVersion,
  ]);

  useEffect(() => {
    const { reordered, removed, added, addedOperators, exploded, upgraded } =
      observeOperatorsUpdates();

    if (reordered || removed) {
      const fieldNamesToReset = getResetFieldNamesWithNoSelectedOption(
        workflowOperators,
        getValues(),
      );

      fieldNamesToReset.forEach((fieldName) =>
        setValue(fieldName, '', { shouldTouch: true }),
      );
    }

    if (added) {
      reset(initialOperatorsValues, {
        keepDirtyValues: true,
        keepErrors: true,
        keepTouched: true,
        keepIsValid: true,
      });

      const fieldNamesToAutoFill = getFieldNamesToAutoFill(addedOperators);

      Object.entries(fieldNamesToAutoFill).forEach(([fieldName, fieldValue]) =>
        setValue(fieldName, fieldValue, { shouldDirty: true }),
      );
    }

    if (exploded || upgraded) {
      reset(initialOperatorsValues, {
        keepDirtyValues: true,
        keepErrors: true,
        keepTouched: true,
        keepIsValid: true,
      });
    }
  }, [
    getFieldNamesToAutoFill,
    getOperatorFieldOptions,
    getResetFieldNamesWithNoSelectedOption,
    getValues,
    initialOperatorsValues,
    observeOperatorsUpdates,
    reset,
    setValue,
    workflowOperators,
  ]);

  useEffect(() => {
    trigger();
  }, [trigger, validationSchema]);

  useEffect(() => {
    if (isSubmitSuccessful || isCustomSubmitted) {
      reset(initialOperatorsValues);
      dispatch(resetIsSubmitted());
    }
  }, [
    isSubmitSuccessful,
    initialOperatorsValues,
    reset,
    dispatch,
    isCustomSubmitted,
  ]);

  return form;
}

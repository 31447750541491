import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import useProjectMutations from '@hooks/projects/useProjectMutations';
import { getCurrentUser } from '@selectors/loginSelectors';
import { ModalDataTypes } from '@app/constants/modalDataTypes';
import useSnackbar from '@hooks/useSnackbar';

export default function useProjectList() {
  const intl = useIntl();
  const user = useSelector(getCurrentUser());
  const { updateProjectMutation, deleteProjectMutation } =
    useProjectMutations();
  const { showDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const getProjectDropDownMenuActions = useCallback(
    (project = {}) => {
      const isUserInOrganization =
        project?.organization === user?.organizationId;
      const isPublic = project?.publicAccess;

      if (!isUserInOrganization) return [];

      const actions = [
        {
          leadingIconName: 'edit_0',
          label: intl.formatMessage({
            id: 'general.rename',
            defaultMessage: 'Rename',
          }),
          onClick: () =>
            showDialog(ModalDataTypes.EDIT_PROJECT, {
              projectId: project.id,
              projectName: project.name,
              projectIsPublic: isPublic,
              userOrganization: user?.organizationId,
            }),
        },
        {
          leadingIconName: 'language_0',
          label: intl.formatMessage({
            id: 'general.public',
            defaultMessage: 'Public',
          }),
          endingButton: {
            type: 'switch',
            enabled: isPublic,
            onChange: () => {
              updateProjectMutation.mutate({
                id: project.id,
                name: project.name,
                organization: user?.organizationId,
                public: !isPublic,
              });
            },
          },
        },
        {
          leadingIconName: 'delete_0',
          label: intl.formatMessage({
            id: 'general.delete',
            defaultMessage: 'Delete',
          }),
          color: 'error',
          onClick: () => {
            showDialog(ModalDataTypes.PROMPT, {
              title: intl.formatMessage({
                id: 'deleteproject.dialog.title',
                defaultMessage: 'Delete Project',
              }),
              subtitle: intl.formatMessage(
                {
                  id: 'deleteproject.dialog.subtitle',
                  defaultMessage:
                    '<b>{projectName}</b> including its workflows and files will be deleted permanently. <br></br><br></br> Are you sure you want to proceed?',
                },
                {
                  projectName: project?.name,
                  b: (str) => <b>{str}</b>,
                  br: () => <br />,
                },
              ),
              onPrimaryButtonClick: async () => {
                try {
                  await deleteProjectMutation.mutateAsync(project?.id);
                  showSnackbar({
                    text: intl.formatMessage({
                      id: 'deleteproject.snackbar.label',
                      defaultMessage: ' Project successfully deleted',
                    }),
                  });
                } catch (_) {
                  //error
                }
              },
            });
          },
        },
      ];

      return actions;
    },
    [
      showSnackbar,
      intl,
      user?.organizationId,
      showDialog,
      updateProjectMutation,
      deleteProjectMutation,
    ],
  );

  return {
    getProjectDropDownMenuActions,
  };
}

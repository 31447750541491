import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty, concat } from 'lodash';
import { fileQueryKeys } from '@hooks/files/useFileQueries';
import {
  getFrozenOutputIds,
  getFrozenOutputs,
  getSelectedOperatorOutput,
  getSelectedOperatorOutputId,
} from '@selectors/conceptSelectors';
import {
  exitToolpathSimulation,
  selectOperatorOutput,
  unfreezeOperatorOutput,
} from '@actions/conceptActions';
import { GEO_OUTPUT_TYPES } from '@constants/operatorFieldTypes';
import { BREP_OUTPUT_NAME } from '@app/constants/canvasSelection';

export default function useFile() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const frozenOutputIds = useSelector(getFrozenOutputIds());
  const frozenOutputs = useSelector(getFrozenOutputs());
  const selectedOperatorOutputId = useSelector(getSelectedOperatorOutputId());
  const selectedOperatorOutput = useSelector(getSelectedOperatorOutput());

  const getDesign = useCallback(
    (designId) => queryClient.getQueryData(fileQueryKeys.design(designId)),
    [queryClient],
  );

  const getProjectFiles = useCallback(
    (projectId) =>
      queryClient.getQueryData(fileQueryKeys.projectFiles(projectId)),
    [queryClient],
  );

  const addProjectFile = useCallback(
    (projectId, file) => {
      const projectFiles = queryClient.getQueryData(
        fileQueryKeys.projectFiles(projectId),
      );
      const nextProjectFiles = concat(projectFiles, file);

      queryClient.setQueryData(
        fileQueryKeys.projectFiles(projectId),
        nextProjectFiles,
      );
    },
    [queryClient],
  );

  const getProjectFile = useCallback(
    (projectId, fileId) =>
      queryClient
        .getQueryData(fileQueryKeys.projectFiles(projectId))
        ?.find(({ id }) => id === fileId),
    [queryClient],
  );

  // File with the design data
  const getGeometry = useCallback(
    (geometryId, printerId) =>
      queryClient.getQueryData(fileQueryKeys.geometry(geometryId, printerId)),
    [queryClient],
  );

  const getGeometries = useCallback(
    (geometryIds, printerId) =>
      queryClient.getQueryState(
        fileQueryKeys.geometries(geometryIds, printerId),
      ),
    [queryClient],
  );

  const getCachedGeometries = useCallback(
    (geometryIds, printerId) => {
      const cachedGeometriesQuery = queryClient.getQueriesData({
        predicate: (query) =>
          query?.queryKey?.includes?.('geometry') &&
          geometryIds?.includes(query.queryKey?.[2]) &&
          query?.queryKey?.[3] === printerId,
      });

      const serializedGeometries = cachedGeometriesQuery?.reduce(
        (acc, [_, queryData]) => {
          if (!queryData?.id) {
            return acc;
          }

          return {
            ...acc,
            [queryData?.id]: queryData,
          };
        },
        {},
      );

      return serializedGeometries;
    },
    [queryClient],
  );

  const getSelectedDesignIds = useCallback(
    (workflowOperators = []) =>
      workflowOperators.reduce(
        (acc, operator) => {
          const { values, id } = operator;

          const hasBrepOutput = !!values.find(
            (val) => val.name === BREP_OUTPUT_NAME,
          )?.value;

          values.forEach((input) => {
            if (GEO_OUTPUT_TYPES.includes(input?.type)) {
              const inputId = input.id;
              const inputValue = input.value;
              const isFrozen = frozenOutputIds.includes(inputId);
              const isSelected = inputId === selectedOperatorOutputId;

              if (isFrozen || isSelected) {
                acc.inputsGeometryIds[inputId] = inputValue;
                acc.designIdOperators[inputValue] = id;
                if (hasBrepOutput) {
                  acc.canvasSelectionDesigns.push(inputValue);
                }
              }

              if (isFrozen) {
                acc.frozenDesignIds.push(inputValue);
                acc.designIds.push(inputValue);
              } else if (isSelected) {
                acc.selectedDesignIds.push(inputValue);
                acc.designIds.unshift(inputValue);
              }
            }
          });

          return acc;
        },
        {
          frozenDesignIds: [],
          selectedDesignIds: [],
          designIds: [],
          inputsGeometryIds: {},
          canvasSelectionDesigns: [],
          designIdOperators: {},
        },
      ),
    [frozenOutputIds, selectedOperatorOutputId],
  );

  const removeGeometriesQuery = useCallback(
    (geometryIds) =>
      queryClient.removeQueries({
        predicate: (query) => {
          const isGeometryQuery = query?.queryKey?.includes?.('geometry');

          if (!isGeometryQuery) return false;

          const queryGeometryId = query.queryKey?.[2];

          if (geometryIds) {
            return geometryIds?.includes?.(queryGeometryId);
          }

          return true;
        },
      }),
    [queryClient],
  );

  const refetchProjectFiles = useCallback(
    (projectId) => {
      return queryClient.refetchQueries({
        queryKey: fileQueryKeys.projectFiles(projectId),
      });
    },
    [queryClient],
  );

  const removeNotExistingGeometriesQuery = useCallback(
    (workflowOperators) =>
      queryClient.removeQueries({
        predicate: (query) => {
          const isGeometryQuery = query?.queryKey?.includes?.('geometry');

          if (!isGeometryQuery) return false;

          const queryGeometryId = query.queryKey?.[2];

          if (!queryGeometryId || !workflowOperators) return false;

          const stringifiedOperators = JSON.stringify(workflowOperators);
          const isGeometryExists =
            stringifiedOperators?.includes?.(queryGeometryId);

          if (!isGeometryExists) {
            const outputsToUnfreeze = frozenOutputs
              ?.filter?.(({ value }) => value === queryGeometryId)
              .map(({ id }) => id);
            const noOperatorFound =
              selectedOperatorOutput &&
              !workflowOperators?.find?.(
                ({ id }) => id === selectedOperatorOutput?.operatorId,
              );
            const shouldUnselectOperator = noOperatorFound;

            if (!isEmpty(outputsToUnfreeze)) {
              dispatch(unfreezeOperatorOutput(outputsToUnfreeze));
            }

            if (shouldUnselectOperator) {
              dispatch(exitToolpathSimulation());
              dispatch(selectOperatorOutput(null));
            }

            return true;
          }

          return false;
        },
      }),
    [queryClient, frozenOutputs, selectedOperatorOutput, dispatch],
  );

  const removeGeometryQuery = useCallback(
    (geometryId) =>
      queryClient.removeQueries({
        predicate: (query) =>
          query?.queryKey?.includes?.('geometry') &&
          geometryId === query.queryKey?.[2],
      }),
    [queryClient],
  );

  const invalidateProjectFielsQuery = useCallback(
    (projectId) =>
      queryClient.invalidateQueries({
        queryKey: fileQueryKeys.projectFiles(projectId),
        refetchType: 'all',
      }),
    [queryClient],
  );

  return {
    addProjectFile,
    getCachedGeometries,
    getDesign,
    getGeometries,
    getGeometry,
    getProjectFile,
    getProjectFiles,
    getSelectedDesignIds,
    invalidateProjectFielsQuery,
    refetchProjectFiles,
    removeGeometriesQuery,
    removeGeometryQuery,
    removeNotExistingGeometriesQuery,
  };
}

import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';
import React from 'react';

const PostProcessorKUKA = ({ isSubmitting }) => {
  const { values, setFieldValue } = useFormikContext();
  const intl = useIntl();
  return (
    <>
      <FormikField
        component={PostProcessorSetting}
        dataTestId={`printer-custom-post-processor__setting-isToolExternalAxis`}
        label={intl.formatMessage({
          id: 'printers.postprocessor.isToolExternalAxis.label',
          defaultMessage: 'Use Tool As External Axis',
        })}
        renderAsCheckbox
        name={'isToolExternalAxis'}
        disabled={isSubmitting}
        checked={Boolean(values['isToolExternalAxis'])}
        onChange={() => {
          setFieldValue('isToolExternalAxis', !values['isToolExternalAxis']);
        }}
        noPaddingBottom
      />

      {values['isToolExternalAxis'] && (
        <FormikField
          component={PostProcessorSetting}
          dataTestId={`printer-custom-post-processor__setting-toolExternalAxisId`}
          name={'toolExternalAxisId'}
          label={intl.formatMessage({
            id: 'printers.postprocessor.toolExternalAxisId.label',
            defaultMessage: 'Tool External Axis ID',
          })}
          placeholder={intl.formatMessage({
            id: 'printers.postprocessor.toolExternalAxisId.placeholder',
            defaultMessage: 'Enter ID',
          })}
          disabled={isSubmitting}
          type={'number'}
          renderAsInput
        />
      )}
    </>
  );
};

PostProcessorKUKA.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorKUKA;

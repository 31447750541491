import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isUndefined } from 'lodash';
import { workflowQueryKeys } from '@hooks/workflows/useWorkflowQueries';
import client from '@api/client';
import endpoints from '@api/endpoints';
import { useCallback } from 'react';

export const workflowMutationKeys = {
  createWorkflow: ['createWorkflow'],
  updateWorkflowMetadata: ['updateWorkflowMetadata'],
  deleteWorkflow: ['deleteWorkflow'],
  updateWorkflow: ['showLoader', 'updateWorkflow'],
  updateWorkflowValues: ['showLoader', 'updateWorkflowValues'],
  computeWorkflow: ['computeWorkflow'],
  cancelComputeWorkflow: ['showLoader', 'computeWorkflow'],
  updateWorkflowMaterial: ['showLoader', 'updateWorkflowMaterial'],
  updateWorkflowNozzle: ['showLoader', 'updateWorkflowNozzle'],
  updateWorkflowPrinterConfig: ['showLoader', 'updateWorkflowPrinterConfig'],
};

export default function useWorkflowMutations() {
  const queryClient = useQueryClient();

  const updateLocalWorkflow = useCallback(
    (updatedWorkflow, computing) => {
      const updater = () => {
        if (isUndefined(computing)) {
          return updatedWorkflow;
        }

        return {
          ...updatedWorkflow,
          computing,
        };
      };

      queryClient.setQueryData(
        workflowQueryKeys.workflow(updatedWorkflow?.id),
        updater,
      );
    },
    [queryClient],
  );

  const createWorkflowMutation = useMutation({
    mutationKey: workflowMutationKeys.createWorkflow,
    mutationFn: (workflow = {}) =>
      client
        .post(endpoints.concepts, {
          name: workflow?.name?.trim(),
          printerId: workflow?.printerId,
          operators: workflow?.operators,
          workspaceId: workflow?.workspaceId,
          nozzleId: workflow?.nozzleId,
          materialId: workflow?.materialId,
        })
        .then((res) => res.data),
    onSuccess: (addedWorkflow, workflowMutation) => {
      const updater = (data) => {
        return data ? [...data, addedWorkflow] : [addedWorkflow];
      };

      queryClient.setQueryData(workflowQueryKeys.recentWorkflows, updater);
      queryClient.setQueryData(
        workflowQueryKeys.projectWorkflows(workflowMutation?.workspaceId),
        updater,
      );
    },
  });

  const updateWorkflowMetadataMutation = useMutation({
    mutationKey: workflowMutationKeys.updateWorkflowMetadata,
    mutationFn: (workflow = {}) =>
      client
        .patch(endpoints.conceptMetadata.replace(':conceptId', workflow?.id), {
          updatedName: workflow?.name?.trim(),
          publicAccess: workflow?.public,
        })
        .then((res) => res.data),
    onSuccess: (_, workflowMutation) => {
      const updateWorkflow = (workflow = {}) => {
        if (workflow?.id === workflowMutation?.id) {
          return {
            ...workflow,
            name: workflowMutation?.name?.trim(),
            public: workflowMutation?.public,
          };
        }

        return workflow;
      };
      const updater = (data) => {
        return data ? data?.map(updateWorkflow) : undefined;
      };

      queryClient.setQueryData(
        workflowQueryKeys.workflow(workflowMutation?.id),
        updateWorkflow,
      );
      queryClient.setQueryData(workflowQueryKeys.recentWorkflows, updater);
      queryClient.setQueryData(
        workflowQueryKeys.projectWorkflows(workflowMutation?.projectId),
        updater,
      );
    },
  });

  const deleteWorkflowMutation = useMutation({
    mutationKey: workflowMutationKeys.deleteWorkflow,
    mutationFn: (workflow) =>
      client
        .delete(endpoints.concept.replace(':conceptId', workflow?.id))
        .then((res) => res.data),
    onSuccess: (_, workflowMutation) => {
      const updater = (data) => {
        return data
          ? data?.filter((workflow) => workflow.id !== workflowMutation?.id)
          : undefined;
      };

      queryClient.setQueryData(workflowQueryKeys.recentWorkflows, updater);
      queryClient.setQueryData(
        workflowQueryKeys.projectWorkflows(workflowMutation?.projectId),
        updater,
      );
    },
  });

  const updateWorkflowMutation = useMutation({
    mutationKey: workflowMutationKeys.updateWorkflow,
    mutationFn: ({ workflowId, workflow }) =>
      client
        .put(endpoints.concept.replace(':conceptId', workflowId), workflow)
        .then((res) => res.data),
    onSuccess: (updatedWorkflow, { computing }) => {
      updateLocalWorkflow(updatedWorkflow, computing);
    },
  });

  const updateWorkflowValuesMutation = useMutation({
    mutationKey: workflowMutationKeys.updateWorkflow,
    mutationFn: ({ workflowId, updatedValues }) =>
      client
        .patch(
          endpoints.workflowValues.replace(':workflowId', workflowId),
          updatedValues,
        )
        .then((res) => res.data),
    onSuccess: (updatedWorkflow, { computing }) => {
      updateLocalWorkflow(updatedWorkflow, computing);
    },
  });

  const computeWorkflowMutation = useMutation({
    mutationKey: workflowMutationKeys.computeWorkflow,
    mutationFn: ({
      workflowId,
      operatorId,
      hiddenInputs = [],
      computeAll = false,
    }) =>
      client
        .put(endpoints.runConcept.replace(':conceptId', workflowId), {
          hiddenInputs,
          operatorId,
          computeAll,
        })
        .then((res) => res.data),
    onSuccess: (_, { workflowId }) => {
      const updater = (data) => {
        return {
          ...data,
          computing: true,
        };
      };

      queryClient.setQueryData(workflowQueryKeys.workflow(workflowId), updater);
    },
  });

  const cancelComputeWorkflowMutation = useMutation({
    mutationKey: workflowMutationKeys.cancelComputeWorkflow,
    mutationFn: ({ workflowId }) =>
      client
        .delete(endpoints.runConcept.replace(':conceptId', workflowId))
        .then((res) => res.data),
    onSuccess: (_, { workflowId }) => {
      const updater = (data) => {
        return {
          ...data,
          computing: false,
        };
      };

      queryClient.setQueryData(workflowQueryKeys.workflow(workflowId), updater);
    },
  });

  const updateWorkflowMaterialMutation = useMutation({
    mutationKey: workflowMutationKeys.updateWorkflowMaterial,
    mutationFn: ({ workflowId, printerId, materialId }) =>
      client
        .put(
          endpoints.conceptMaterialUpdate.replace(':conceptId', workflowId),
          null,
          {
            params: {
              printerId,
              materialId,
            },
          },
        )
        .then((res) => res.data),
    onSuccess: (updatedWorkflow, { workflowId }) => {
      queryClient.setQueryData(
        workflowQueryKeys.workflow(workflowId),
        updatedWorkflow,
      );
    },
  });

  const updateWorkflowNozzleMutation = useMutation({
    mutationKey: workflowMutationKeys.updateWorkflowNozzle,
    mutationFn: ({ workflowId, printerId, nozzleId }) =>
      client
        .put(
          endpoints.conceptNozzleUpdate.replace(':conceptId', workflowId),
          null,
          {
            params: {
              printerId,
              nozzleId,
            },
          },
        )
        .then((res) => res.data),
    onSuccess: (updatedWorkflow, { workflowId }) => {
      queryClient.setQueryData(
        workflowQueryKeys.workflow(workflowId),
        updatedWorkflow,
      );
    },
  });

  const updateWorkflowPrinterConfigMutation = useMutation({
    mutationKey: workflowMutationKeys.updateWorkflowPrinterConfig,
    mutationFn: ({ workflowId }) =>
      client
        .post(
          endpoints.updateWorkflowPrinterConfiguration.replace(
            ':workflowId',
            workflowId,
          ),
        )
        .then((res) => res.data),
    onSuccess: (updatedWorkflow, { workflowId }) => {
      queryClient.setQueryData(
        workflowQueryKeys.workflow(workflowId),
        updatedWorkflow,
      );
    },
  });

  return {
    updateWorkflowMetadataMutation,
    createWorkflowMutation,
    deleteWorkflowMutation,
    updateWorkflowMutation,
    computeWorkflowMutation,
    cancelComputeWorkflowMutation,
    updateWorkflowValuesMutation,
    updateWorkflowMaterialMutation,
    updateWorkflowNozzleMutation,
    updateWorkflowPrinterConfigMutation,
  };
}

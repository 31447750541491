/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * WARNING
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * Using this file to define the action types is deprecated.
 * Please add the new action types in the:
 * ../actions/asyncActions.js
 * or
 * ../actions/syncActions.js
 * based on the action nature
 */

/* Login actions */
export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const TOKEN_EXPIRE_LOG_OUT_SUCCESS = 'TOKEN_EXPIRE_LOG_OUT_SUCCESS';
export const TOKEN_AUTHENTIFICATION_REQUEST = 'TOKEN_AUTHENTIFICATION_REQUEST';
export const TOKEN_AUTHENTIFICATION_FAILURE = 'TOKEN_AUTHENTIFICATION_FAILURE';
export const TOKEN_AUTHENTIFICATION_SUCCESS = 'TOKEN_AUTHENTIFICATION_SUCCESS';

export const UPDATE_USER_PREFERENCE = 'UPDATE_USER_PREFERENCE';
export const UPDATE_USER_SESSION_PREFERENCE = 'UPDATE_USER_SESSION_PREFERENCE';

/* Common actions */
export const UPDATE_OUTER_WALL_LINE_VISIBILITY =
  'UPDATE_OUTER_WALL_LINE_VISIBILITY';
export const UPDATE_INNER_WALL_LINE_VISIBILITY =
  'UPDATE_INNER_WALL_LINE_VISIBILITY';
export const UPDATE_SUPPORT_LINE_VISIBILITY = 'UPDATE_SUPPORT_LINE_VISIBILITY';
export const UPDATE_SUPPORT_INTERFACE_LINE_VISIBILITY =
  'UPDATE_SUPPORT_INTERFACE_LINE_VISIBILITY';
export const UPDATE_BRIM_LINE_VISIBILITY = 'UPDATE_BRIM_LINE_VISIBILITY';
export const UPDATE_SKIN_LINE_VISIBILITY = 'UPDATE_SKIN_LINE_VISIBILITY';
export const UPDATE_INFILL_LINE_VISIBILITY = 'UPDATE_INFILL_LINE_VISIBILITY';
export const UPDATE_MILLING_LINE_VISIBILITY = 'UPDATE_MILLING_LINE_VISIBILITY';
export const UPDATE_LATTICE_LINE_VISIBILITY = 'UPDATE_LATTICE_LINE_VISIBILITY';
export const UPDATE_SEAMS_VISIBILITY = 'UPDATE_SEAMS_VISIBILITY';
export const TOGGLE_SOLID_VISIBILITY = 'TOGGLE_SOLID_VISIBILITY';
export const UPDATE_ROTATIONS_VISIBILITY = 'UPDATE_ROTATIONS_VISIBILITY';
export const UPDATE_VERTICES_VISIBILITY = 'UPDATE_VERTICES_VISIBILITY';
export const UPDATE_DIRECTION_VISIBILITY = 'UPDATE_DIRECTION_VISIBILITY';
export const UPDATE_DIMENSION_VISIBILITY = 'UPDATE_DIMENSION_VISIBILITY';
export const UPDATE_LINETYPE_VISIBILITY = 'UPDATE_LINETYPE_VISIBILITY';
export const UPDATE_DISPLAY_MODE = 'UPDATE_DISPLAY_MODE';
export const UPDATE_GRID_VISIBILITY = 'UPDATE_GRID_VISIBILITY';
export const UPDATE_ROBOT_VISIBILITY = 'UPDATE_ROBOT_VISIBILITY';
export const UPDATE_WORKSPACE_VISIBILITY = 'UPDATE_WORKSPACE_VISIBILITY';
export const UPDATE_ENCLOSURE_VISIBILITY = 'UPDATE_ENCLOSURE_VISIBILITY';
export const UPDATE_CAMERA_POSITION = 'UPDATE_CAMERA_POSITION';
export const UPDATE_LEGEND_RANGE = 'UPDATE_LEGEND_RANGE';
export const UPDATE_PROJECT_SEARCH_BAR_VALUE =
  'UPDATE_PROJECT_SEARCH_BAR_VALUE';

/* Design actions */
export const FETCH_ALL_DESIGNS = 'FETCH_ALL_DESIGNS';
export const FETCH_ALL_DESIGNS_FAILED = 'FETCH_ALL_DESIGNS_FAILED';
export const DESIGNS_LOADED = 'DESIGNS_LOADED';
export const FETCH_DESIGN_GEOMETRY = 'FETCH_DESIGN_GEOMETRY';
export const FETCH_DESIGN_GEOMETRY_SUCCEEDED =
  'FETCH_DESIGN_GEOMETRY_SUCCEEDED';
export const FETCH_DESIGN_GEOMETRY_FAILED = 'FETCH_DESIGN_GEOMETRY_FAILED';
export const SELECT_DESIGN = 'SELECT_DESIGN';
export const DELETE_DESIGN = 'DELETE_DESIGN';
export const DELETE_DESIGN_SUCCEEDED = 'DELETE_DESIGN_SUCCEEDED';
export const DELETE_DESIGN_FAILED = 'DELETE_DESIGN_FAILED';
export const PUBLISH_DESIGN = 'PUBLISH_DESIGN';
export const PUBLISH_DESIGN_SUCCEEDED = 'PUBLISH_DESIGN_SUCCEEDED';
export const PUBLISH_DESIGN_FAILED = 'PUBLISH_DESIGN_FAILED';
export const SHOW_DELETE_DESIGN_DIALOG = 'SHOW_DELETE_DESIGN_DIALOG';
export const DISMISS_DELETE_DESIGN_DIALOG = 'DISMISS_DELETE_DESIGN_DIALOG';
export const SHOW_PUBLISH_DESIGN_DIALOG = 'SHOW_PUBLISH_DESIGN_DIALOG';
export const DISMISS_PUBLISH_DESIGN_DIALOG = 'DISMISS_PUBLISH_DESIGN_DIALOG';
export const CREATE_DESIGN = 'CREATE_DESIGN';
export const CREATE_DESIGN_SUCCEEDED = 'CREATE_DESIGN_SUCCEEDED';
export const UPDATE_DESIGN_SORTING = 'UPDATE_DESIGN_SORTING';
export const NEW_DESIGN_NAME_MODIFIED = 'NEW_DESIGN_NAME_MODIFIED';
export const UPLOADING_FILE = 'UPLOADING_FILE';
export const UPLOAD_STOPPED = 'UPLOAD_STOPPED';
export const UPDATE_DESIGN = 'UPDATE_DESIGN';
export const UPDATE_DESIGN_SUCCEEDED = 'UPDATE_DESIGN_SUCCEEDED';
export const UPDATE_DESIGN_FAILED = 'UPDATE_DESIGN_FAILED';
export const SHOW_UPDATE_DESIGN_DIALOG = 'SHOW_UPDATE_DESIGN_DIALOG';
export const DISMISS_UPDATE_DESIGN_DIALOG = 'DISMISS_UPDATE_DESIGN_DIALOG';
export const UPDATE_DESIGN_NAME_MODIFIED = 'UPDATE_DESIGN_NAME_MODIFIED';
export const DOWNLOAD_DESIGN = 'DOWNLOAD_DESIGN';
export const DOWNLOAD_DESIGN_SUCCEEDED = 'DOWNLOAD_DESIGN_SUCCEEDED';
export const DOWNLOAD_DESIGN_FAILED = 'DOWNLOAD_DESIGN_FAILED';
export const LOAD_MODEL = 'LOAD_MODEL';
export const LOAD_MODEL_SUCCEEDED = 'LOAD_MODEL_SUCCEEDED';
export const RESET_DESIGN_METADATA = 'RESET_DESIGN_METADATA';

/* Concept actions */
export const FETCH_ALL_CONCEPTS = 'FETCH_ALL_CONCEPTS';
export const FETCH_ALL_CONCEPTS_SUCCEEDED = 'FETCH_ALL_CONCEPTS_SUCCEEDED';
export const FETCH_ALL_CONCEPTS_FAILED = 'FETCH_ALL_CONCEPTS_FAILED';
export const FETCH_CONCEPT_GEOMETRY = 'FETCH_CONCEPT_GEOMETRY';
export const FETCH_CONCEPT_GEOMETRY_SUCCEEDED =
  'FETCH_CONCEPT_GEOMETRY_SUCCEEDED';
export const FETCH_CONCEPT_GEOMETRY_FAILED = 'FETCH_CONCEPT_GEOMETRY_FAILED';
export const UPDATE_CONCEPTS = 'UPDATE_CONCEPTS';
export const UPDATE_CONCEPT = 'UPDATE_CONCEPT';
export const UPDATE_CONCEPT_SUCCEEDED = 'UPDATE_CONCEPT_SUCCEEDED';
export const UPDATE_CONCEPT_FAILED = 'UPDATE_CONCEPT_FAILED';
export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW';
export const RUN_CONCEPT = 'RUN_CONCEPT';
export const RUN_CONCEPT_SUCCEEDED = 'RUN_CONCEPT_SUCCEEDED';
export const RUN_CONCEPT_FAILED = 'RUN_CONCEPT_FAILED';
export const UPDATE_INVALID_OPERATORS = 'UPDATE_INVALID_OPERATORS';
export const UPDATE_MISSING_INPUT_OPERATORS = 'UPDATE_MISSING_INPUT_OPERATORS';
export const UPDATE_HIDDEN_INPUT_OPERATORS = 'UPDATE_HIDDEN_INPUT_OPERATORS';
export const UPDATE_HIDDEN_OUTPUT_OPERATORS = 'UPDATE_HIDDEN_OUTPUT_OPERATORS';
export const SHOW_DELETE_CONCEPT_DIALOG = 'SHOW_DELETE_CONCEPT_DIALOG';
export const DISMISS_CREATE_CONCEPT_DIALOG = 'DISMISS_CREATE_CONCEPT_DIALOG';
export const DISMISS_DELETE_CONCEPT_DIALOG = 'DISMISS_DELETE_CONCEPT_DIALOG';
export const CREATE_CONCEPT = 'CREATE_CONCEPT';
export const CREATE_CONCEPT_SUCCEEDED = 'CREATE_CONCEPT_SUCCEEDED';
export const CREATE_CONCEPT_FAILED = 'CREATE_CONCEPT_FAILED';
export const DELETE_CONCEPT = 'DELETE_CONCEPT';
export const DELETE_CONCEPT_SUCCEEDED = 'DELETE_CONCEPT_SUCCEEDED';
export const DELETE_CONCEPT_FAILED = 'DELETE_CONCEPT_FAILED';
export const UPDATE_COMPUTE_AUTOMATICALLY = 'UPDATE_COMPUTE_AUTOMATICALLY';
export const UPDATE_CAMERA = 'UPDATE_CAMERA';
export const DELETE_CONCEPTRUN = 'DELETE_CONCEPTRUN';
export const DELETE_CONCEPTRUN_SUCCEEDED = 'DELETE_CONCEPTRUN_SUCCEEDED';
export const DELETE_CONCEPTRUN_FAILED = 'DELETE_CONCEPTRUN_FAILED';
export const CONCEPT_NAME_MODIFIED = 'CONCEPT_NAME_MODIFIED';
export const CONCEPT_PRINTER_MODIFIED = 'CONCEPT_PRINTER_MODIFIED';
export const RESET_CONCEPT_MODIFICATIONS = 'RESET_CONCEPT_MODIFICATIONS';
export const SHOW_UPDATE_PRINTER_DIALOG = 'SHOW_UPDATE_PRINTER_DIALOG';
export const DISMISS_UPDATE_PRINTER_DIALOG = 'DISMISS_UPDATE_PRINTER_DIALOG';
export const FREEZE_OPERATOR_OUTPUT = 'FREEZE_OPERATOR_OUTPUT';
export const UNFREEZE_OPERATOR_OUTPUT = 'UNFREEZE_OPERATOR_OUTPUT';
export const RESET_FROZEN_OPERATOR_OUTPUTS = 'RESET_FROZEN_OPERATOR_OUTPUTS';
export const CHANGE_CURRENT_FOCUS_MODEL = 'CHANGE_CURRENT_FOCUS_MODEL';
export const ADD_NEW_OPERATORVALUE_TO_LIST_SUCCEEDED =
  'ADD_NEW_OPERATORVALUE_TO_LIST_SUCCEEDED';
export const REMOVE_OPERATORVALUE_FROM_LIST_SUCCEEDED =
  'REMOVE_OPERATORVALUE_FROM_LIST_SUCCEEDED';
export const UPDATE_PRINTINGBED_VISIBILITY = 'UPDATE_PRINTINGBED_VISIBILITY';
export const FETCH_TOOLPATH_SIMULATION = 'FETCH_TOOLPATH_SIMULATION';
export const FETCH_TOOLPATH_SIMULATION_SUCCEEDED =
  'FETCH_TOOLPATH_SIMULATION_SUCCEEDED';
export const FETCH_TOOLPATH_SIMULATION_FAILED =
  'FETCH_TOOLPATH_SIMULATION_FAILED';
export const ENTER_TOOLPATH_SIMULATION = 'ENTER_TOOLPATH_SIMULATION';
export const EXIT_TOOLPATH_SIMULATION = 'EXIT_TOOLPATH_SIMULATION';
export const SET_TOOLPATH_SIMULATION = 'SET_TOOLPATH_SIMULATION';
export const UPDATE_SIMULATION_TRAVELLINE_VISIBILITY =
  'UPDATE_SIMULATION_TRAVELLINE_VISIBILITY';
export const UPDATE_OPERATOR_ORDER = 'UPDATE_OPERATOR_ORDER';
export const UPDATE_OPERATOR_ORDER_SUCCEEDED =
  'UPDATE_OPERATOR_ORDER_SUCCEEDED';
export const UPDATE_OPERATOR_ORDER_FAILED = 'UPDATE_OPERATOR_ORDER_FAILED';
export const FETCH_WORKFLOW = 'FETCH_WORKFLOW';
export const FETCH_WORKFLOW_SUCCEEDED = 'FETCH_WORKFLOW_SUCCEEDED';
export const FETCH_WORKFLOW_FAILED = 'FETCH_WORKFLOW_FAILED';
export const TOGGLE_OPERATOR_DESCRIPTORS_POPUP =
  'TOGGLE_OPERATOR_DESCRIPTORS_POPUP';
export const DISMISS_OPERATOR_DESCRIPTORS_POPUP =
  'DISMISS_OPERATOR_DESCRIPTORS_POPUP';
export const OPRATOR_SELECTOR_DRAG_START = 'OPRATOR_SELECTOR_DRAG_START';
export const OPRATOR_SELECTOR_DRAG_END = 'OPRATOR_SELECTOR_DRAG_END';
export const ADD_OPERATOR_AT_POSITION_REQUEST =
  'ADD_OPERATOR_AT_POSITION_REQUEST';
export const ADD_OPERATOR_AT_POSITION_SUCCESS =
  'ADD_OPERATOR_AT_POSITION_SUCCESS';
export const ADD_OPERATOR_AT_POSITION_FAILURE =
  'ADD_OPERATOR_AT_POSITION_FAILURE';

/* Operator actions */
export const FETCH_DEFAULT_OPERATORS = 'FETCH_DEFAULT_OPERATORS';
export const FETCH_DEFAULT_OPERATORS_SUCCEEDED =
  'FETCH_DEFAULT_OPERATORS_SUCCEEDED';
export const FETCH_DEFAULT_OPERATORS_FAILED = 'FETCH_DEFAULT_OPERATORS_FAILED';
export const ADD_OPERATOR = 'ADD_OPERATOR';
export const ADD_OPERATOR_SUCCEEDED = 'ADD_OPERATOR_SUCCEEDED';
export const ADD_OPERATOR_FAILED = 'ADD_OPERATOR_FAILED';
export const UPGRADE_OPERATOR = 'UPGRADE_OPERATOR';
export const UPGRADE_OPERATOR_SUCCEEDED = 'UPGRADE_OPERATOR_SUCCEEDED';
export const UPGRADE_OPERATOR_FAILED = 'UPGRADE_OPERATOR_FAILED';
export const REMOVE_OPERATOR = 'REMOVE_OPERATOR';
export const REMOVE_OPERATOR_SUCCEEDED = 'REMOVE_OPERATOR_SUCCEEDED';
export const REMOVE_OPERATOR_FAILED = 'REMOVE_OPERATOR_FAILED';
export const SELECT_OPERATOR = 'SELECT_OPERATOR';
export const OPERATOR_INPUT_MODIFIED = 'OPERATOR_INPUT_MODIFIED';
export const REMOVE_OPERATOR_INPUT_MODIFICATIONS =
  'REMOVE_OPERATOR_INPUT_MODIFICATIONS';
export const SELECT_OPERATOR_OUTPUT = 'SELECT_OPERATOR_OUTPUT';
export const OPERATOR_MODIFIED = 'OPERATOR_MODIFIED';

/* Toolpath actions */
export const FETCH_TOOLPATH_INSTRUCTIONS = 'FETCH_TOOLPATH_INSTRUCTIONS';
export const FETCH_TOOLPATH_INSTRUCTIONS_SUCCEEDED =
  'FETCH_TOOLPATH_INSTRUCTIONS_SUCCEEDED';
export const FETCH_TOOLPATH_INSTRUCTIONS_FAILED =
  'FETCH_TOOLPATH_INSTRUCTIONS_FAILED';
export const CONNECT_TO_TOOLPATH_SOCKET = 'CONNECT_TO_TOOLPATH_SOCKET';
export const CLOSED_CONNECTION_TO_TOOLPATH_SOCKET =
  'CLOSED_CONNECTION_TO_TOOLPATH_SOCKET';
export const EVENTS_WEBSOCKET_CREATED = 'EVENTS_WEBSOCKET_CREATED';
export const STREAM_TOOLPATH_INSTRUCTIONS = 'STREAM_TOOLPATH_INSTRUCTIONS';
export const STREAM_TOOLPATH_INSTRUCTIONS_SUCCEEDED =
  'STREAM_TOOLPATH_INSTRUCTIONS_SUCCEEDED';
export const STREAM_TOOLPATH_INSTRUCTIONS_FAILED =
  'STREAM_TOOLPATH_INSTRUCTIONS_FAILED';
export const SET_TOOLPATH_SIMULATION_VISIBILITY =
  'TOGGLE_TOOLPATH_SIMULATION_VISIBILITY';

/* Printer actions */
export const FETCH_ALL_PRINTERS = 'FETCH_ALL_PRINTERS';
export const FETCH_ALL_PRINTERS_SUCCEEDED = 'FETCH_ALL_PRINTERS_SUCCEEDED';
export const FETCH_ALL_PRINTERS_FAILED = 'FETCH_ALL_PRINTERS_FAILED';
export const SELECT_PRINTER = 'SELECT_PRINTER';
export const CREATE_PRINTER_SUCCEEDED = 'CREATE_PRINTER_SUCCEEDED';
export const UPDATE_PRINTER = 'UPDATE_PRINTER';
export const UPDATE_PRINTER_SUCCEEDED = 'UPDATE_PRINTER_SUCCEEDED';
export const UPDATE_PRINTER_FAILED = 'UPDATE_PRINTER_FAILED';
export const DELETE_PRINTER = 'DELETE_PRINTER';
export const DELETE_PRINTER_SUCCEEDED = 'DELETE_PRINTER_SUCCEEDED';
export const DELETE_PRINTER_FAILED = 'DELETE_PRINTER_FAILED';
export const PRINTER_ROBOT_TYPE_MODIFIED = 'PRINTER_ROBOT_TYPE_MODIFIED';
export const PRINTER_SETTING_MODIFIED = 'PRINTER_SETTING_MODIFIED';
export const PRINTER_HEARTBEAT = 'PRINTER_HEARTBEAT';
export const PRINT_STATUS = 'PRINT_STATUS';
export const CANCEL_PRINT = 'CANCEL_PRINT';
export const CANCEL_PRINT_SUCCEEDED = 'CANCEL_PRINT_SUCCEEDED';
export const CANCEL_PRINT_FAILED = 'CANCEL_PRINT_FAILED';
export const SHOW_DELETE_PRINT_DIALOG = 'SHOW_DELETE_PRINT_DIALOG';
export const DISMISS_DELETE_PRINT_DIALOG = 'DISMISS_DELETE_PRINT_DIALOG';
export const DELETE_PRINT = 'DELETE_PRINT';
export const DELETE_PRINT_SUCCEEDED = 'DELETE_PRINT_SUCCEEDED';
export const DELETE_PRINT_FAILED = 'DELETE_PRINT_FAILED';
export const FETCH_PRINTER_CAMERAS_REQUEST = 'FETCH_PRINTER_CAMERAS_REQUEST';
export const FETCH_PRINTER_CAMERAS_SUCCESS = 'FETCH_PRINTER_CAMERAS_SUCCESS';
export const FETCH_PRINTER_CAMERAS_FAILURE = 'FETCH_PRINTER_CAMERAS_FAILURE';
export const UPDATE_CAMERA_SENSITIVITY = 'UPDATE_CAMERA_SENSITIVITY';
export const UPDATE_CAMERA_SENSITIVITY_SUCCESS =
  'UPDATE_CAMERA_SENSITIVITY_SUCCESS';
export const UPDATE_CAMERA_SENSITIVITY_FAILURE =
  'UPDATE_CAMERA_SENSITIVITY_FAILURE';

/* Analytics actions */
export const SELECT_ANALYTICS_PRINTER = 'SELECT_ANALYTICS_PRINTER';
export const ALL_SENSORS_DATA = 'ALL_SENSORS_DATA';
export const FETCH_ALL_DASHBOARDS = 'FETCH_ALL_DASHBOARDS';
export const DASHBOARDS_LOADED = 'DASHBOARDS_LOADED';
export const FETCH_ALL_DASHBOARDS_FAILED = 'FETCH_ALL_DASHBOARDS_FAILED';
export const FETCH_ALL_WIDGET_TEMPLATES = 'FETCH_ALL_WIDGET_TEMPLATES';
export const WIDGET_TEMPLATES_LOADED = 'WIDGET_TEMPLATES_LOADED';
export const FETCH_ALL_WIDGET_TEMPLATES_FAILED =
  'FETCH_ALL_WIDGET_TEMPLATES_FAILED';
export const DASHBOARD_SELECTED = 'DASHBOARD_SELECTED';
export const RESET_SELECTED_DASHBOARD = 'RESET_SELECTED_DASHBOARD';
export const SHOW_CREATE_DASHBOARD_DIALOG = 'SHOW_CREATE_DASHBOARD_DIALOG';
export const CREATE_DASHBOARD = 'CREATE_DASHBOARD';
export const CREATE_DASHBOARD_FAILED = 'CREATE_DASHBOARD_FAILED';
export const CREATE_DASHBOARD_SUCCEEDED = 'CREATE_DASHBOARD_SUCCEEDED';
export const DISMISS_CREATE_DASHBOARD_DIALOG =
  'DISMISS_CREATE_DASHBOARD_DIALOG';
export const DASHBOARD_NAME_MODIFIED = 'DASHBOARD_NAME_MODIFIED';
export const NEW_DASHBOARD_NAME_MODIFIED = 'NEW_DASHBOARD_NAME_MODIFIED';
export const SHOW_DELETE_DASHBOARD_DIALOG = 'SHOW_DELETE_DASHBOARD_DIALOG';
export const DISMISS_DELETE_DASHBOARD_DIALOG =
  'DISMISS_DELETE_DASHBOARD_DIALOG';
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const DELETE_DASHBOARD_SUCCEEDED = 'DELETE_DASHBOARD_SUCCEEDED';
export const DELETE_DASHBOARD_FAILED = 'DELETE_DASHBOARD_FAILED';
export const SHOW_UPDATE_DASHBOARD_DIALOG = 'SHOW_UPDATE_DASHBOARD_DIALOG';
export const DISMISS_UPDATE_DASHBOARD_DIALOG =
  'DISMISS_UPDATE_DASHBOARD_DIALOG';
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';
export const UPDATE_DASHBOARD_SUCCEEDED = 'UPDATE_DASHBOARD_SUCCEEDED';
export const UPDATE_DASHBOARD_FAILED = 'UPDATE_DASHBOARD_FAILED';
export const UPDATE_DASHBOARD_NAME_MODIFIED = 'UPDATE_DASHBOARD_NAME_MODIFIED';
export const CREATE_WIDGET = 'CREATE_WIDGET';
export const CREATE_WIDGET_SUCCEEDED = 'CREATE_WIDGET_SUCCEEDED';
export const CREATE_WIDGET_FAILED = 'CREATE_WIDGET_FAILED';
export const UPDATE_WIDGET = 'CREATE_WIDGET';
export const UPDATE_WIDGET_SUCCEEDED = 'CREATE_WIDGET_SUCCEEDED';
export const UPDATE_WIDGET_FAILED = 'CREATE_WIDGET_FAILED';
export const SHOW_CREATE_WIDGET_DIALOG = 'SHOW_CREATE_WIDGET_DIALOG';
export const DISMISS_CREATE_WIDGET_DIALOG = 'DISMISS_CREATE_WIDGET_DIALOG';
export const TOOLPATH_WORKSPACE_SELECTED = 'TOOLPATH_WORKSPACE_SELECTED';
export const TOOLPATH_FETCH_ALL_CONCEPTS = 'TOOLPATH_FETCH_ALL_CONCEPTS';
export const TOOLPATH_FETCH_ALL_CONCEPTS_SUCCEEDED =
  'TOOLPATH_FETCH_ALL_CONCEPTS_SUCCEEDED';
export const TOOLPATH_FETCH_ALL_CONCEPTS_FAILED =
  'TOOLPATH_FETCH_ALL_CONCEPTS_FAILED';
export const TOOLPATH_WORKFLOW_SELECTED = 'TOOLPATH_WORKFLOW_SELECTED';
export const TOOLPATH_SELECTED = 'TOOLPATH_SELECTED';
export const TOOLPATH_REMOVED = 'TOOLPATH_REMOVED';
export const FETCH_ALL_DASHBOARD_WIDGETS = 'FETCH_ALL_DASHBOARD_WIDGETS';
export const FETCH_ALL_DASHBOARD_WIDGETS_SUCCEEDED =
  'FETCH_ALL_DASHBOARD_WIDGETS_SUCCEEDED';
export const FETCH_ALL_DASHBOARD_WIDGETS_FAILED =
  'FETCH_ALL_DASHBOARD_WIDGETS_FAILED';
export const NEW_COMPONENT_NAME_CHANGED = 'NEW_COMPONENT_NAME_CHANGED';
export const DELETE_WIDGET = 'DELETE_WIDGET';
export const DELETE_WIDGET_SUCCEEDED = 'DELETE_WIDGET_SUCCEEDED';
export const DELETE_WIDGET_FAILED = 'DELETE_WIDGET_FAILED';
export const SHOW_EDIT_WIDGET_DIALOG = 'SHOW_EDIT_WIDGET_DIALOG';
export const FETCH_WIDGET_DATA = 'FETCH_WIDGET_DATA';
export const FETCH_WIDGET_DATA_SUCCEEDED = 'FETCH_WIDGET_DATA_SUCCEEDED';
export const FETCH_WIDGET_DATA_FAILED = 'FETCH_WIDGET_DATA_FAILED';
export const FETCH_PRINTER_SENSORS_DATA = 'FETCH_PRINTER_SENSORS_DATA';
export const FETCH_PRINTER_SENSORS_DATA_SUCCEEDED =
  'FETCH_PRINTER_SENSORS_DATA_SUCCEEDED';
export const FETCH_PRINTER_SENSORS_DATA_FAILED =
  'FETCH_PRINTER_SENSORS_DATA_FAILED';
export const SELECT_PRINTER_WIDGET = 'SELECT_PRINTER_WIDGET';
export const FETCH_PRINTER_IMAGES = 'FETCH_PRINTER_IMAGES';
export const FETCH_PRINTER_IMAGES_SUCCEEDED = 'FETCH_PRINTER_IMAGES_SUCCEEDED';
export const FETCH_PRINTER_IMAGES_FAILED = 'FETCH_PRINTER_IMAGES_FAILED';
export const FETCH_CAMERA_VIDEO_FEED_URL = 'FETCH_CAMERA_VIDEO_FEED_URL';
export const FETCH_CAMERA_VIDEO_FEED_URL_SUCCEEDED =
  'FETCH_CAMERA_VIDEO_FEED_URL_SUCCEEDED';
export const FETCH_CAMERA_VIDEO_FEED_URL_FAILED =
  'FETCH_CAMERA_VIDEO_FEED_URL_FAILED';
export const FETCH_TOOLPATH_ANALYSIS_DATA = 'FETCH_TOOLPATH_ANALYSIS_DATA';
export const FETCH_TOOLPATH_ANALYSIS_DATA_SUCCEEDED =
  'FETCH_TOOLPATH_ANALYSIS_DATA_SUCCEEDED';
export const FETCH_TOOLPATH_ANALYSIS_DATA_FAILED =
  'FETCH_TOOLPATH_ANALYSIS_DATA_FAILED';
export const SELECTED_PRINT_VIDEO_FEED = 'SELECTED_PRINT_VIDEO_FEED';
export const FETCH_PRINTER_SENSORS_HISTORICAL_DATA =
  'FETCH_PRINTER_SENSORS_HISTORICAL_DATA';
export const FETCH_PRINTER_SENSORS_HISTORICAL_DATA_SUCCEEDED =
  'FETCH_PRINTER_SENSORS_HISTORICAL_DATA_SUCCEEDED';
export const FETCH_PRINTER_SENSORS_HISTORICAL_DATA_FAILED =
  'FETCH_PRINTER_SENSORS_HISTORICAL_DATA_FAILED';

/* Error actions */
export const SHOW_ERROR_DIALOG = 'SHOW_ERROR_DIALOG';
export const DISMISS_ERROR_DIALOG = 'DISMISS_ERROR_DIALOG';

/* Workspace actions */
export const FETCH_ALL_WORKSPACES = 'FETCH_ALL_WORKSPACES';
export const FETCH_ALL_WORKSPACES_SUCCEEDED = 'FETCH_ALL_WORKSPACES_SUCCEEDED';
export const FETCH_ALL_WORKSPACES_FAILED = 'FETCH_ALL_WORKSPACES_FAILED';
export const WORKSPACE_SELECTED = 'WORKSPACE_SELECTED';
export const RESET_SELECTED_WORKSPACE = 'RESET_SELECTED_WORKSPACE';
export const CREATE_WORKSPACE = 'CREATE_WORKSPACE';
export const CREATE_WORKSPACE_FAILED = 'CREATE_WORKSPACE_FAILED';
export const CREATE_WORKSPACE_SUCCEEDED = 'CREATE_WORKSPACE_SUCCEEDED';
export const SHOW_DELETE_WORKSPACE_DIALOG = 'SHOW_DELETE_WORKSPACE_DIALOG';
export const DISMISS_DELETE_WORKSPACE_DIALOG =
  'DISMISS_DELETE_WORKSPACE_DIALOG';
export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';
export const DELETE_WORKSPACE_SUCCEEDED = 'DELETE_WORKSPACE_SUCCEEDED';
export const DELETE_WORKSPACE_FAILED = 'DELETE_WORKSPACE_FAILED';
export const SHOW_UPDATE_WORKSPACE_DIALOG = 'SHOW_UPDATE_WORKSPACE_DIALOG';
export const DISMISS_UPDATE_WORKSPACE_DIALOG =
  'DISMISS_UPDATE_WORKSPACE_DIALOG';
export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE';
export const UPDATE_WORKSPACE_SUCCEEDED = 'UPDATE_WORKSPACE_SUCCEEDED';
export const UPDATE_WORKSPACE_FAILED = 'UPDATE_WORKSPACE_FAILED';

/* Print actions */
export const FETCH_ALL_PRINTS = 'FETCH_ALL_PRINTS';
export const FETCH_ALL_PRINTS_SUCCEEDED = 'FETCH_ALL_PRINTS_SUCCEEDED';
export const FETCH_ALL_PRINTS_FAILED = 'FETCH_ALL_PRINTS_FAILED';
export const SELECT_PRINT = 'SELECT_PRINT';
export const FETCH_ALL_PRINT_DASHBOARDS = 'FETCH_ALL_PRINT_DASHBOARDS';
export const FETCH_ALL_PRINT_DASHBOARDS_SUCCEEDED =
  'FETCH_ALL_PRINT_DASHBOARDS_SUCCEEDED';
export const FETCH_ALL_PRINT_DASHBOARDS_FAILED =
  'FETCH_ALL_PRINT_DASHBOARDS_FAILED';

/* Modal */
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

/* Computation progress */
export const START_COMPUTATION = 'START_COMPUTATION';
export const CANCEL_COMPUTATION = 'CANCEL_COMPUTATION';
export const COMPUTATION_COMPLETED = 'COMPUTATION_COMPLETED';
export const COMPUTATION_FAILED = 'COMPUTATION_FAILED';
export const SET_COMPUTATION_PROGRESS_HANDLER =
  'SET_COMPUTATION_PROGRESS_HANDLER';
export const RESET_COMPUTATION_PROGRESS_HANDLER =
  'RESET_COMPUTATION_PROGRESS_HANDLER';
export const SET_COMPUTING_ITEM_ID = 'SET_COMPUTING_ITEM_ID';
export const SET_COMPUTED_ITEM_IDS = 'SET_COMPUTED_ITEM_IDS';

/* Last added operator */
export const UPDATE_LAST_ADDED_OPERATOR_ID = 'UPDATE_LAST_ADDED_OPERATOR_ID';
export const RESET_LAST_ADDED_OPERATOR_ID = 'RESET_LAST_ADDED_OPERATOR_ID';

/* GPT */
export const GPT_ADD_MESSAGES_TO_CONVERSATION =
  'GPT_ADD_MESSAGES_TO_CONVERSATION';
export const GPT_UPDATE_CONVERSATION = 'GPT_UPDATE_CONVERSATION';
export const GPT_CONVERSATION_REQUEST = 'GPT_CONVERSATION_REQUEST';
export const GPT_CONVERSATION_SUCCESS = 'GPT_CONVERSATION_SUCCESS';
export const GPT_CONVERSATION_FAILURE = 'GPT_CONVERSATION_FAILURE';
export const GPT_APPLY_CHANGES_REQUEST = 'GPT_APPLY_CHANGES_REQUEST';
export const GPT_APPLY_CHANGES_SUCCESS = 'GPT_APPLY_CHANGES_SUCCESS';
export const GPT_APPLY_CHANGES_FAILURE = 'GPT_APPLY_CHANGES_FAILURE';
export const GPT_RESET = 'GPT_RESET';

/* Templates */
export const FETCH_TEMPLATES_REQUEST = 'FETCH_TEMPLATES_REQUEST';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAILURE = 'FETCH_TEMPLATES_FAILURE';
export const EXPLODE_TEMPLATE_REQUEST = 'EXPLODE_TEMPLATE_REQUEST';
export const EXPLODE_TEMPLATE_SUCCESS = 'EXPLODE_TEMPLATE_SUCCESS';
export const EXPLODE_TEMPLATE_FAILURE = 'EXPLODE_TEMPLATE_FAILURE';
export const CREATE_TEMPLATE_REQUEST = 'CREATE_TEMPLATE_REQUEST';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_FAILURE = 'CREATE_TEMPLATE_FAILURE';
export const CREATE_TEMPLATE_SET_CURRENT_STAGE =
  'CREATE_TEMPLATE_SET_CURRENT_STAGE';
export const SET_CREATE_TEMPLATE_COMPLETED_STAGE =
  'SET_CREATE_TEMPLATE_COMPLETED_STAGE';
export const RESET_CREATE_TEMPLATE = 'RESET_CREATE_TEMPLATE';
export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';
export const ADD_TEMPLATE_TO_FAVOURITE_REQUEST =
  'ADD_TEMPLATE_TO_FAVOURITE_REQUEST';
export const ADD_TEMPLATE_TO_FAVOURITE_SUCCESS =
  'ADD_TEMPLATE_TO_FAVOURITE_SUCCESS';
export const ADD_TEMPLATE_TO_FAVOURITE_FAILURE =
  'ADD_TEMPLATE_TO_FAVOURITE_FAILURE';
export const REMOVE_TEMPLATE_FROM_FAVOURITE_REQUEST =
  'REMOVE_TEMPLATE_FROM_FAVOURITE_REQUEST';
export const REMOVE_TEMPLATE_FROM_FAVOURITE_SUCCESS =
  'REMOVE_TEMPLATE_FROM_FAVOURITE_SUCCESS';
export const REMOVE_TEMPLATE_FROM_FAVOURITE_FAILURE =
  'REMOVE_TEMPLATE_FROM_FAVOURITE_FAILURE';

/* Quick Start */
export const QUICK_START_PROJECT_REQUEST = 'QUICK_START_PROJECT_REQUEST';
export const QUICK_START_PROJECT_SUCCESS = 'QUICK_START_PROJECT_SUCCESS';
export const QUICK_START_PROJECT_FAILURE = 'QUICK_START_PROJECT_FAILURE';

export const QUICK_START_SET_CURRENT_STAGE = 'QUICK_START_SET_CURRENT_STAGE';
export const SET_QUICK_START_COMPLETED_STAGE =
  'SET_QUICK_START_COMPLETED_STAGE';
export const RESET_QUICK_START = 'RESET_QUICK_START';
export const QUICK_START_REDIRECTION_COMPLETED =
  'QUICK_START_REDIRECTION_COMPLETED';

/* Notifications */
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

/* Scene */
export const SET_SCENE_CAMERA_CONFIG = 'SET_SCENE_CAMERA_CONFIG';
export const FOCUS_CAMERA_ON_OBJECT = 'FOCUS_CAMERA_ON_OBJECT';

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * WARNING
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * Using this file to define the action types is deprecated.
 * Please add the new action types in the:
 * ../actions/asyncActions.js
 * or
 * ../actions/syncActions.js
 * based on the action nature
 */

import axios from 'axios';
import config from './config';

const TIMEOUT = 120000;

const generateEnvKey = (env = '') => env && 'current';

const ENVIRONMENTS = {
  [generateEnvKey(DEVELOPMENT_ENV)]: 'development',
  [generateEnvKey(TEST_ENV)]: 'test',
  [generateEnvKey(PROD_ENV)]: 'production',
  [generateEnvKey(ONPREMISE_ENV)]: 'onpremise',
};

const api = config[ENVIRONMENTS.current];

// if (process?.env?.REACT_APP_USE_TEST_ENV_SERVER) {
//   api.baseUrl = config.test.baseUrl;
//   api.baseUrlws = config.test.baseUrlws;
// }

const client = axios.create({
  baseURL: api.baseUrl,
  timeout: TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default client;

import React, {
  useEffect,
  useCallback,
  useMemo,
  useContext,
  useRef,
} from 'react';
import cadex from '@cadexchanger/web-toolkit';
import useCanvasSelectionFetches from '@app/hooks/operators/useCanvasSelectionFetches';
import { useSelector } from 'react-redux';
import { selectActiveCanvasSelectionInput } from '@app/reducers/workflowSlice';
import useOperator from '@app/hooks/operators/useOperator';
import PropTypes from 'prop-types';
import useFile from '@app/hooks/files/useFile';
import useWorkflow from '@app/hooks/workflows/useWorkflow';
import { VisualizationContext } from '@app/contexts/VisualizationContext';
import * as THREE from 'three';
import { BrepShapeFinder, BrepShapeIdFinder } from '@app/utils/canvasSelection';
import { useIntl } from 'react-intl';
import { ModalDataTypes } from '@app/constants/modalDataTypes';
import useDialog from '@app/hooks/useDialog';
import {
  ORTHOGRAPHIC_CAMERA_DEFAULT_POSITION,
  ORTHOGRAPHIC_CAMERA_MAX_ZOOM,
  orthographicCameraDirections,
} from '@constants/camera';
import { hexToRgbNormalised } from '@app/stylesheets/helpers';
import { useTheme } from 'styled-components';
import {
  BASE_CDXWEB_FILE_NAME,
  BREP_OUTPUT_NAME,
  ID_INPUT_SUFFIX,
  MODEL_INPUT_SUFFIX,
} from '@app/constants/canvasSelection';

const CanvasSelection = ({ form: { setValue, watch } }) => {
  const scene = useRef(null);
  const viewport = useRef(null);
  const model = useRef({});
  const theme = useTheme();
  const intl = useIntl();
  const { canvasSelectionObjectFetch } = useCanvasSelectionFetches();
  const activeInputId = useSelector(selectActiveCanvasSelectionInput);
  const {
    deselectCanvasSelectionField,
    getSelectedOperatorByValueId,
    getOperatorInput,
    getOperatorInputs,
    getOperatorOutputs,
    getDefaultOperators,
  } = useOperator();
  const { getSelectedWorkflow, getWorkflowOperators } = useWorkflow();
  const { getSelectedDesignIds } = useFile();
  const {
    controls,
    setCanvasSelectionCamera,
    camera,
    orthographicCameraMode,
    cameraConfig,
    printer,
  } = useContext(VisualizationContext);
  const { showDialog } = useDialog();

  const workflow = getSelectedWorkflow();
  const workflowOperators = getWorkflowOperators(workflow);

  const cameraDirection = cameraConfig?.position;
  const selectedDesigns = getSelectedDesignIds(workflowOperators);
  const selectedDesignIds = selectedDesigns.canvasSelectionDesigns;
  const selectedDesignIdOperators = selectedDesigns.designIdOperators;
  const selectedOperator = getSelectedOperatorByValueId(activeInputId);
  const selectedOperatorId = selectedOperator?.id;
  const activeInput = getOperatorInput(selectedOperator, activeInputId);
  const allInputs = getOperatorInputs(selectedOperator);

  const defaultOperators = useMemo(
    () => getDefaultOperators(),
    [getDefaultOperators],
  );
  const defaultOperator = useMemo(
    () => defaultOperators.find(({ name }) => selectedOperator?.name === name),
    [defaultOperators, selectedOperator],
  );
  const defaultOperatorTargetSetting = useMemo(
    () =>
      defaultOperator?.settings.find(({ name }) => name === activeInput?.name),
    [defaultOperator, activeInput],
  );
  const referencedInputName = defaultOperatorTargetSetting?.selectionInput;
  const modelInput = allInputs?.find(
    (i) => i.name === `${referencedInputName}${MODEL_INPUT_SUFFIX}`,
  );
  const idInput = allInputs?.find(
    (i) => i.name === `${referencedInputName}${ID_INPUT_SUFFIX}`,
  );
  const originalInput = allInputs?.find(
    (i) => i.name === defaultOperatorTargetSetting?.name,
  );

  const modelPath = `${selectedOperatorId}.${modelInput?.id}`;
  const idPath = `${selectedOperatorId}.${idInput?.id}`;
  const targetPath = `${selectedOperatorId}.${originalInput?.id}`;
  const watchReferencedModelInput = watch(modelPath);
  const watchReferencedShapeIdInput = watch(idPath);

  const setInputFieldValue = useCallback(
    (modelFieldValue, fieldValue) => {
      setValue(targetPath, modelFieldValue, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue(modelPath, modelFieldValue, {
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue(idPath, fieldValue.toString(), {
        shouldTouch: true,
        shouldDirty: true,
      });
    },
    [setValue, idPath, modelPath, targetPath],
  );

  const showErrorDialog = useCallback(
    (subtitleId, subtitleDefault) => {
      showDialog(ModalDataTypes.PROMPT, {
        dataTestId: 'canvas-selection-design-id-error',
        title: intl.formatMessage({
          id: 'dialogs.title.attention',
          defaultMessage: 'Attention',
        }),
        subtitle: intl.formatMessage({
          id: subtitleId,
          defaultMessage: subtitleDefault,
        }),
        secondaryButtonLabel: '',
      });
    },
    [showDialog, intl],
  );

  const onSelectionChanged = useCallback(
    async (e) => {
      const added = e.added;

      let chosenItem;
      if (added.length > 0) {
        chosenItem = added[0];
      }

      if (!chosenItem) {
        return;
      }

      let pickedModelDesignId;
      let brepId;

      // traverse up the nodes to find the design ID we have picked
      let currentNode = chosenItem.node;
      while (currentNode) {
        if (currentNode.designId) {
          pickedModelDesignId = currentNode.designId;
          brepId = currentNode.brepId;
          break;
        }
        currentNode = currentNode.parent;
      }

      if (!pickedModelDesignId) {
        showErrorDialog(
          'canvasselection.error.cannot_find_surface',
          'Something went wrong trying to find the surface you selected. Please try again.',
        );
        return;
      }

      let pickedShapeId = -1;
      let duplicateShapeIdFound = false;
      const pickedModel = model.current[pickedModelDesignId];

      for (const entity of chosenItem.entities()) {
        await pickedModel.accept(
          new BrepShapeIdFinder(entity.shape, (foundShapeId) => {
            if (pickedShapeId !== -1) {
              duplicateShapeIdFound = true;
            }
            pickedShapeId = foundShapeId;
          }),
        );
      }

      if (duplicateShapeIdFound) {
        showErrorDialog(
          'canvasselection.error.duplicate_shape_ids',
          'The supplied STEP file contains duplicate shapes and this surface cannot be selected. Please try again with a different STEP file.',
        );
        return;
      }

      if (pickedShapeId !== -1) {
        setInputFieldValue(brepId, pickedShapeId);
        deselectCanvasSelectionField();
      } else {
        showErrorDialog(
          'canvasselection.error.cannot_find_surface',
          'Something went wrong trying to find the surface you selected. Please try again.',
        );
      }
    },
    [setInputFieldValue, deselectCanvasSelectionField, showErrorDialog],
  );

  const fetchFile = useCallback(
    (designId) => async (fileName) => {
      return await canvasSelectionObjectFetch(designId, fileName);
    },
    [canvasSelectionObjectFetch],
  );

  const matchExistingScene = useCallback(
    async (sceneNode) => {
      // position the object at bed origin
      const { bed } = printer;
      const { baseTransformationMatrix } = bed;

      const matrix = baseTransformationMatrix.clone();
      const basePosition = new THREE.Vector3();
      basePosition.setFromMatrixPosition(matrix);
      basePosition.multiplyScalar(0.02);
      matrix.setPosition(basePosition);
      const scaleMatrix = new THREE.Matrix4().makeScale(0.02, 0.02, 0.02);
      matrix.multiply(scaleMatrix);
      sceneNode.transformation = new cadex.ModelData_Transformation(
        matrix.elements,
      );
      // actually draw it on the screen
      scene.current.addRoot(sceneNode);
      await scene.current.update();
    },
    [printer],
  );

  /**
   * Sets the gravity point of camera rotation according to the provided target
   */
  const setGravityPoint = useCallback((target) => {
    for (const inputHandler of viewport.current.inputManager.inputHandlers()) {
      if (inputHandler instanceof cadex.ModelPrs_CameraManipulationHandler) {
        inputHandler.rotateHandler.gravityPoint = new cadex.ModelData_Point(
          target.x,
          target.y,
          target.z,
        );
      }
    }
  }, []);

  /**
   * Align the camera position and rotation between Three.js and the WTK.
   */
  const alignCameraPositions = useCallback(() => {
    if (!controls.current || !camera.current) return;
    const wtkCamera = viewport.current.camera;

    wtkCamera.projectionType = cadex.ModelPrs_CameraProjectionType.Perspective;
    wtkCamera.fov = 15;

    const threeJsCamera = camera.current;
    let threeJsCameraPosition = threeJsCamera.position;
    const orbitControlsTarget = controls.current.target;
    if (orthographicCameraMode) {
      const [dx, dy, dz] =
        orthographicCameraDirections?.[cameraDirection] ||
        ORTHOGRAPHIC_CAMERA_DEFAULT_POSITION;
      threeJsCameraPosition = {
        x:
          threeJsCameraPosition.x +
          dx * (ORTHOGRAPHIC_CAMERA_MAX_ZOOM / threeJsCamera.zoom),
        y:
          threeJsCameraPosition.y +
          dy * (ORTHOGRAPHIC_CAMERA_MAX_ZOOM / threeJsCamera.zoom),
        z:
          threeJsCameraPosition.z +
          dz * (ORTHOGRAPHIC_CAMERA_MAX_ZOOM / threeJsCamera.zoom),
      };
    }
    wtkCamera.set(
      new cadex.ModelData_Point(
        threeJsCameraPosition.x,
        threeJsCameraPosition.y,
        threeJsCameraPosition.z,
      ),
      new cadex.ModelData_Point(
        orbitControlsTarget.x,
        orbitControlsTarget.y,
        orbitControlsTarget.z,
      ),
      new cadex.ModelData_Point(0, 0, 1),
      1,
      10000,
    );

    // get orbit style camera by setting the gravity point of the camera to
    // the orbit target rather than the centre of the bounding box (the default)
    setGravityPoint(controls.current.target);

    // ensure orbit is maintained when the camera is panned
    viewport.current.camera.addEventListener('changed', (e) =>
      setGravityPoint(e.target.target),
    );

    viewport.current.update();
    setCanvasSelectionCamera(viewport.current.camera);
  }, [
    controls,
    camera,
    setGravityPoint,
    orthographicCameraMode,
    cameraDirection,
    setCanvasSelectionCamera,
  ]);

  /**
   * Select a shape recursively in a node. Recurses into each node's children
   * to try and select a shape. Will do nothing if the shape cannot be selected.
   */
  const selectItem = useCallback((nodes, selectedShape) => {
    if (!selectedShape || nodes.length === 0) return;

    let selectionItem;
    let selected;
    for (const node of nodes) {
      selectionItem = new cadex.ModelPrs_SelectionItem(node, selectedShape);
      selected = scene.current.selectionManager.select(
        selectionItem,
        true,
        false,
      );

      if (selected) {
        return;
      } else {
        selectItem(node.childNodes(), selectedShape);
      }
    }
  }, []);

  const preselectFaceIfNecessary = useCallback(
    async (node, model) => {
      if (watchReferencedModelInput && watchReferencedShapeIdInput) {
        let selectedShape;
        await model.accept(
          new BrepShapeFinder(
            watchReferencedShapeIdInput,
            async (foundShape) => {
              if (foundShape) {
                selectedShape = new cadex.ModelPrs_SelectedShapeEntity(
                  foundShape,
                );
              }
            },
          ),
        );
        selectItem([node], selectedShape);
      }
    },
    [watchReferencedModelInput, watchReferencedShapeIdInput, selectItem],
  );

  const renderModel = useCallback(
    async (baseFileName, designId, operatorId) => {
      // avoid expensive re-computation of already rendered designs
      if (model.current[designId]) {
        return;
      }
      if (model.current[designId]) {
        model.current[designId].clear();
      }
      const modelReader = new cadex.ModelData_ModelReader();
      model.current[designId] = new cadex.ModelData_Model();
      const allOutputs = getOperatorOutputs(operatorId);
      const brepOutput = allOutputs.find(
        (obj) => obj.name === BREP_OUTPUT_NAME,
      );
      if (!brepOutput) {
        showErrorDialog(
          'canvasselection.error.generic',
          'Something went wrong preparing canvas selection',
        );
        return;
      }
      await modelReader.loadModel(
        baseFileName,
        model.current[designId],
        fetchFile(brepOutput.value),
      );
      const aSceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory();
      const sceneNode = await aSceneNodeFactory.createGraphFromModel(
        model.current[designId],
        cadex.ModelData_RepresentationMask.ModelData_RM_BRep,
      );
      if (!sceneNode) {
        showErrorDialog(
          'canvasselection.error.generic',
          'Something went wrong preparing canvas selection',
        );
        return;
      }
      sceneNode.designId = designId;
      if (brepOutput) {
        sceneNode.brepId = brepOutput.id;
      }
      const parentSceneNode = new cadex.ModelPrs_SceneNode();
      parentSceneNode.addChildNode(sceneNode);
      parentSceneNode.selectionMode = cadex.ModelPrs_SelectionMode.Face;
      parentSceneNode.style = new cadex.ModelPrs_Style();
      const rgb = hexToRgbNormalised(theme?.colors?.tertiary);
      const appearance = new cadex.ModelData_Appearance(
        new cadex.ModelData_ColorObject(rgb.r, rgb.g, rgb.b, 1),
      );
      parentSceneNode.style.highlightAppearance = appearance;
      parentSceneNode.style.selectionAppearance = appearance;

      await matchExistingScene(parentSceneNode);
      await preselectFaceIfNecessary(parentSceneNode, model.current[designId]);
    },
    [
      fetchFile,
      matchExistingScene,
      showErrorDialog,
      getOperatorOutputs,
      preselectFaceIfNecessary,
      theme,
    ],
  );

  const hideLogo = useCallback(() => {
    // the "magic formula" for hiding the logo, other approaches causes them to render
    // their logo again on the canvas via threejs
    document.querySelectorAll('.cadex-logo').forEach((el) => {
      el.style.width = 0;
      el.style.height = 0;
      el.style.border = 'none';
      el.style.padding = 1;
      el.style.background = 'transparent';
    });
  }, []);

  useEffect(() => {
    scene.current = new cadex.ModelPrs_Scene();
    // disable multiple selection
    scene.current.selectionManager.multipleSelectionModifier = undefined;
    viewport.current = new cadex.ModelPrs_ViewPort(
      { addDefaultViewCube: false },
      document.getElementById('interactive-canvas'),
    );
    viewport.current.attachToScene(scene.current);
    viewport.current.inputManager.isHoverEnabled = true;
    viewport.current.inputManager.pushInputHandler(
      new cadex.ModelPrs_HighlightingHandler(viewport.current),
    );
    hideLogo();
    return () => {
      viewport.current?.dispose();
      scene.current?.dispose();
    };
  }, [hideLogo]);

  useEffect(() => {
    alignCameraPositions();
    if (selectedDesignIds.length === 0) {
      if (watchReferencedModelInput && watchReferencedShapeIdInput) {
        const pickedOperator = getSelectedOperatorByValueId(
          watchReferencedModelInput,
        );
        const targetValue = getOperatorInput(
          pickedOperator,
          watchReferencedModelInput,
        );
        renderModel(
          BASE_CDXWEB_FILE_NAME,
          targetValue.value,
          pickedOperator.id,
        );
      }
      return;
    }
    for (const designId of selectedDesignIds) {
      const operatorId = selectedDesignIdOperators[designId];
      renderModel(BASE_CDXWEB_FILE_NAME, designId, operatorId);
    }
  }, [
    renderModel,
    selectedDesignIds,
    watchReferencedModelInput,
    getOperatorInput,
    getSelectedOperatorByValueId,
    watchReferencedShapeIdInput,
    selectedDesignIdOperators,
    alignCameraPositions,
  ]);

  useEffect(() => {
    // re-select faces if active input changed
    for (const designId of selectedDesignIds) {
      for (const root of scene.current.roots()) {
        preselectFaceIfNecessary(root, model.current[designId]);
      }
    }
  }, [activeInputId, preselectFaceIfNecessary, selectedDesignIds]);

  useEffect(() => {
    scene.current.selectionManager.addEventListener(
      'selectionChanged',
      onSelectionChanged,
    );
    return () =>
      scene.current?.selectionManager?.removeEventListener(
        'selectionChanged',
        onSelectionChanged,
      );
  }, [onSelectionChanged]);

  return <div id="interactive-canvas" className="threejs" />;
};

CanvasSelection.propTypes = {
  form: PropTypes.object,
};

export default CanvasSelection;

import React, { Fragment, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import { concat, sortBy } from 'lodash';
import useOperator from '@hooks/operators/useOperator';
import useOperatorMutations from '@hooks/operators/useOperatorMutations';
import { OperatorInputTypes } from '@constants/operatorInputTypes';
import {
  decimalInputPattern,
  integerInputPattern,
  stringInputPattern,
} from '@utils/validation';
import {
  whenEnterButtonPressed,
  whenMatchPattern,
} from '@utils/interactionEvents';
import {
  CHECKBOX_FIELD_TYPES,
  DROP_DOWN_DATA_FIELD_TYPES,
  DROP_DOWN_FIELD_TYPES,
  DROP_DOWN_FILE_FIELD_TYPES,
  DROP_DOWN_INPUT_FIELD_TYPES,
  INPUT_FIELD_TYPES,
  OPERATORS_WITH_ADD_BUTTON,
} from '@constants/operatorFieldTypes';
import { findClosestElementById } from '@utils/dom';
import {
  DROP_DOWN_MENU_ID,
  DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
} from '@components/2-molecules/DropDownMenu';
import SettingDropDownWithIconButton from '@components/2-molecules/SettingDropDownWithIconButton';
import SettingTextField from '@components/2-molecules/SettingTextField';
import SettingCheckbox from '@components/2-molecules/SettingCheckbox';
import SettingActionRow from '@components/2-molecules/SettingActionRow';
import { TOOLTIP_POSITION_BOTTOM_LEFT } from '@components/2-molecules/Tooltip';
import { Wrapper } from './WorkflowOperatorFields.styled';
import SettingsFileButton from '@components/File/SettingsFileButton/SettingsFileButton';
import CanvasSelectionChip from '@components/CanvasSelection';
import { br } from '@utils/render';

const settingTooltipProps = {
  tooltipAppearDelay: 200,
  tooltipWidth: 350,
  tooltipPosition: TOOLTIP_POSITION_BOTTOM_LEFT,
  tooltipOffsetRight: 20,
};

const WorkflowOperatorFields = ({
  disabled,
  operator = {},
  computeWorkflow = () => {},
}) => {
  const intl = useIntl();

  const {
    getOperatorVisibleInputs,
    getOperatorFieldOptions,
    getOperatorInputDescription,
    doesFieldSupportCanvasSelection,
    getReferencedCanvasSelectionField,
  } = useOperator();
  const { addOperatorValueMutation, removeOperatorValueMutation } =
    useOperatorMutations();

  const workflowId = useMemo(() => operator?.conceptId, [operator?.conceptId]);
  const operatorId = useMemo(() => operator?.id, [operator?.id]);

  const operatorValues = useMemo(
    () => getOperatorVisibleInputs(operator),
    [getOperatorVisibleInputs, operator],
  );

  const showAddButton = OPERATORS_WITH_ADD_BUTTON.includes(operator?.name);
  const fieldNameBasePath = `${operatorId}`;

  const getDropDownPlaceholder = useCallback(
    (fieldInput = {}) => {
      const { type } = fieldInput;
      let placeholder = intl.formatMessage({
        id: 'editorpanel.selector.select_value',
        defaultMessage: 'Select value',
      });

      if (DROP_DOWN_INPUT_FIELD_TYPES.includes(type)) {
        placeholder = intl.formatMessage({
          id: 'editorpanel.selector.select_input',
          defaultMessage: 'Select input',
        });
      }

      if (DROP_DOWN_FILE_FIELD_TYPES.includes(type)) {
        placeholder = intl.formatMessage({
          id: 'editorpanel.selector.select_file',
          defaultMessage: 'Select file',
        });
      }

      if (DROP_DOWN_DATA_FIELD_TYPES.includes(type)) {
        placeholder = intl.formatMessage({
          id: 'editorpanel.selector.select_data',
          defaultMessage: 'Select data',
        });
      }

      return placeholder;
    },
    [intl],
  );

  // this function is used to reorder the data values in the combine operator
  // when add or remove a Data name field
  const reorderCombineOperatorDataValues = useCallback((operatorValues) => {
    const dataValues = operatorValues.filter((operatorValue) =>
      operatorValue.name.startsWith('Data'),
    );
    const orderedDataValues = dataValues.map((dataValue, i) => ({
      ...dataValue,
      name: i === 0 ? 'Data' : `Data${i + 1}`,
    }));

    const otherValues = operatorValues.filter(
      (operatorValue) => !operatorValue.name.startsWith('Data'),
    );

    return concat(orderedDataValues, otherValues);
  }, []);

  // this function is used to to sort the data values in the combine operator
  // when add or remove a Data name field
  const updateCombineOperatorDataValues = useCallback(
    (operators) =>
      operators.map((operator) => {
        if (operator.id === operatorId) {
          return {
            ...operator,
            values: reorderCombineOperatorDataValues(
              sortBy(operator.values, ['name']),
            ),
          };
        }

        return operator;
      }),
    [operatorId, reorderCombineOperatorDataValues],
  );

  const handleAddField = useCallback(() => {
    const dataFieldOrder = operator?.values?.reduce((acc, value) => {
      if (value.name.startsWith('Data')) {
        const order = parseInt(value.name.replace('Data', ''), 10);

        return order > acc ? order : acc;
      }

      return acc;
    }, 1);

    addOperatorValueMutation.mutate({
      workflowId,
      operatorId,
      valueName: `Data${dataFieldOrder + 1}`,
      valueType: OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES,
      updateOperatorValues: updateCombineOperatorDataValues,
    });
  }, [
    workflowId,
    operatorId,
    operator?.values,
    addOperatorValueMutation,
    updateCombineOperatorDataValues,
  ]);

  const handleFieldRemove = useCallback(
    (fieldInput) => () => {
      removeOperatorValueMutation.mutate({
        workflowId,
        operatorId,
        operatorValueId: fieldInput.id,
        updateOperatorValues: updateCombineOperatorDataValues,
      });
    },
    [
      removeOperatorValueMutation,
      workflowId,
      operatorId,
      updateCombineOperatorDataValues,
    ],
  );

  const handleSelectdBlur = useCallback(
    (cb) => (e) => {
      const relatedTarget = e?.relatedTarget;

      if (
        relatedTarget &&
        findClosestElementById(relatedTarget, DROP_DOWN_MENU_ID)
      ) {
        const dropDownMenuElement = document.getElementById(DROP_DOWN_MENU_ID);

        dropDownMenuElement.addEventListener('blur', () => {
          cb(e);
        });

        return;
      }

      cb(e);
    },
    [],
  );

  const renderDropDownField = useCallback(
    (fieldInput = {}, prependItems = []) => {
      const fieldId = fieldInput?.id;
      const formFieldName = `${fieldNameBasePath}.${fieldId}`;
      const fieldType = fieldInput?.type || '';
      const fieldName = fieldInput?.name || '';
      const options = getOperatorFieldOptions(operator, fieldInput);
      const dropDownMenuScrolledToBottom = [
        ...DROP_DOWN_INPUT_FIELD_TYPES,
        ...DROP_DOWN_FILE_FIELD_TYPES,
      ].includes(fieldType);
      const withRemoveButton =
        !disabled &&
        fieldType === OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES;

      return (
        <Controller
          name={formFieldName}
          render={({ field, fieldState }) => {
            const error = fieldState.isTouched && fieldState.error;
            const fieldIsDirty = !error && fieldState.isDirty;

            const selectedOption = options?.find(
              (option) => option?.value === field.value,
            ) || { label: '', value: '' };
            const dropDownMenuItems = options?.map((option) => {
              const withTooltip =
                option?.assetImageUrl || option?.descriptionKey;
              const tooltip = withTooltip
                ? {
                    tooltipAnimated: false,
                    tooltipWidth: 300,
                    tooltipPosition: TOOLTIP_POSITION_BOTTOM_LEFT,
                    tooltipOffsetRight: 10,
                    tooltipMediaSrc: option?.assetImageUrl,
                    tooltipMediaHeight: 156,
                    tooltipMediaPadding: '20px',
                    tooltipInfoRow: {
                      label: option?.value,
                      description: intl.formatMessage(
                        {
                          id: option?.descriptionKey,
                          defaultMessage: option?.description,
                        },
                        { br },
                      ),
                    },
                  }
                : undefined;

              const optionalProps = {
                tooltip,
              };

              return {
                label: option?.label,
                leadingIconUrl: option?.assetIconUrl,
                selected: selectedOption?.value === option?.value,
                onClick: () =>
                  field.onChange({ target: { value: option?.value } }),
                ...optionalProps,
              };
            });

            dropDownMenuItems.unshift(...prependItems);

            return (
              <SettingDropDownWithIconButton
                label={fieldName}
                labelTooltip={{
                  ...settingTooltipProps,
                  tooltipInfoRow: {
                    label: fieldName,
                    description: getOperatorInputDescription(operator, fieldId),
                  },
                }}
                dropDownField={{
                  disabled,
                  value: selectedOption?.label,
                  placeholder: getDropDownPlaceholder(fieldInput),
                  error: !!error,
                  supportingText: error?.message,
                  dirty: fieldIsDirty,
                  diffRemoved: fieldInput?.diffRemoved,
                  diffAdded: fieldInput?.diffAdded,
                  diffModified: fieldInput?.diffModified,
                  dropDownMenuItems: dropDownMenuItems,
                  dropDownMenuPosition: DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
                  dropDownMenuScrolledToBottom,
                  fullWidthDropDownMenu: false,
                  onBlur: handleSelectdBlur(field.onBlur),
                }}
                {...(withRemoveButton
                  ? {
                      iconButton: {
                        iconName: 'delete_0',
                        onClick: handleFieldRemove(fieldInput),
                      },
                    }
                  : {})}
              />
            );
          }}
        />
      );
    },
    [
      intl,
      disabled,
      fieldNameBasePath,
      getDropDownPlaceholder,
      getOperatorFieldOptions,
      getOperatorInputDescription,
      handleFieldRemove,
      operator,
      handleSelectdBlur,
    ],
  );

  const renderCanvasSelectionField = useCallback(
    (fieldInput = {}, referencedInputs = {}) => {
      const fieldId = fieldInput?.id;
      const formFieldName = `${fieldNameBasePath}.${fieldId}`;
      const referencedModelFieldName = `${fieldNameBasePath}.${referencedInputs?.modelInput?.id}`;
      const referencedShapeIdFieldName = `${fieldNameBasePath}.${referencedInputs?.idInput?.id}`;

      return (
        <CanvasSelectionChip
          formFieldName={formFieldName}
          fieldInput={fieldInput}
          referencedModelFieldName={referencedModelFieldName}
          referencedShapeIdFieldName={referencedShapeIdFieldName}
          renderDropDownField={renderDropDownField}
          disabled={disabled}
        />
      );
    },
    [fieldNameBasePath, renderDropDownField, disabled],
  );

  const renderCanvasSelectionDropDownField = useCallback(
    (fieldInput = {}) => {
      const referencedInputs = getReferencedCanvasSelectionField(
        fieldInput,
        operator,
      );
      return renderCanvasSelectionField(fieldInput, referencedInputs);
    },
    [renderCanvasSelectionField, operator, getReferencedCanvasSelectionField],
  );

  const getMatchPattern = useCallback((fieldInputType) => {
    switch (fieldInputType) {
      case OperatorInputTypes.DECIMAL:
        return decimalInputPattern;
      case OperatorInputTypes.STRING:
        return stringInputPattern;
      case OperatorInputTypes.INTEGER:
        return integerInputPattern;
      default:
        return integerInputPattern;
    }
  }, []);

  const renderFileField = useCallback(
    (fieldInput = {}) => {
      const fieldId = fieldInput?.id;
      const formFieldName = `${fieldNameBasePath}.${fieldId}`;
      const fieldName = fieldInput?.name || '';

      return (
        <Controller
          name={formFieldName}
          render={({ field: { onChange, value }, fieldState }) => {
            const error = fieldState.error;
            return (
              <SettingsFileButton
                labelTooltip={{
                  ...settingTooltipProps,
                  tooltipInfoRow: {
                    label: fieldName,
                    description: getOperatorInputDescription(operator, fieldId),
                  },
                }}
                fieldInput={fieldInput}
                onChange={onChange}
                value={value}
                disabled={disabled}
                error={Boolean(error)}
              />
            );
          }}
        />
      );
    },
    [fieldNameBasePath, getOperatorInputDescription, operator, disabled],
  );

  const renderInputField = useCallback(
    (fieldInput = {}) => {
      const fieldId = fieldInput?.id;
      const formFieldName = `${fieldNameBasePath}.${fieldId}`;
      const fieldName = fieldInput?.name || '';
      const fieldInputType = fieldInput?.type || '';
      const intlId = `operatorinput.placeholder.${fieldInputType?.toLowerCase()}`;

      return (
        <Controller
          name={formFieldName}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            const error = fieldState.isTouched && fieldState.error;
            const fieldIsDirty = !error && fieldState.isDirty;

            return (
              <SettingTextField
                label={fieldName}
                labelTooltip={{
                  ...settingTooltipProps,
                  tooltipInfoRow: {
                    label: fieldName,
                    description: getOperatorInputDescription(operator, fieldId),
                  },
                }}
                field1={{
                  disabled,
                  id: fieldId,
                  name: formFieldName,
                  placeholder: intl.formatMessage({
                    id: intlId,
                    defaultMessage: fieldInputType,
                  }),
                  dirty: fieldIsDirty,
                  error: !!error,
                  supportingText: error?.message,
                  diffRemoved: fieldInput?.diffRemoved,
                  diffAdded: fieldInput?.diffAdded,
                  diffModified: fieldInput?.diffModified,
                  onKeyPress: whenEnterButtonPressed(
                    whenMatchPattern(getMatchPattern(fieldInputType)),
                    computeWorkflow,
                  ),
                  ...field,
                  onChange: whenMatchPattern(
                    getMatchPattern(fieldInputType),
                    field.onChange,
                  ),
                }}
              />
            );
          }}
        />
      );
    },
    [
      computeWorkflow,
      disabled,
      fieldNameBasePath,
      getMatchPattern,
      getOperatorInputDescription,
      intl,
      operator,
    ],
  );

  const renderCheckboxField = useCallback(
    (fieldInput = {}) => {
      const fieldId = fieldInput?.id;
      const formFieldName = `${fieldNameBasePath}.${fieldId}`;
      const fieldName = fieldInput?.name || '';

      return (
        <Controller
          name={formFieldName}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            const error = fieldState.isTouched && fieldState.error;
            const fieldIsDirty = !error && fieldState.isDirty;
            const checked = field.value === 'true';

            return (
              <SettingCheckbox
                label={fieldName}
                labelTooltip={{
                  ...settingTooltipProps,
                  tooltipInfoRow: {
                    label: fieldName,
                    description: getOperatorInputDescription(operator, fieldId),
                  },
                }}
                checkbox={{
                  disabled,
                  id: fieldId,
                  name: formFieldName,
                  checked: checked,
                  onChange: (checked) =>
                    field.onChange({ target: { value: checked?.toString() } }),
                  dirty: fieldIsDirty,
                  diffRemoved: fieldInput?.diffRemoved,
                  diffAdded: fieldInput?.diffAdded,
                  diffModified: fieldInput?.diffModified,
                }}
              />
            );
          }}
        />
      );
    },
    [disabled, fieldNameBasePath, getOperatorInputDescription, operator],
  );

  return (
    <Wrapper>
      {operatorValues?.map((fieldInput) => (
        <Fragment key={fieldInput.id}>
          {DROP_DOWN_FIELD_TYPES.includes(fieldInput?.type) &&
            (doesFieldSupportCanvasSelection(operator, fieldInput)
              ? renderCanvasSelectionDropDownField(fieldInput)
              : renderDropDownField(fieldInput))}

          {DROP_DOWN_FILE_FIELD_TYPES.includes(fieldInput?.type) &&
            renderFileField(fieldInput)}

          {INPUT_FIELD_TYPES.includes(fieldInput?.type) &&
            renderInputField(fieldInput)}

          {CHECKBOX_FIELD_TYPES.includes(fieldInput?.type) &&
            renderCheckboxField(fieldInput)}
        </Fragment>
      ))}

      {showAddButton && (
        <SettingActionRow
          button={{
            disabled,
            iconName: 'add_0',
            children: intl.formatMessage({
              id: 'operator.fields.add_field',
              defaultMessage: 'Add Field',
            }),
            onClick: handleAddField,
          }}
        />
      )}
    </Wrapper>
  );
};

WorkflowOperatorFields.propTypes = {
  disabled: PropTypes.bool,
  operator: PropTypes.object,
  computeWorkflow: PropTypes.func,
};

export default memo(WorkflowOperatorFields);

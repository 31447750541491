import { getCurrentUser } from '@selectors/loginSelectors';

export const getMinimumBackwardsCompatibleVersion = (state = {}) => {
  return state?.config?.minimumBackwardsCompatibleVersion;
};

export const isFeatureEnabled =
  (featureName = '') =>
  (state = {}) => {
    const feature = state?.config?.features?.[featureName];

    if (!feature) return false;

    const { organizationName, role, id } = getCurrentUser()(state);

    if (!feature?.enabled) {
      return checkDisabledFeature(feature, organizationName, role);
    }
    return checkEnabledFeature(feature, organizationName, role, id);
  };

const checkEnabledFeature = (feature, organizationName, role, id) => {
  const enableForUser = feature?.users === '*' || feature?.users?.includes(id);

  if (!enableForUser) return false;

  const enableForUserOrg =
    feature?.organizations === '*' ||
    feature?.organizations?.includes(organizationName);

  if (!enableForUserOrg) return false;

  const enableForUserRoe =
    feature?.roles === '*' || feature?.roles?.includes(role);

  if (!enableForUserRoe) return false;

  return true;
};

const checkDisabledFeature = (feature, organizationName, role) => {
  const disableForAllOrgs = feature?.organizations === '*';

  if (disableForAllOrgs) return false;

  const disableForUserOrg = feature?.organizations?.includes(organizationName);

  if (!disableForUserOrg) return true;

  const disableForAllRoles = feature?.roles === '*';

  if (disableForAllRoles) return false;

  const disableForUserRole = feature?.roles?.includes(role);

  if (disableForUserRole) return false;

  return true;
};

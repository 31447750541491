import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, map, uniqBy, uniqueId } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { getDependentNodes } from '@utils/visualTool';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperatorList from '@hooks/operators/useOperatorList';
import useOperator from '@hooks/operators/useOperator';
import useOperatorMutations from '@hooks/operators/useOperatorMutations';
import {
  unfreezeOperatorOutput,
  resetLastAddedOperator,
} from '@actions/conceptActions';
import { showErrorDialog } from '@actions/errorActions';
import {
  selectFullScreenOperatorId,
  setIsSubmitted,
  setFullScreenOperatorId,
  resetFullScreenOperatorVisibility,
  resetFullScreenOperatorId,
} from '@reducers/workflowSlice';
import { getLastAddedOperatorId } from '@selectors/conceptSelectors';
import { getUserAccountPreference } from '@selectors/loginSelectors';
import Operator from '@components/2-molecules/Operator';
import WorkflowOperatorFields from '@containers/WorkflowOperatorFields';
import WorkflowOperatorOutputs from '@containers/WorkflowOperatorOutputs';
import WorkflowFullScreenOperator, {
  OPERATOR_SLIDE_IN_ANIMATION_SECONDS_DURATION,
} from '@containers/WorkflowFullScreenOperator';
import HorizontalDivider from '@components/1-atoms/HorizontalDivider';
import MessageBox, {
  MESSAGE_BOX_VARIANT_ERROR,
  MESSAGE_BOX_VARIANT_MESSAGE,
} from '@components/2-molecules/MessageBox';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import SettingActionRow from '@components/2-molecules/SettingActionRow';
import EmptyStateBox from '@components/2-molecules/EmptyStateBox';
import {
  DraggableItem,
  DragProvider,
  List,
  OperatorContent,
  OperatorFooter,
  OperatorMessageWrapper,
} from './WorkflowOperators.styled';

export const WORKFLOW_OPERATORS_DROPPABLE_ID = 'workflow-operators-list';
export const OPERATOR_BODY_VISIBILITY_INPUT_LENGTH = 15;
export const OPERATOR_BODY_VISIBILITY_DELAY = 600;

const WorkflowOperators = ({
  drag = {},
  expandedOperatorIds = [],
  toggleOperatorExpansion,
  isWorkflowSettingBarVisible,
  isWorkflowEmpty,
  reviewWorkflowUpgrade,
}) => {
  const [previouslyExpandedOperatorIds, setPreviouslyExpandedOperatorIds] =
    useState(expandedOperatorIds);
  const intl = useIntl();
  const dispatch = useDispatch();
  const form = useFormContext();
  const isFullScreenOperatorPreference = useSelector(
    getUserAccountPreference('USE_FULL_SCREEN_OPERATORS'),
  );
  const fullScreenOperatorId = useSelector(selectFullScreenOperatorId);
  const uniqueIdRef = useRef(uniqueId());
  const { deleteOperatorMutation } = useOperatorMutations();
  const { getDefaultOperator, getOperatorDropDownMenuActions } =
    useOperatorList();
  const {
    explodeOperator,
    getIsOperatorComputing,
    getIsOperatorExplodable,
    getOperatorHiddenInputNames,
    getOperatorGraphDependencies,
    getOperatorStatus,
    getSelectedOperator,
    getWorkflowFrozenHiddenOutputs,
    selectFirstOperatorOutput,
    getIsOperatorComputed,
    getIsOperatorFrozen,
  } = useOperator();
  const {
    getSelectedWorkflow,
    getWorkflowOperators,
    getIsWorkflowPublic,
    getIsWorkflowComputing,
    updateValuesAndComputeWorkflow,
    getIsWorkflowEditable,
  } = useWorkflow();
  const lastAddedOperatorId = useSelector(getLastAddedOperatorId);
  const workflow = getSelectedWorkflow();
  const operators = getWorkflowOperators(workflow);
  const selectedOperator = getSelectedOperator();

  const workflowIsComputing = getIsWorkflowComputing(workflow);
  const workflowIsPublic = getIsWorkflowPublic(workflow);
  const workflowIsDisabled = !getIsWorkflowEditable(workflow);

  const {
    formState: { touchedFields, errors, defaultValues },
    getValues,
    handleSubmit,
  } = form;

  const workflowFrozenHiddenOutputs = useMemo(
    () => getWorkflowFrozenHiddenOutputs(),
    [getWorkflowFrozenHiddenOutputs],
  );

  const closestTopOperatorOrder = drag?.closestDragItems?.nextItem?.order - 1;
  const closestBottomOperatorOrder =
    drag?.closestDragItems?.previousItem?.order - 1 || operators?.length;

  const isOperatorDisabled = useCallback(
    (index, isDragging) => {
      if (isDragging || workflowIsDisabled) {
        return false;
      }

      const outOfRange =
        index <= closestTopOperatorOrder || index >= closestBottomOperatorOrder;

      return outOfRange;
    },
    [workflowIsDisabled, closestTopOperatorOrder, closestBottomOperatorOrder],
  );

  const getDraggableTransform = useCallback(
    (provided) => {
      const transform = provided?.draggableProps?.style?.transform;

      if (!transform) {
        return;
      }

      const closestTopDisabledOperatorElement = document.querySelector(
        `.workflow-operator-draggable--order-${closestTopOperatorOrder}`,
      );
      const closestBottomDisabledOperatorElement = document.querySelector(
        `.workflow-operator-draggable--order-${closestBottomOperatorOrder}`,
      );

      const [, parsedTranslateY] = transform.split(',');
      const translateY = parseInt(parsedTranslateY);
      // TODO: Implement minTranslateY and maxTranslateY to prevent dragging out of the disabled area
      let minTranslateY = 0;
      let maxTranslateY = 0;

      if (closestTopDisabledOperatorElement) {
        const { top, height } =
          closestTopDisabledOperatorElement.getBoundingClientRect();
        // eslint-disable-next-line no-unused-vars
        minTranslateY = top + height;
      }

      if (closestBottomDisabledOperatorElement) {
        const { top } =
          closestBottomDisabledOperatorElement.getBoundingClientRect();
        // eslint-disable-next-line no-unused-vars
        maxTranslateY = top;
      }

      return `translate(0, ${translateY}px)`;
    },
    [closestTopOperatorOrder, closestBottomOperatorOrder],
  );

  const operatorDropDownMenuActionsGetter = useCallback(
    (operator) => () => getOperatorDropDownMenuActions(operator),
    [getOperatorDropDownMenuActions],
  );

  const handleOperatorSelection = useCallback(
    (operator) => () => {
      selectFirstOperatorOutput(operator);
    },
    [selectFirstOperatorOutput],
  );

  const validateDependencyTreeAndCompute = useCallback(
    async (invalidFormValues, operatorId) => {
      const dependedGraph = getOperatorGraphDependencies();
      const dependedOperators = getDependentNodes(dependedGraph, operatorId);
      const dependedOperatorIds = dependedOperators.map((op) => op.getId());
      const invalidOperatorIds = Object.keys(invalidFormValues);

      // Check if the current operatorId or any of its successors have errors
      const hasErrors = invalidOperatorIds.some(
        (id) => id === operatorId || dependedOperatorIds.includes(id),
      );
      const allValues = getValues();
      const filteredValues = {};
      // Filter values for the target operator and its successors
      [operatorId, ...dependedOperatorIds].forEach((id) => {
        if (allValues[id] !== undefined) {
          filteredValues[id] = allValues[id];
        }
      });

      // If no errors, compute the workflow
      if (!hasErrors) {
        await updateValuesAndComputeWorkflow(filteredValues, operatorId);
        dispatch(setIsSubmitted(true));
      } else {
        dispatch(
          showErrorDialog(
            'Error',
            intl.formatMessage({
              id: 'validation.operator_path_invalid',
              defaultMessage:
                "Please ensure all fields within the operator's execution path are valid before proceeding",
            }),
          ),
        );
      }
    },
    [
      dispatch,
      getOperatorGraphDependencies,
      getValues,
      intl,
      updateValuesAndComputeWorkflow,
    ],
  );

  const computeOperator = useCallback(
    (operatorId) => () => {
      handleSubmit(
        async (formValues) => {
          await updateValuesAndComputeWorkflow(formValues, operatorId);
        },
        async (invalidFormValues) => {
          //   if there are issues with the form, only validate dependency tree of single operator
          await validateDependencyTreeAndCompute(invalidFormValues, operatorId);
        },
      )();
    },
    [
      handleSubmit,
      updateValuesAndComputeWorkflow,
      validateDependencyTreeAndCompute,
    ],
  );

  const generateOperatorMessages = useCallback(
    (operator) => {
      const operatorMessages = [];
      const formOperatorTouched = touchedFields?.[operator.id] || {};
      const formOperatorErrors = errors?.[operator.id] || {};

      if (!workflowIsDisabled && operator?.deprecated) {
        operatorMessages.push({
          id: 'operator.deprecated_message_box.deprecated.text.backwards_compatible',
          variant: MESSAGE_BOX_VARIANT_MESSAGE,
          text: intl.formatMessage({
            id: 'operator.deprecated_message_box.deprecated.text.backwards_compatible',
            defaultMessage:
              'There is a new Aibuild update available. The new version includes changes to this operator.',
          }),
          endingButtonTitle: intl.formatMessage({
            id: 'operator.deprecated_message_box.deprecated.action.backwards_compatible',
            defaultMessage: 'Review',
          }),
          onEndingButtonClick: reviewWorkflowUpgrade,
        });
      }

      if (!isEmpty(formOperatorErrors)) {
        const errorFieldIds = Object.keys(formOperatorErrors);
        const touchedFieldIds = Object.keys(formOperatorTouched);
        const showFieldsErrorMessage = touchedFieldIds.some((fieldId) =>
          errorFieldIds?.includes(fieldId),
        );

        if (showFieldsErrorMessage) {
          operatorMessages.push({
            id: 'operator.invalid_message_box.ivalid_entires.text',
            variant: MESSAGE_BOX_VARIANT_ERROR,
            text: intl.formatMessage({
              id: 'operator.invalid_message_box.ivalid_entires.text',
              defaultMessage:
                'Unable to compute because of some invalid entries. Please review fields below.',
            }),
          });
        }
      }

      return operatorMessages;
    },
    [intl, touchedFields, errors, reviewWorkflowUpgrade, workflowIsDisabled],
  );

  const getOperatorName = useCallback((operator) => {
    let name = operator?.tag || operator?.name;

    if (operator?.name === 'Load' && operator?.name !== operator?.tag) {
      name = `${operator?.name}: ${operator?.tag}`;
    }

    return name;
  }, []);

  const getIsOperatorSelectable = useCallback(
    (operator) => {
      if (workflowIsDisabled) {
        return true;
      }

      return getIsOperatorComputed(operator);
    },
    [workflowIsDisabled, getIsOperatorComputed],
  );

  const closeFullScreenOperator = useCallback(
    () =>
      new Promise((resolve) => {
        const closeAnimationDuration =
          OPERATOR_SLIDE_IN_ANIMATION_SECONDS_DURATION * 1000;

        dispatch(resetFullScreenOperatorVisibility());

        setTimeout(() => {
          dispatch(resetFullScreenOperatorId());

          resolve();
        }, closeAnimationDuration);
      }),
    [dispatch],
  );

  const cancelFullScreenOperator = useCallback(
    async (operator) => {
      closeFullScreenOperator();

      await deleteOperatorMutation.mutateAsync({
        workflowId: operator.conceptId,
        operatorId: operator.id,
      });

      if (lastAddedOperatorId === operator?.id) {
        dispatch(resetLastAddedOperator());
      }
    },
    [
      dispatch,
      closeFullScreenOperator,
      lastAddedOperatorId,
      deleteOperatorMutation,
    ],
  );

  // eslint-disable-next-line no-unused-vars
  const openOperatorToFullScreen = useCallback(
    (operator) => () => {
      if (!isFullScreenOperatorPreference) return;

      dispatch(setFullScreenOperatorId(operator.id));
    },
    [dispatch, isFullScreenOperatorPreference],
  );

  useEffect(() => {
    if (!isEmpty(workflowFrozenHiddenOutputs)) {
      const workflowFrozenHiddenOutputsIds = map(
        workflowFrozenHiddenOutputs,
        'id',
      );

      dispatch(unfreezeOperatorOutput(workflowFrozenHiddenOutputsIds));
    }
  }, [dispatch, workflowFrozenHiddenOutputs]);

  useEffect(() => {
    const skip = expandedOperatorIds.every((id) =>
      previouslyExpandedOperatorIds.includes(id),
    );

    if (skip) {
      return;
    }

    const uniqOperatorIds = uniqBy(
      expandedOperatorIds,
      (id) => !previouslyExpandedOperatorIds.includes(id),
    );
    const nextPreviouslyExpandedOperatorIds = [
      ...previouslyExpandedOperatorIds,
      ...uniqOperatorIds,
    ];

    setPreviouslyExpandedOperatorIds(nextPreviouslyExpandedOperatorIds);
  }, [previouslyExpandedOperatorIds, expandedOperatorIds]);

  return (
    <Droppable
      droppableId={WORKFLOW_OPERATORS_DROPPABLE_ID}
      ignoreContainerClipping
    >
      {(provided, droppableSnapshot) => (
        <List
          {...provided.droppableProps}
          ref={provided.innerRef}
          draggingOver={droppableSnapshot?.isDraggingOver}
        >
          {isWorkflowEmpty && (
            <EmptyStateBox
              dataTestId="workflow-setting-bar__empty-state"
              iconName="network_node_0"
              title={intl.formatMessage({
                id: 'workflowsettingbar.empty_state.title',
                defaultMessage: 'Get started',
              })}
              description={intl.formatMessage({
                id: 'workflowsettingbar.empty_state.description',
                defaultMessage:
                  'Load a file, slice your model and generate a toolpath using commands from the toolbar on the left-hand side.',
              })}
            />
          )}

          {operators.map((operator, index) => {
            const { id, name } = operator;
            const operatorHiddneInputNames = getOperatorHiddenInputNames(id);
            const operatorMessages = generateOperatorMessages(operator);
            const operatorIsEpxanded = expandedOperatorIds?.includes(id);
            const operatorName = getOperatorName(operator);
            const operatorStatus = getOperatorStatus(operator, {
              formState: {
                touchedFields,
                errors,
                defaultValues,
              },
              getValues,
            });
            const isPreviouslyExpanded =
              previouslyExpandedOperatorIds?.includes(id);
            const shouldDelayContentVisibility =
              operator.values?.length - operatorHiddneInputNames.length >
              OPERATOR_BODY_VISIBILITY_INPUT_LENGTH;

            return (
              <Draggable
                key={`${id}-${uniqueIdRef.current}`}
                index={index}
                draggableId={id}
                isDragDisabled={!drag.daragEnabled}
              >
                {(provided, snapshot) => (
                  <DraggableItem
                    className={classNames(
                      'workflow-operator-draggable',
                      `workflow-operator-draggable--order-${operator.order}`,
                    )}
                    key={`${WORKFLOW_OPERATORS_DROPPABLE_ID}-operator-${id}-${uniqueIdRef.current}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    tabIndex="-1"
                    isDragging={snapshot.isDragging}
                    style={{
                      ...provided.draggableProps.style,
                      pointerEvents: droppableSnapshot?.isDraggingOver
                        ? 'none'
                        : undefined,
                      transform: getDraggableTransform(provided),
                    }}
                  >
                    <>
                      <Operator
                        dataTestId={`workflow-operator-${id}`}
                        skeleton={operator.adding}
                        loading={getIsOperatorComputing(operator)}
                        disableActions={workflowIsComputing}
                        disabled={isOperatorDisabled(
                          index,
                          snapshot.isDragging,
                        )}
                        disableDrag={workflowIsPublic}
                        dragged={snapshot.isDragging}
                        dragHandleProps={provided.dragHandleProps}
                        expanded={operatorIsEpxanded}
                        onExpandButtonClick={toggleOperatorExpansion?.(id)}
                        // TODO: Uncoment once the feature is approved
                        // onHeaderDoubleClick={openOperatorToFullScreen(operator)}
                        onComputeButtonClick={computeOperator(operator.id)}
                        operatorIcon={getDefaultOperator(name)?.iconName}
                        operatorName={operatorName}
                        selected={selectedOperator?.id === id}
                        highlighted={getIsOperatorFrozen(operator)}
                        status={operatorStatus}
                        selectable={getIsOperatorSelectable(operator)}
                        onSelect={handleOperatorSelection?.(operator)}
                        moreButtonDropDownMenuItemsGetter={operatorDropDownMenuActionsGetter(
                          operator,
                        )}
                        observeResize={isWorkflowSettingBarVisible}
                        bodySkeleton={shouldDelayContentVisibility}
                        contentVisibilityDelay={
                          shouldDelayContentVisibility
                            ? OPERATOR_BODY_VISIBILITY_DELAY
                            : 0
                        }
                      >
                        {isPreviouslyExpanded && (
                          <OperatorContent>
                            {!isEmpty(operatorMessages) && (
                              <OperatorMessageWrapper>
                                {operatorMessages.map((message) => (
                                  <MessageBox
                                    key={message.id}
                                    variant={message.variant}
                                    leadingIconName={message.iconName}
                                    endingButtonTitle={
                                      message.endingButtonTitle
                                    }
                                    onEndingButtonClick={
                                      message.onEndingButtonClick
                                    }
                                  >
                                    {message.text}
                                  </MessageBox>
                                ))}
                              </OperatorMessageWrapper>
                            )}

                            <SettingsCategory
                              title={intl.formatMessage({
                                id: 'general.settings',
                                defaultMessage: 'Settings',
                              })}
                              expand
                            >
                              <WorkflowOperatorFields
                                disabled={workflowIsDisabled}
                                operator={operator}
                              />
                            </SettingsCategory>

                            <WorkflowOperatorOutputs
                              disabled={workflowIsDisabled}
                              disablePublish={workflowIsPublic}
                              operator={operator}
                              computeOperator={computeOperator(operator.id)}
                              operatorStatus={operatorStatus}
                            />

                            {getIsOperatorExplodable(operator) && (
                              <div>
                                <HorizontalDivider middleInset />

                                <OperatorFooter>
                                  <SettingActionRow
                                    button={{
                                      disabled: workflowIsDisabled,
                                      iconName: 'format_list_bulleted_0',
                                      children: intl.formatMessage({
                                        id: 'operator.actions.explode',
                                        defaultMessage: 'Explode',
                                      }),
                                      onClick: () => explodeOperator(operator),
                                    }}
                                  />
                                </OperatorFooter>
                              </div>
                            )}
                          </OperatorContent>
                        )}
                      </Operator>

                      {fullScreenOperatorId === id && (
                        <WorkflowFullScreenOperator
                          operator={operator}
                          name={operatorName}
                          onApply={closeFullScreenOperator}
                          onCancel={cancelFullScreenOperator}
                          computeOperator={computeOperator(operator.id)}
                          status={operatorStatus}
                        />
                      )}
                    </>
                  </DraggableItem>
                )}
              </Draggable>
            );
          })}

          <DragProvider remove={isWorkflowEmpty}>
            {provided.placeholder}
          </DragProvider>
        </List>
      )}
    </Droppable>
  );
};

WorkflowOperators.propTypes = {
  drag: PropTypes.shape({
    dragHandleProps: PropTypes.object,
  }),
  expandedOperatorIds: PropTypes.array,
  toggleOperatorExpansion: PropTypes.func,
  isWorkflowSettingBarVisible: PropTypes.bool,
  isWorkflowEmpty: PropTypes.bool,
  reviewWorkflowUpgrade: PropTypes.func,
};

export default WorkflowOperators;

export const findClosestElementById = (element, targetId) => {
  if (!element) return null;

  while (element && element !== document) {
    if (element.id === targetId) {
      return element;
    }

    element = element.parentNode;
  }

  return null;
};

export const hasParentWithId = (element, id) => {
  let currentElement = element;

  while (currentElement) {
    if (currentElement.id === id) {
      return true;
    }

    currentElement = currentElement.parentElement;
  }

  return false;
};

export const findScrollableParent = (element) => {
  let parent = element?.parentElement;

  if (!parent) {
    return document.body;
  }

  while (parent) {
    const overflowY = window.getComputedStyle(parent).overflowY;
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

    if (isScrollable && parent.scrollHeight > parent.clientHeight) {
      return parent;
    }

    parent = parent.parentElement;
  }

  return document.body;
};

// Recursive function to traverse successors
export const checkSuccessorsRecursively = (
  graph,
  nodeId,
  targetId,
  visited = new Set(),
) => {
  // Get the successors of the current node
  const successors = graph.getSuccessors(nodeId) || [];

  // Extract the node IDs from the successors
  const successorIds = successors.map((successor) => successor.getId());

  // If the targetId is found in the current successors, return true
  if (successorIds.includes(targetId)) {
    return true;
  }

  // Mark the current node as visited to avoid cycles
  visited.add(nodeId);

  // Recursively check the successors of successors
  for (const successorId of successorIds) {
    if (!visited.has(successorId)) {
      // Avoid multiple calls to getId() and simplify logic by passing successorId directly
      if (checkSuccessorsRecursively(graph, successorId, targetId, visited)) {
        return true;
      }
    }
  }

  // If the target is not found in this branch, return false
  return false;
};
